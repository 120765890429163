import { yupResolver } from '@hookform/resolvers/yup'
import { Alert as MuiAlert, AlertProps, Grid } from '@mui/material'
import { useGlobalState } from '@proxyqb/portal-global-state'
import { errorMapper, Severity } from '@proxyqb/ui'
import { Form, Input, PasswordInput, Submit } from '@proxyqb/ui-forms'
import { useSnackbar } from 'notistack'
import { ReactElement, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { Theme } from '@proxyqb/graphql-api-types'
import { filterOutNulls } from '../../../shared'
import { DoctorRegistrationFields, doctorRegistrationSchema } from './doctor-registration-schema'
import { useRegisterPublicDoctorMutation } from './doctor-registration.form.generated'

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

export const DoctorRegistrationForm = (): ReactElement => {
  const { formatMessage } = useIntl()
  const { enqueueSnackbar } = useSnackbar()
  const form = useForm<DoctorRegistrationFields>({
    defaultValues: doctorRegistrationSchema.getDefault(),
    resolver: yupResolver(doctorRegistrationSchema),
  })
  const [, registerDoctor] = useRegisterPublicDoctorMutation()
  const push = useNavigate()
  const { authState } = useGlobalState()
  const [alertSeverity, changeSeverity] = useState<Severity | null>(null)

  const errorMessage = authState.context?.error?.code

  useEffect(() => {
    if (errorMessage !== undefined) {
      const severity = errorMapper[errorMessage] ?? Severity.default
      changeSeverity(severity)
    }
  }, [errorMessage])
  async function handleDoctorRegistration({ phoneNumber, ...rest }: DoctorRegistrationFields) {
    try {
      const result = await registerDoctor({
        doctor: {
          ...filterOutNulls(rest),
          phoneNumber,
          preferences: {
            theme: Theme.Light,
          },
        },
      })
      if (result.error) {
        enqueueSnackbar(result.error?.graphQLErrors?.[0].message, { variant: 'error' })
      } else {
        enqueueSnackbar(formatMessage({ id: 'doctorForm.publicRegistationSuccess' }), { variant: 'success' })
        push('/')
      }
    } catch (err: any) {
      enqueueSnackbar(err.message, { variant: 'error' })
    }
  }
  const submitText = formatMessage({ id: 'doctorForm.submit' })
  return (
    <Form
      name="doctorRegistrationForm"
      onSubmit={handleDoctorRegistration}
      form={form}
      schema={doctorRegistrationSchema}
    >
      {alertSeverity !== null &&
        (alertSeverity === Severity.success ? (
          <Alert severity={Severity.success}>
            {formatMessage[`doctorRegistrationForm.emailWasSentMessage`]}
          </Alert>
        ) : (
          <Alert severity="error">{formatMessage[`doctorRegistrationForm.${alertSeverity}`]}</Alert>
        ))}
      <Grid container spacing={2}>
        <Grid container item xs={12} md={6} spacing={2}>
          <Grid item xs={6} md={5}>
            <Input name="prenominalLetters" />
          </Grid>
          <Grid item xs={6} md={7}>
            <Input name="firstName" sx={{ width: '100%' }} />
          </Grid>
        </Grid>
        <Grid container item xs={12} md={6} spacing={2}>
          <Grid item xs={6} md={7}>
            <Input name="lastName" sx={{ width: '100%' }} />
          </Grid>
          <Grid item xs={6} md={5}>
            <Input name="postnominalLetters" />
          </Grid>
        </Grid>
      </Grid>
      <Input name="email" />
      <Input name="phoneNumber" />
      <PasswordInput />
      <Submit>{submitText}</Submit>
    </Form>
  )
}
