import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup as MuiRadioGroup,
} from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { CSSProperties } from 'react'
import { RegisterOptions, useController } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useFormInnerContext } from '../utils/FormInnerProvider'

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      width: '100%',
    },
  }),
)

interface RadioGroupOption {
  value: any
  label: string
}

interface RadioGroupProps {
  name: string
  rules?: RegisterOptions
  options: RadioGroupOption[]
  direction?: CSSProperties['flexDirection']
  disabled?: boolean
}

export const RadioGroup = ({ name, rules, options, direction, disabled }: RadioGroupProps) => {
  const { isSubmitting, formName, control } = useFormInnerContext()
  const intl = useIntl()
  const classes = useStyles()
  const label =
    intl.messages[`${formName}.${name}`] ?? intl.messages[`shared.${name}`] ?? `${formName}.${name}`
  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
    rules,
    defaultValue: undefined,
  })
  return (
    <FormControl component="fieldset" disabled={disabled || isSubmitting} className={classes.formControl}>
      <FormLabel component="legend">{label}</FormLabel>
      <MuiRadioGroup name={name} value={value || null} onChange={onChange}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: direction,
          }}
        >
          {options.map(({ value, label }) => (
            <FormControlLabel key={value} value={value} control={<Radio />} label={label} />
          ))}
        </Box>
      </MuiRadioGroup>
    </FormControl>
  )
}
