import { CssBaseline } from '@mui/material'
import { LanguageProvider } from '@proxyqb/ui'
import { SnackbarProvider } from 'notistack'
import { ReactElement } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { GlobalProviders } from './global-providers' // eslint-disable-line
import { Routes } from './routes'

const App = (): ReactElement => {
  return (
    <LanguageProvider>
      <GlobalProviders>
        <CssBaseline />
        <BrowserRouter>
          <SnackbarProvider maxSnack={3}>
            <Routes />
          </SnackbarProvider>
        </BrowserRouter>
      </GlobalProviders>
    </LanguageProvider>
  )
}

export default App
