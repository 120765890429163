import { env } from './env'
import { Configuration, ConfigurationParameters, DefaultApi, PlaythroughApi } from './generated'
import { ApiMiddleware } from './middleware'

const config: ConfigurationParameters = {
  basePath: env.gaiaUrl as string,
  middleware: [new ApiMiddleware()],
}

const apiConfig = new Configuration(config)

export const apiClient = new PlaythroughApi(apiConfig)
export const apiClientDefault = new DefaultApi(apiConfig)
