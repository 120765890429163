import { Box, Typography } from '@mui/material'
import { apiClient } from '@proxyqb/gaia-api-client'
import { DashboardWidget } from '@proxyqb/ui'
import startOfDay from 'date-fns/startOfDay'
import subDays from 'date-fns/subDays'
import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useQuery } from 'react-query'
import { animated, config, useSpring } from 'react-spring'

export const PlaythroughsTodayWidget = () => {
  const { data, isFetching: isFetchingToday } = useQuery('getPlaythroughCount', () =>
    apiClient.getPlaythroughCount({ fromDate: startOfDay(new Date()) }),
  )

  const { data: dataYesterday, isFetching: isFetchingDataYesterday } = useQuery(
    'getPlaythroughYesterdayCount',
    () =>
      apiClient.getPlaythroughCount({
        fromDate: subDays(startOfDay(new Date()), 1),
        toDate: startOfDay(new Date()),
      }),
  )

  const isFetching = isFetchingToday || isFetchingDataYesterday
  const intl = useIntl()

  const [spring] = useSpring(() => ({
    count: 0,
    distinctUsers: 0,
    yesterdayCount: 0,
    yesterdayDistinctUsers: 0,
    from: { count: 0, distinctUsers: 0, yesterdayCount: 0, yesterdayDistinctUsers: 0 },
    delay: 50,
    config: config.molasses,
  }))

  useEffect(() => {
    if (data?.count) {
      spring.count.start(data?.count)
      spring.distinctUsers.start(data?.distinctUsers?.length)
    }
  }, [data?.count, data?.distinctUsers?.length])

  useEffect(() => {
    if (dataYesterday?.count) {
      spring.yesterdayCount.start(dataYesterday?.count)
      spring.yesterdayDistinctUsers.start(dataYesterday?.distinctUsers?.length)
    }
  }, [dataYesterday?.count, dataYesterday?.distinctUsers?.length])

  return (
    <DashboardWidget loading={isFetching} sx={{ minWidth: '300px', minHeight: '250px' }}>
      <Box
        sx={{
          '& > span': {
            fontSize: 50,
          },
          '& > p': {
            fontSize: 32,
          },
        }}
      >
        <Typography variant="h4">{intl.formatMessage({ id: 'dashboard.playedToday' })}</Typography>
        <animated.span>{spring.count.to((x) => x.toFixed(0))}</animated.span>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h6" sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <>
              {intl.formatMessage({ id: 'dashboard.distinctPlayers' })}
              <animated.span>{spring.distinctUsers.to((x) => x.toFixed(0))}</animated.span>
            </>
          </Typography>
        </Box>
        <hr />
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h6" sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <>
              {intl.formatMessage({ id: 'dashboard.playedYesterday' })}
              <animated.span>{spring.yesterdayCount.to((x) => x.toFixed(0))}</animated.span>
            </>
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h6" sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <>
              {intl.formatMessage({ id: 'dashboard.distinctPlayers' })}
              <animated.span>{spring.yesterdayDistinctUsers.to((x) => x.toFixed(0))}</animated.span>
            </>
          </Typography>
        </Box>
      </Box>
    </DashboardWidget>
  )
}
