import { MutableRefObject, useState } from 'react'
import { Autocomplete as MUIAutocomplete, AutocompleteProps, Typography, useTheme } from '@mui/material'
import { get } from 'lodash-es'
import { Controller, RegisterOptions } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useFormInnerContext } from '../utils/FormInnerProvider'
import { useHelperText } from '../utils/form-helpers'

export function Autocomplete<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  name,
  disabled,
  rules,
  label,
  onChangeProps,
  inputRef,
  ...rest
}: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> & {
  name: string
  label?: string
  rules?: RegisterOptions
  onChangeProps?: () => void
  inputRef?: MutableRefObject<T>
}) {
  const [autocompleteOpen, setAutocompleteOpen] = useState(false)

  const { isSubmitting, errors, control, formName, form, schema } = useFormInnerContext()
  const intl = useIntl()
  const theme = useTheme()
  const resolvedLabel =
    label !== undefined
      ? label
      : intl.messages[`${formName}.${name}`] ?? intl.messages[`shared.${name}`] ?? `${formName}.${name}`
  const error = get(errors, name)
  const helperText = useHelperText(error, resolvedLabel)
  // const path = name.split('.')
  // let spec: any | undefined
  // for (const p of path) {
  //   spec = spec ? spec?.fields[p] : schema?.fields[p]
  // }

  // const required = !disabled && (spec?.presence === 'required' || rules?.required)

  return (
    <Controller
      render={({ field }) => (
        <>
          <MUIAutocomplete<T, Multiple, DisableClearable, FreeSolo>
            disabled={disabled || isSubmitting}
            {...field}
            onChange={(_, value) => {
              form.setValue(name, value)
              inputRef?.current?.focus()
              onChangeProps?.()
            }}
            open={autocompleteOpen}
            onOpen={() => {
              setAutocompleteOpen(true)
            }}
            onClose={() => {
              setAutocompleteOpen(false)
            }}
            onBlur={() => {
              form.setValue('search', '')
            }}
            {...rest}
          />
          <Typography variant="body2" sx={{ color: `${theme.palette.error.main}` }}>
            {helperText}
          </Typography>
        </>
      )}
      control={control}
      rules={rules}
      name={name}
    />
  )
}
