import { FC } from 'react'
import styled from 'styled-components'
import { Loading } from '../atoms'
import { prop, theme } from 'styled-tools'

const LoadingWrapper = styled.div<any>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background: ${prop('background', theme('theme.background.default'))};
  position: ${({ fullScreen }) => (fullScreen ? 'absolute' : undefined)};
`

interface Props {
  background?: string | Function
  fullScreen?: boolean
}

export const LoadingTemplate: FC<Props> = ({ background, fullScreen }) => {
  return (
    <LoadingWrapper background={background} fullScreen={fullScreen}>
      <Loading />
    </LoadingWrapper>
  )
}
