// import 'source-map-support/register'
import './app/initFirebase'
import React from 'react'
import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'
import App from './app/app'
import { env } from './app/env'
import { createRoot } from 'react-dom/client'

if (env.environment && env.environment !== 'local') {
  Sentry.init({
    dsn: 'https://b254e144572f4b98a170f24170a04242@sentry.prorocketeers.com/7',
    integrations: [new Integrations.BrowserTracing()],
    environment: env.environment,
    release: env.release,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
