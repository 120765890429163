import { FC, ReactNode, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { usePopper } from 'react-popper'
import styled from 'styled-components'

const ARROW_SIZE = 8

const StyledContent = styled.div`
  padding: 0.3125rem 0.5rem;
  white-space: normal;
  color: white;
  background-color: black;
  box-shadow: 0 0.25rem 0.5rem 0 rgba(48, 65, 109, 0.2);
  border-radius: 1px;
  font-size: 0.875rem;
`

const Arrow = styled.span`
  width: ${ARROW_SIZE}px;
  height: ${ARROW_SIZE}px;
  background-color: black;
  transform: rotate(45deg);
`

const getArrowStyles = (styles, attributes) => {
  const placement = attributes?.popper?.['data-popper-placement']
  const arrowStyles = { ...styles.arrow, transform: `${styles.arrow?.transform} rotate(45deg)` }
  if (placement?.includes?.('bottom')) {
    return { ...arrowStyles, top: -ARROW_SIZE / 2 }
  } else if (placement?.includes?.('right')) {
    return { ...arrowStyles, left: -ARROW_SIZE / 2 }
  } else if (placement?.includes?.('left')) {
    return { ...arrowStyles, right: -ARROW_SIZE / 2 }
  } else if (placement?.includes?.('top')) {
    return { ...arrowStyles, bottom: -ARROW_SIZE / 2 }
  } else {
    return arrowStyles
  }
}

interface TooltipProps {
  placement?: 'bottom' | 'right' | 'top' | 'left'
  text: ReactNode
}

export const Tooltip: FC<TooltipProps> = ({ children, placement, text }) => {
  const referenceElement = useRef(null)
  const [popperElement, setPopperElement] = useState<any>(null) // we need to rerender when popperElement is changed
  const arrowElement = useRef(null)

  const { styles, attributes } = usePopper(referenceElement.current, popperElement, {
    modifiers: [
      { name: 'arrow', options: { element: arrowElement.current, padding: ARROW_SIZE / 2 } },
      {
        name: 'offset',
        options: {
          offset: [0, ARROW_SIZE / 2],
        },
      },
    ],
    strategy: 'fixed',
    placement: placement,
  })
  const [open, setOpen] = useState(false)

  const popperStyle = open
    ? styles.popper
    : {
        display: 'none',
      }

  // hack arrow position because popper.js sux
  const arrowStyles = getArrowStyles(styles, attributes)

  return (
    <>
      <div ref={referenceElement} onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
        {children}
      </div>
      {open &&
        ReactDOM.createPortal(
          <div
            ref={setPopperElement}
            style={{
              ...popperStyle,
              zIndex: 2147483647, // You shall be beneath only, and not be above. The Mighty Tooltip is above all things.
            }}
            {...attributes.popper}
          >
            <StyledContent>{text}</StyledContent>
            <Arrow ref={arrowElement} style={arrowStyles} />
          </div>,
          document.body,
        )}
    </>
  )
}
