import { getAuth, Auth, User } from 'firebase/auth'

export function getCurrentUser(providedAuth?: Auth): Promise<User | null> {
  const auth = providedAuth || getAuth()
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      unsubscribe()
      resolve(user)
      return user
    }, reject)
  })
}
