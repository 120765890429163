/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum GoalType {
  TimeLevelGoal = 'TimeLevelGoal',
  StageCountLevelGoal = 'StageCountLevelGoal',
}

export function GoalTypeFromJSON(json: any): GoalType {
  return GoalTypeFromJSONTyped(json, false)
}

export function GoalTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): GoalType {
  return json as GoalType
}

export function GoalTypeToJSON(value?: GoalType | null): any {
  return value as any
}
