import * as Types from '@proxyqb/graphql-api-types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export const IsUserDeletedDocument = gql`
    query isUserDeleted($email: String, $phoneNumber: String) {
  isUserDeleted(email: $email, phoneNumber: $phoneNumber)
}
    `;

export function useIsUserDeletedQuery(options?: Omit<Urql.UseQueryArgs<IsUserDeletedQueryVariables>, 'query'>) {
  return Urql.useQuery<IsUserDeletedQuery, IsUserDeletedQueryVariables>({ query: IsUserDeletedDocument, ...options });
};
export type IsUserDeletedQueryVariables = Types.Exact<{
  email?: Types.InputMaybe<Types.Scalars['String']>;
  phoneNumber?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type IsUserDeletedQuery = { __typename: 'Query', isUserDeleted?: boolean | null };
