/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'

/**
 * DefaultApi - interface
 *
 * @export
 * @interface DefaultApiInterface
 */
export interface DefaultApiInterface {
  /**
   *
   * @summary Livez
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApiInterface
   */
  livezLivezGetRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<any>>

  /**
   * Livez
   */
  livezLivezGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any>

  /**
   *
   * @summary Readyz
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApiInterface
   */
  readyzReadyzGetRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<any>>

  /**
   * Readyz
   */
  readyzReadyzGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any>

  /**
   *
   * @summary Version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApiInterface
   */
  versionVersionGetRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<any>>

  /**
   * Version
   */
  versionVersionGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any>
}

/**
 *
 */
export class DefaultApi extends runtime.BaseAPI implements DefaultApiInterface {
  /**
   * Livez
   */
  async livezLivezGetRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<any>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/livez`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    if (this.isJsonMime(response.headers.get('content-type'))) {
      return new runtime.JSONApiResponse<any>(response)
    } else {
      return new runtime.TextApiResponse(response) as any
    }
  }

  /**
   * Livez
   */
  async livezLivezGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
    const response = await this.livezLivezGetRaw(initOverrides)
    return await response.value()
  }

  /**
   * Readyz
   */
  async readyzReadyzGetRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<any>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/readyz`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    if (this.isJsonMime(response.headers.get('content-type'))) {
      return new runtime.JSONApiResponse<any>(response)
    } else {
      return new runtime.TextApiResponse(response) as any
    }
  }

  /**
   * Readyz
   */
  async readyzReadyzGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
    const response = await this.readyzReadyzGetRaw(initOverrides)
    return await response.value()
  }

  /**
   * Version
   */
  async versionVersionGetRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<any>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/version`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    if (this.isJsonMime(response.headers.get('content-type'))) {
      return new runtime.JSONApiResponse<any>(response)
    } else {
      return new runtime.TextApiResponse(response) as any
    }
  }

  /**
   * Version
   */
  async versionVersionGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
    const response = await this.versionVersionGetRaw(initOverrides)
    return await response.value()
  }
}
