/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
/**
 *
 * @export
 * @interface MovingTime
 */
export interface MovingTime {
  /**
   *
   * @type {string}
   * @memberof MovingTime
   */
  cubeId: string
  /**
   *
   * @type {number}
   * @memberof MovingTime
   */
  value: number
}

/**
 * Check if a given object implements the MovingTime interface.
 */
export function instanceOfMovingTime(value: object): boolean {
  if (!('cubeId' in value)) return false
  if (!('value' in value)) return false
  return true
}

export function MovingTimeFromJSON(json: any): MovingTime {
  return MovingTimeFromJSONTyped(json, false)
}

export function MovingTimeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MovingTime {
  if (json == null) {
    return json
  }
  return {
    cubeId: json['cubeId'],
    value: json['value'],
  }
}

export function MovingTimeToJSON(value?: MovingTime | null): any {
  if (value == null) {
    return value
  }
  return {
    cubeId: value['cubeId'],
    value: value['value'],
  }
}
