import { defineMessages } from 'react-intl'
import { useAsync } from 'react-use'
import { flattenTranslations } from '@proxyqb/shared-translations'

type Loader = (locale: string) => Promise<any>

export const useTranslationsMessages = (locale: string, loaders: Loader[] = []) => {
  const state = useAsync(async () => {
    const all = await Promise.all(loaders.map((loader) => loader(locale)))
    const flatAll = all.map(flattenTranslations)
    const reduced = flatAll.reduce((acc, curr) => ({ ...acc, ...curr }), {})
    const messages = Object.fromEntries(
      Object.entries(reduced).map(([key, value]) => [key.replace(/^default\./, ''), value]),
    ) as any
    return defineMessages(messages)
  }, [locale])

  return state
}
