import {
  Box,
  FormHelperText,
  IconButton,
  InputLabelProps,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material'
import { get, isEmpty } from 'lodash-es'
import { MouseEventHandler, useState } from 'react'
import { Controller, RegisterOptions } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useFormInnerContext } from '../utils/FormInnerProvider'
import { useHelperText, useResolveRequired } from '../utils/form-helpers'
import { Flex } from '@proxyqb/ui'
import { RemoveCircle } from '@mui/icons-material'
import { CSSProperties } from '@mui/styled-engine'

type Props = {
  accept: string
  name: string
  type?: string
  rules?: RegisterOptions
  disabled?: boolean
  label?: string
  helperText?: string
  onClick?: MouseEventHandler<HTMLDivElement>
  InputLabelProps?: InputLabelProps
} & Pick<TextFieldProps, 'multiline' | 'variant' | 'autoFocus' | 'sx' | 'InputProps'>

const imgStyle: CSSProperties = {
  maxWidth: '100%',
  maxHeight: '300px',
  width: 'auto',
  height: 'auto',
}

export function FileInput({ name, type, rules, label, disabled, helperText, accept, ...rest }: Props) {
  const { isSubmitting, errors, control, formName } = useFormInnerContext()
  const intl = useIntl()
  const resolvedLabel = (
    label !== undefined
      ? label
      : intl.messages[`${formName}.${name}`] ?? intl.messages[`shared.${name}`] ?? `${formName}.${name}`
  ) as string
  const error = get(errors, name)
  const resolvedHelperText = useHelperText(error, resolvedLabel) ?? helperText
  const required = useResolveRequired(name, rules, disabled)
  const [value, setValue] = useState('')
  const videoFormats = ['webm']
  const isVideo = (url: string) => videoFormats.some((format) => url.includes('.' + format))
  return (
    <>
      <Typography>{resolvedLabel}</Typography>
      <Controller
        render={({ field }) => (
          <>
            {field.value?.signedUrl ? (
              <Box position="relative">
                {isVideo(field.value.signedUrl) ? (
                  <video style={imgStyle} autoPlay controls>
                    <source src={field.value.signedUrl} type="video/webm" />
                  </video>
                ) : (
                  <img src={field.value.signedUrl} style={imgStyle} />
                )}
                <IconButton
                  sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                  }}
                  onClick={() => {
                    field.onChange({})
                  }}
                >
                  <RemoveCircle color="error" />
                </IconButton>
              </Box>
            ) : (
              <Flex flexDirection="column">
                <div>
                  {field.value && !isEmpty(field.value) && (
                    <>
                      {field.value[0]?.type.startsWith('video/') ? (
                        <video style={imgStyle} autoPlay controls>
                          <source src={window.URL.createObjectURL(field.value[0])} type="video/webm" />
                        </video>
                      ) : (
                        <img src={window.URL.createObjectURL(field.value[0])} style={imgStyle} />
                      )}
                    </>
                  )}
                </div>
                <input
                  type="file"
                  disabled={disabled || isSubmitting}
                  id={name}
                  autoCorrect="off"
                  autoComplete="off"
                  spellCheck="false"
                  {...field}
                  value={value}
                  {...rest}
                  accept={accept}
                  onChange={(e) => {
                    setValue(e.target.value)
                    field.onChange(e.target.files)
                  }}
                />
              </Flex>
            )}
          </>
        )}
        control={control}
        rules={{
          ...rules,
          required,
        }}
        name={name}
      />
      {resolvedHelperText && (
        <FormHelperText color="error" error>
          {resolvedHelperText}
        </FormHelperText>
      )}
    </>
  )
}
