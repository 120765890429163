import { FC } from 'react'
import styled from 'styled-components'
import { Text } from '../atoms'

const StyledTodo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
`

interface Props {}

export const TodoTemplate: FC<Props> = ({}) => {
  return (
    <StyledTodo>
      <div style={{ fontSize: '200px' }}>🚧</div>
      <Text variant="h1">In construction</Text>
    </StyledTodo>
  )
}
