import { Theme } from '@mui/material'

declare module 'styled-components' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefaultTheme extends Theme {}
}

export type ColorFunction = (args: { theme: Theme }) => string

export const defaultThemeType: 'dark' | 'light' =
  window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
