/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { GameType } from './GameType'
import { GameTypeFromJSON, GameTypeFromJSONTyped, GameTypeToJSON } from './GameType'
import type { Level } from './Level'
import { LevelFromJSON, LevelFromJSONTyped, LevelToJSON } from './Level'
import type { User } from './User'
import { UserFromJSON, UserFromJSONTyped, UserToJSON } from './User'

/**
 *
 * @export
 * @interface Playthrough
 */
export interface Playthrough {
  /**
   *
   * @type {string}
   * @memberof Playthrough
   */
  id: string
  /**
   *
   * @type {User}
   * @memberof Playthrough
   */
  user: User
  /**
   *
   * @type {Level}
   * @memberof Playthrough
   */
  level: Level
  /**
   *
   * @type {string}
   * @memberof Playthrough
   */
  endedAt: string
  /**
   *
   * @type {number}
   * @memberof Playthrough
   */
  score: number
  /**
   *
   * @type {string}
   * @memberof Playthrough
   */
  planId?: string
  /**
   *
   * @type {any}
   * @memberof Playthrough
   */
  plan?: any
  /**
   *
   * @type {GameType}
   * @memberof Playthrough
   */
  gameType: GameType
}

/**
 * Check if a given object implements the Playthrough interface.
 */
export function instanceOfPlaythrough(value: object): boolean {
  if (!('id' in value)) return false
  if (!('user' in value)) return false
  if (!('level' in value)) return false
  if (!('endedAt' in value)) return false
  if (!('score' in value)) return false
  if (!('gameType' in value)) return false
  return true
}

export function PlaythroughFromJSON(json: any): Playthrough {
  return PlaythroughFromJSONTyped(json, false)
}

export function PlaythroughFromJSONTyped(json: any, ignoreDiscriminator: boolean): Playthrough {
  if (json == null) {
    return json
  }
  return {
    id: json['id'],
    user: UserFromJSON(json['user']),
    level: LevelFromJSON(json['level']),
    endedAt: json['endedAt'],
    score: json['score'],
    planId: json['planId'] == null ? undefined : json['planId'],
    plan: json['plan'] == null ? undefined : json['plan'],
    gameType: GameTypeFromJSON(json['gameType']),
  }
}

export function PlaythroughToJSON(value?: Playthrough | null): any {
  if (value == null) {
    return value
  }
  return {
    id: value['id'],
    user: UserToJSON(value['user']),
    level: LevelToJSON(value['level']),
    endedAt: value['endedAt'],
    score: value['score'],
    planId: value['planId'],
    plan: value['plan'],
    gameType: GameTypeToJSON(value['gameType']),
  }
}
