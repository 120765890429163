import { PHONE_NUMBER_REGEX } from '@proxyqb/ui'
import { InferType, object, string } from 'yup'
import { PASSWORD_LENGTH } from '../../../doctors/form/doctor-form-schema'

export const doctorRegistrationSchema = object({
  prenominalLetters: string().trim().nullable(),
  postnominalLetters: string().trim().nullable(),
  firstName: string().trim().required(),
  lastName: string().trim().required(),
  email: string().trim().email().required(),
  password: string().trim().min(PASSWORD_LENGTH).matches(/[0-9]/).required(),
  phoneNumber: string()
    .trim()
    .matches(PHONE_NUMBER_REGEX, {
      excludeEmptyString: true,
    })
    .nullable(),
})
  .required()
  .default({
    phoneNumber: '',
    email: '',
    lastName: '',
    firstName: '',
    password: '',
    postnominalLetters: '',
    prenominalLetters: '',
  })

export type DoctorRegistrationFields = InferType<typeof doctorRegistrationSchema>
