import { FormEventHandler, ReactNode, useState } from 'react'
import styled from 'styled-components'
import { FormInnerProvider } from '../utils/FormInnerProvider'
import { FormProvider, SubmitHandler, UseFormReturn } from 'react-hook-form'
import { ObjectSchema, setLocale } from 'yup'
import { useEffectOnce } from 'react-use'
import { useIntl } from 'react-intl'

type FormProps<T extends Record<string, any>> = {
  children: ReactNode
  isSubmitting?: boolean
  form: UseFormReturn<T>
  onSubmit?: SubmitHandler<T>
  onReset?: FormEventHandler<HTMLFormElement>
  name: string
  schema?: ObjectSchema<any>
}

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: 100%;
`

export const Form = function <Fields>({
  children,
  onSubmit,
  form,
  name,
  schema,
  ...rest
}: FormProps<Fields>) {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = form
  const submitHandler = (onSubmit) => (e) => {
    setIsSubmitting(true)
    e.preventDefault()
    const result = onSubmit?.(e)
    result.finally &&
      result.finally(() => {
        setIsSubmitting(false)
      })
  }

  const { formatMessage } = useIntl()
  useEffectOnce(() => {
    setLocale({
      number: {
        min: ({ min }) => formatMessage({ id: 'shared.form.error.min' }, { minValue: min }),
      },
    })
  })

  return (
    <FormProvider {...form}>
      <FormInnerProvider
        errors={errors}
        isSubmitting={isSubmitting}
        control={control}
        formName={name}
        form={form}
        schema={schema}
      >
        <StyledForm onSubmit={onSubmit && submitHandler(handleSubmit(onSubmit))} {...(rest as any)} id={name}>
          {children}
        </StyledForm>
      </FormInnerProvider>
    </FormProvider>
  )
}
