/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { GoalType } from './GoalType'
import { GoalTypeFromJSON, GoalTypeFromJSONTyped, GoalTypeToJSON } from './GoalType'
import type { Sort } from './Sort'
import { SortFromJSON, SortFromJSONTyped, SortToJSON } from './Sort'

/**
 *
 * @export
 * @interface PlaythroughFilter
 */
export interface PlaythroughFilter {
  /**
   *
   * @type {Array<string>}
   * @memberof PlaythroughFilter
   */
  levelIds?: Array<string>
  /**
   *
   * @type {Array<string>}
   * @memberof PlaythroughFilter
   */
  patientIds: Array<string>
  /**
   *
   * @type {Array<string>}
   * @memberof PlaythroughFilter
   */
  planIds?: Array<string>
  /**
   *
   * @type {GoalType}
   * @memberof PlaythroughFilter
   */
  goalType?: GoalType
  /**
   *
   * @type {Array<string>}
   * @memberof PlaythroughFilter
   */
  fields?: Array<string>
  /**
   *
   * @type {Sort}
   * @memberof PlaythroughFilter
   */
  sort?: Sort
}

/**
 * Check if a given object implements the PlaythroughFilter interface.
 */
export function instanceOfPlaythroughFilter(value: object): boolean {
  if (!('patientIds' in value)) return false
  return true
}

export function PlaythroughFilterFromJSON(json: any): PlaythroughFilter {
  return PlaythroughFilterFromJSONTyped(json, false)
}

export function PlaythroughFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlaythroughFilter {
  if (json == null) {
    return json
  }
  return {
    levelIds: json['levelIds'] == null ? undefined : json['levelIds'],
    patientIds: json['patientIds'],
    planIds: json['planIds'] == null ? undefined : json['planIds'],
    goalType: json['goalType'] == null ? undefined : GoalTypeFromJSON(json['goalType']),
    fields: json['fields'] == null ? undefined : json['fields'],
    sort: json['sort'] == null ? undefined : SortFromJSON(json['sort']),
  }
}

export function PlaythroughFilterToJSON(value?: PlaythroughFilter | null): any {
  if (value == null) {
    return value
  }
  return {
    levelIds: value['levelIds'],
    patientIds: value['patientIds'],
    planIds: value['planIds'],
    goalType: GoalTypeToJSON(value['goalType']),
    fields: value['fields'],
    sort: SortToJSON(value['sort']),
  }
}
