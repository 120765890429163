import { Box, Drawer, IconButton, useMediaQuery, useTheme } from '@mui/material'
import { FC, PropsWithChildren, ReactNode, useState } from 'react'
import MenuIcon from '@mui/icons-material/Menu'
import { Breadcrumbs } from '@proxyqb/breadcrumbs'

interface Props {
  menu: ReactNode
}

export const MainTemplate: FC<PropsWithChildren<Props>> = ({ menu, children }) => {
  const [mobileOpen, setMobileOpen] = useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const theme = useTheme()
  const isBiggerThanSm = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <Box
      sx={{
        width: '100vw',
        minHeight: '100vh',
        display: { sm: 'grid' },
        gridTemplateColumns: {
          sm: '260px auto',
        },
        background: `${({ theme }) => theme.palette.background.default}`,
      }}
    >
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{ display: { sm: 'none' }, position: 'absolute', top: 0, left: '10px', zIndex: 1 }}
      >
        <MenuIcon />
      </IconButton>
      {!isBiggerThanSm && (
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: {
              xs: 'block',
              sm: 'none',
            },
          }}
        >
          {menu}
        </Drawer>
      )}
      <Box
        sx={{
          display: {
            xs: 'none',
            sm: 'block',
          },
        }}
      >
        {menu}
      </Box>
      <Box
        sx={{
          paddingX: {
            xs: theme.mainScreenSpacing.pxSmall,
            sm: theme.mainScreenSpacing.px,
          },
          paddingY: theme.mainScreenSpacing.py,
          position: 'relative',
          gridColumn: 2,
        }}
      >
        <Breadcrumbs ignorePaths={['/dashboard']} />
        {children}
      </Box>
    </Box>
  )
}
