import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material'
import { csCZ, enUS } from '@mui/x-data-grid'
import { useGraphqlClient, GlobalStateProvider } from '@proxyqb/portal-global-state'
import { useInitFlagsmith } from '@proxyqb/react-feature-flags'
import { useTranslationsMessages } from '@proxyqb/react-translations'
import { loadSharedTranslations } from '@proxyqb/shared-translations'
import { LanguageContext, lightMuiThemeOptions } from '@proxyqb/ui'
import { useContext } from 'react'
import { IntlProvider } from 'react-intl'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { ThemeProvider } from 'styled-components'
import { Provider } from 'urql'
import { env } from './env'
import { loadTranslations } from './shared/translations/translations'
import { theme } from './theme'

export const queryClient = new QueryClient({ defaultOptions: { queries: { staleTime: 60000 } } })

const translationLoaders = [loadSharedTranslations, loadTranslations]

export const GlobalProviders = ({ children }) => {
  return (
    <GlobalStateProvider>
      <InnerProviders>{children}</InnerProviders>
    </GlobalStateProvider>
  )
}
export const InnerProviders = ({ children }) => {
  const { userLanguage } = useContext(LanguageContext)
  const messages = useTranslationsMessages(userLanguage, translationLoaders)
  const { isLoadingFlags } = useInitFlagsmith(env.flagsmith.environmentID)
  const client = useGraphqlClient()
  if (messages.loading || isLoadingFlags || !client) {
    return null
  }
  const gridLanguage = userLanguage === 'cs' ? csCZ : enUS
  const muiTheme = createTheme(lightMuiThemeOptions, theme, gridLanguage)
  // TODO: get user from authService and fix this
  // let theme = defaultThemeType === Theme.dark ? muiTheme : lightMuiTheme
  // if (user?.preferences?.theme) {
  //   theme = user?.preferences?.theme === Theme.dark ? muiTheme : lightMuiTheme
  // }
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      <Provider value={client}>
        <IntlProvider locale={userLanguage} messages={messages.value}>
          <ThemeProvider theme={muiTheme}>
            <MuiThemeProvider theme={muiTheme}>{children}</MuiThemeProvider>
          </ThemeProvider>
        </IntlProvider>
      </Provider>
    </QueryClientProvider>
  )
}
