import * as Types from '@proxyqb/graphql-api-types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export const DocumentGroupListDetailFragmentDoc = gql`
    fragment DocumentGroupListDetail on DocumentGroup {
  id
  name {
    local
  }
  collectionName
  accessRights {
    accessRights
  }
}
    `;
export const DocumentInGroupsDocument = gql`
    query documentInGroups($documentId: ID!) {
  documentInGroups(documentId: $documentId) {
    ...DocumentGroupListDetail
  }
}
    ${DocumentGroupListDetailFragmentDoc}`;

export function useDocumentInGroupsQuery(options: Omit<Urql.UseQueryArgs<DocumentInGroupsQueryVariables>, 'query'>) {
  return Urql.useQuery<DocumentInGroupsQuery, DocumentInGroupsQueryVariables>({ query: DocumentInGroupsDocument, ...options });
};
export type DocumentGroupListDetailFragment = { __typename: 'DocumentGroup', id: string, collectionName: string, name: { __typename: 'LocalizedText', local: string }, accessRights: Array<{ __typename: 'AccessRight', accessRights: Array<Types.AccessRightType> }> };

export type DocumentInGroupsQueryVariables = Types.Exact<{
  documentId: Types.Scalars['ID'];
}>;


export type DocumentInGroupsQuery = { __typename: 'Query', documentInGroups: Array<{ __typename: 'DocumentGroup', id: string, collectionName: string, name: { __typename: 'LocalizedText', local: string }, accessRights: Array<{ __typename: 'AccessRight', accessRights: Array<Types.AccessRightType> }> }> };
