import { Button, Grid, Typography } from '@mui/material'
import { ReactElement } from 'react'
import { useGlobalState } from '@proxyqb/portal-global-state'
import { MaskIcon } from '@proxyqb/ui'
import { useIntl } from 'react-intl'

export const AccessNotAllowedScreen = (): ReactElement => {
  const { authService } = useGlobalState()
  const { formatMessage } = useIntl()

  const logout = (): void => {
    authService.send('LOG_OUT')
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        paddingTop: 5,
      }}
    >
      <Grid item>
        <Typography variant="h4">{formatMessage({ id: 'accessNotAllowed.message' })}</Typography>
      </Grid>
      <Grid item>
        <Button
          onClick={logout}
          sx={{
            mt: '1vw',
            color: 'text.primary',
            textTransform: 'initial',
            justifyContent: 'flex-start',
          }}
        >
          <MaskIcon url="/static/media/icons/exit_to_app-filled.svg" sx={{ mr: 1 }} />
          {formatMessage({ id: 'menu.logout' })}
        </Button>
      </Grid>
    </Grid>
  )
}

export default AccessNotAllowedScreen
