/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
/**
 *
 * @export
 * @interface User
 */
export interface User {
  /**
   *
   * @type {string}
   * @memberof User
   */
  firstName: string
  /**
   *
   * @type {string}
   * @memberof User
   */
  lastName: string
}

/**
 * Check if a given object implements the User interface.
 */
export function instanceOfUser(value: object): boolean {
  if (!('firstName' in value)) return false
  if (!('lastName' in value)) return false
  return true
}

export function UserFromJSON(json: any): User {
  return UserFromJSONTyped(json, false)
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
  if (json == null) {
    return json
  }
  return {
    firstName: json['firstName'],
    lastName: json['lastName'],
  }
}

export function UserToJSON(value?: User | null): any {
  if (value == null) {
    return value
  }
  return {
    firstName: value['firstName'],
    lastName: value['lastName'],
  }
}
