/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
/**
 *
 * @export
 * @interface Level
 */
export interface Level {
  /**
   *
   * @type {any}
   * @memberof Level
   */
  name?: any
  /**
   *
   * @type {string}
   * @memberof Level
   */
  id: string
}

/**
 * Check if a given object implements the Level interface.
 */
export function instanceOfLevel(value: object): boolean {
  if (!('id' in value)) return false
  return true
}

export function LevelFromJSON(json: any): Level {
  return LevelFromJSONTyped(json, false)
}

export function LevelFromJSONTyped(json: any, ignoreDiscriminator: boolean): Level {
  if (json == null) {
    return json
  }
  return {
    name: json['name'] == null ? undefined : json['name'],
    id: json['id'],
  }
}

export function LevelToJSON(value?: Level | null): any {
  if (value == null) {
    return value
  }
  return {
    name: value['name'],
    id: value['id'],
  }
}
