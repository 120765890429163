import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MUISelect,
  SelectProps,
} from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { get } from 'lodash-es'
import { FC, PropsWithChildren } from 'react'
import { Controller, RegisterOptions } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useFormInnerContext } from '../utils/FormInnerProvider'
import { useHelperText } from '../utils/form-helpers'

interface Props {
  name: string
  label?: string
  rules?: RegisterOptions
  multiple?: boolean
  disabled?: boolean
}

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      width: '100%',
    },
  }),
)

const SelectComponent = ({
  name,
  label,
  children,
  rules,
  multiple,
  disabled,
  ...rest
}: PropsWithChildren<Props> & Partial<SelectProps>) => {
  const { isSubmitting, errors, formName, control } = useFormInnerContext()
  const intl = useIntl()
  const classes = useStyles()
  const labelText = label ?? intl.messages[`${formName}.${name}`] ?? intl.messages[`shared.${name}`]

  const error = get(errors, name)
  const helperText = useHelperText(error, labelText)

  return (
    <Box flexDirection="column" justifyContent="flex-end">
      <Box alignItems="center">
        <FormControl
          variant="outlined"
          disabled={disabled || isSubmitting}
          className={classes.formControl}
          error={!!error}
        >
          <InputLabel htmlFor={`${formName}-${name}-label`} error={!!error}>{`${labelText}${
            rules?.required ? ' *' : ''
          }`}</InputLabel>
          <Controller
            control={control}
            rules={rules}
            name={name}
            render={({ field }) => (
              <MUISelect
                variant="outlined"
                labelId={`${formName}-${name}-label`}
                label={`${labelText}${rules?.required ? ' *' : ''}`}
                inputProps={{
                  id: `${formName}-${name}`,
                  name: name,
                }}
                error={!!error}
                autoWidth
                multiple={multiple}
                MenuProps={{
                  style: { zIndex: 100000 },
                }}
                {...field}
                name={name}
                value={field.value ?? ''}
                {...rest}
              >
                {children}
              </MUISelect>
            )}
          />
        </FormControl>
      </Box>
      {error && <FormHelperText error={!!error}>{helperText}</FormHelperText>}
    </Box>
  )
}

type OptionProps = {
  value: string | number
}

const Option: FC<PropsWithChildren<OptionProps>> = MenuItem

export const Select = Object.assign(SelectComponent, { Option })
