import React, { ReactNode, useMemo } from 'react'
import {
  DataGrid as DataGridMUI,
  DataGridProps as DataGridMUIProps,
  gridColumnPositionsSelector,
  gridColumnsTotalWidthSelector,
  useGridApiContext,
} from '@mui/x-data-grid'
import { makeStyles } from '@mui/styles'
import { Box, Skeleton, useTheme } from '@mui/material'
import { Flex } from '../atoms'
import { useIntl } from 'react-intl'

const useStyles = makeStyles({
  root: {
    'border-left': 'none',
    'border-right': 'none',
    '&.MuiDataGrid-root :focus': {
      outline: 'none',
    },
    '&.MuiDataGrid-root .MuiDataGrid-menuIcon': {
      // visibility: 'visible',
    },
    '.MuiDataGrid-iconButtonContainer': {
      // visibility: 'visible !important',
    },
  },
  hideRightSeparator: {
    '& > .MuiDataGrid-columnSeparator': {
      visibility: 'hidden',
    },
  },
})

const randomWidth = () => {
  return `${Math.floor(Math.random() * 100)}%`
}
const cellPadding = (index: number, columnCount: number) => {
  if (index % columnCount === 0) {
    return '0 0 0 80px'
  } else if ((index + 1) % columnCount === 0) {
    return '0 80px 0 20px'
  } else {
    return '0 0 0 20px'
  }
}

const NoRowsOverlay = () => {
  const { formatMessage } = useIntl()
  return (
    <Flex alignItems={'center'} justifyContent="center" height="100%">
      {formatMessage({ id: `shared.noRows` })}
    </Flex>
  )
}

const SkeletonLoadingOverlay = () => {
  const apiRef = useGridApiContext()
  const positions = gridColumnPositionsSelector(apiRef)
  const rowHeight = apiRef.current.unstable_getRowHeight()
  const totalWidth = gridColumnsTotalWidthSelector(apiRef)
  const inViewportCount = useMemo(
    () => positions.filter((value) => value <= totalWidth).length,
    [totalWidth, positions],
  )
  const columns = apiRef.current.getVisibleColumns().slice(0, inViewportCount)
  const children = useMemo(() => {
    const array: React.ReactNode[] = []
    for (let i = 0; i < 100; i++) {
      array.push(
        <Box
          key={i}
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: cellPadding(i, columns.length),
            justifyContent: 'start',
            borderBottom: '1px solid rgb(224, 224, 224)',
          }}
        >
          <Skeleton width={randomWidth()} sx={{ minWidth: '40%', maxWidth: '80%' }} />
        </Box>,
      )
    }
    return array
  }, [])
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: `${columns.map(({ computedWidth }) => `${computedWidth}px`).join(' ')}`,
        gridAutoRows: rowHeight,
      }}
    >
      {children}
    </div>
  )
}

export interface DataGridProps extends DataGridMUIProps {
  dataGridHeight?: string
  noRowsOverlay?: () => ReactNode
}

export const DataGrid: React.FC<DataGridProps> = ({
  dataGridHeight,
  noRowsOverlay = NoRowsOverlay,
  columns,
  rows,
  loading,
  ...rest
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const { formatMessage } = useIntl()
  return (
    <DataGridMUI
      {...rest}
      loading={loading}
      rows={loading ? [] : rows}
      columns={columns.map((column) => ({ ...column, headerClassName: classes.hideRightSeparator }))}
      className={classes.root}
      sx={{
        height: dataGridHeight || '100%',
        '.bestScorePlace1': { backgroundColor: theme.palette.primary.light },
      }}
      componentsProps={{
        pagination: {
          labelRowsPerPage: formatMessage({ id: `shared.rowsPerPage` }),
        },
      }}
      components={{
        LoadingOverlay: SkeletonLoadingOverlay,
        NoRowsOverlay: noRowsOverlay,
      }}
    />
  )
}
