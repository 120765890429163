import * as Types from '@proxyqb/graphql-api-types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export const DoctorGroupsRightsDocument = gql`
    query doctorGroupsRights($documentId: ID!) {
  allAccessRightsToDocument(
    to: $documentId
    filter: {node: {id: {startsWith: "documentGroups-"}}}
  ) {
    node {
      id
      ... on DocumentGroup {
        name {
          local
        }
      }
    }
    accessRights {
      accessRights
    }
  }
}
    `;

export function useDoctorGroupsRightsQuery(options: Omit<Urql.UseQueryArgs<DoctorGroupsRightsQueryVariables>, 'query'>) {
  return Urql.useQuery<DoctorGroupsRightsQuery, DoctorGroupsRightsQueryVariables>({ query: DoctorGroupsRightsDocument, ...options });
};
export type DoctorGroupsRightsQueryVariables = Types.Exact<{
  documentId: Types.Scalars['ID'];
}>;


export type DoctorGroupsRightsQuery = { __typename: 'Query', allAccessRightsToDocument: Array<{ __typename: 'AccessRightForNode', node: { __typename: 'Admin', id: string } | { __typename: 'Doctor', id: string } | { __typename: 'DocumentGroup', id: string, name: { __typename: 'LocalizedText', local: string } } | { __typename: 'Patient', id: string } | { __typename: 'PublicDoctor', id: string } | { __typename: 'PublicUser', id: string }, accessRights: Array<{ __typename: 'AccessRight', accessRights: Array<Types.AccessRightType> }> }> };
