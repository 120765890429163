export const env = {
  graphqlUrl: import.meta.env.VITE_GRAPHQL_API_URL,
  firebase: {
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    appId: import.meta.env.VITE_FIREBASE_APP_ID,
    enablePerformance: import.meta.env.VITE_FIREBASE_ENABLE_PERFORMANCE === 'true',
    connectAuthEmulator: import.meta.env.VITE_FIREBASE_CONNECT_AUTH_EMULATOR,
    connectStorageEmulator: import.meta.env.VITE_FIREBASE_CONNECT_STORAGE_EMULATOR,
    storageHost: import.meta.env.VITE_FIREBASE_STORAGE_HOST,
    storagePort: import.meta.env.VITE_FIREBASE_STORAGE_PORT,
    storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET as string,
    imagesBucket: import.meta.env.VITE_FIREBASE_IMAGES_BUCKET as string,
  },
  environment: import.meta.env.VITE_ENVIRONMENT,
  release: import.meta.env.VITE_RELEASE,
  flagsmith: {
    environmentID: import.meta.env.VITE_FLAGSMITH_ENVIRONMENT_ID as string,
  },
  gaiaUrl: import.meta.env.VITE_GAIA_API_URL,
} as const
