import { FC, MouseEventHandler } from 'react'
import styled from 'styled-components'

const StyledIcon = styled.div`
  fill: red;
  font-size: 4rem;
  cursor: pointer;
  background: white;
  border-radius: 50%;
`

interface Props {
  icon: any
  onClick?: MouseEventHandler
}

export const IconButton: FC<Props> = ({ icon, ...rest }) => {
  return <StyledIcon as={icon} {...rest} />
}
