import { cloneElement, ReactElement, ReactNode, useState } from 'react'
import styled from 'styled-components'
import { Loading } from '../atoms'
import { Button } from '@mui/material'

const StyledOverlay = styled.div`
  backdrop-filter: blur(6px);
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 20000;
`
const StyledDialog = styled.div`
  border-radius: 10px;
  box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.05);
  background: ${({ theme }) => theme.palette.background.paper};
  position: fixed;
  z-index: 20100;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 400px;
`
const StyledTitle = styled.div`
  padding: 20px;
  text-transform: uppercase;
  font-weight: 600;
`
const StyledContent = styled.div`
  padding: 0 20px 20px 20px;
`
const StyledFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  background: rgba(0, 0, 0, 0.08);
  border-radius: 0 0 10px 10px;
  gap: 20px;
`

const StyledLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`

interface Props {
  onConfirm?(hide: () => void): void | Promise<void>
  onCancel?()
  title?: string
  content?: ReactNode
  okText?: string
  cancelText?: string
  children?: ReactElement<{ onClick: Function }>
  variant?: 'delete' | 'info' | 'text'
  defaultOpenState?: boolean
}

export function ConfirmDialog({
  title,
  content,
  onConfirm,
  onCancel,
  okText,
  cancelText,
  variant,
  children,
  defaultOpenState,
}: Props) {
  const [isOpen, setOpen] = useState(defaultOpenState)
  const [isLoading, setLoading] = useState(false)
  const confirmText = okText ?? (variant === 'delete' ? 'Delete' : 'Confirm')
  const handleConfirm = async () => {
    if (onConfirm) {
      setLoading(true)
      if (handleConfirm.constructor.name == 'AsyncFunction') {
        await onConfirm?.(() => {
          setOpen(false)
          setLoading(false)
        })
      } else {
        onConfirm?.(() => {
          setOpen(false)
          setLoading(false)
        })
      }
    } else {
      setOpen(false)
    }
  }

  return (
    <>
      {children && cloneElement(children, { onClick: () => setOpen(true) })}
      {isOpen && (
        <>
          <StyledOverlay />
          <StyledDialog>
            {isLoading ? (
              <StyledLoading>
                <Loading scale={0.25} />
              </StyledLoading>
            ) : (
              <>
                {title && <StyledTitle>{title}</StyledTitle>}
                <StyledContent>{content}</StyledContent>
              </>
            )}
            {variant !== 'text' && (
              <StyledFooter>
                {variant !== 'info' && (
                  <Button
                    variant="outlined"
                    onClick={() => {
                      onCancel?.()
                      setOpen(false)
                    }}
                  >
                    {cancelText ?? 'Cancel'}
                  </Button>
                )}
                <Button
                  variant="outlined"
                  onClick={handleConfirm}
                  disabled={isLoading}
                  color={variant === 'delete' ? 'secondary' : 'primary'}
                >
                  {confirmText}
                </Button>
              </StyledFooter>
            )}
          </StyledDialog>
        </>
      )}
    </>
  )
}
