import { FieldError, RegisterOptions } from 'react-hook-form'
import { MessageFormatElement, useIntl } from 'react-intl'
import { useFormInnerContext } from './FormInnerProvider'

export function useHelperText(error: FieldError | undefined, label: string | MessageFormatElement[]) {
  const intl = useIntl()
  if (!error) {
    return null
  }
  if (error.type === 'required') {
    return intl.formatMessage(
      { id: 'shared.form.error.required' },
      {
        fieldName: label,
      },
    )
  }
  if (error.type === 'pattern') {
    return intl.formatMessage(
      { id: 'shared.form.error.pattern' },
      {
        fieldName: label,
      },
    )
  }
  if (
    error.type === 'min' &&
    (error?.message?.startsWith('password') || error?.message?.startsWith('newPassword'))
  ) {
    return intl.formatMessage({ id: 'shared.form.error.passMin' })
  }
  if (error.type === 'email') {
    return intl.formatMessage({ id: 'shared.form.error.email' })
  }
  if (error.type === 'max') {
    return intl.formatMessage(
      { id: 'shared.form.error.max' },
      {
        fieldName: label,
      },
    )
  }
  if (error?.type === 'matches' && error?.message?.startsWith('phoneNumber')) {
    return intl.formatMessage({ id: 'shared.form.error.phoneNumber' })
  }
  if (error?.type === 'matches') {
    return intl.formatMessage({ id: 'shared.form.error.passMatches' })
  }
  if (error?.type === 'YYYY-MM format of date' && error?.message?.startsWith('dateOfBirth')) {
    return intl.formatMessage({ id: 'shared.form.error.dateOfBirth' })
  }
  if (error?.type === 'invalid file size' && error?.message === 'file is invalid') {
    return intl.formatMessage({ id: 'shared.form.error.size' })
  }
  if (error?.type === 'invalid file type' && error?.message === 'file is invalid') {
    return intl.formatMessage({ id: 'shared.form.error.type' })
  }
  if (error.message) {
    return error.message
  }
}

export const useResolveRequired = (name: string, rules?: RegisterOptions, disabled?: boolean) => {
  const { schema } = useFormInnerContext()
  const path = name.split('.')
  let spec: any | undefined
  for (const p of path) {
    spec = spec ? spec?.fields[p] : schema?.fields[p]
  }
  return (
    !disabled && (spec?.presence === 'required' || spec?.spec?.presence === 'required' || rules?.required)
  )
}
