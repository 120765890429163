/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type {
  BoxPlotData,
  Export,
  HTTPValidationError,
  LevelRunsChartData,
  Playthrough,
  PlaythroughCounter,
  PlaythroughFilter,
  ProbabilityDensityData,
  RehabPlanStatistic,
  RepeatedRunsChartData,
  ScoreChart,
  ScoreTable,
  ZScoreAndDensityFunction,
} from '../models/index'
import {
  BoxPlotDataFromJSON,
  BoxPlotDataToJSON,
  ExportFromJSON,
  ExportToJSON,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  LevelRunsChartDataFromJSON,
  LevelRunsChartDataToJSON,
  PlaythroughFromJSON,
  PlaythroughToJSON,
  PlaythroughCounterFromJSON,
  PlaythroughCounterToJSON,
  PlaythroughFilterFromJSON,
  PlaythroughFilterToJSON,
  ProbabilityDensityDataFromJSON,
  ProbabilityDensityDataToJSON,
  RehabPlanStatisticFromJSON,
  RehabPlanStatisticToJSON,
  RepeatedRunsChartDataFromJSON,
  RepeatedRunsChartDataToJSON,
  ScoreChartFromJSON,
  ScoreChartToJSON,
  ScoreTableFromJSON,
  ScoreTableToJSON,
  ZScoreAndDensityFunctionFromJSON,
  ZScoreAndDensityFunctionToJSON,
} from '../models/index'

export interface DeficiencyDetectorRequest {
  patientId: string
  rehabilitationPlanId: string
  fromAge?: number
  toAge?: number
  authorization?: string
}

export interface ExportPlaythroughsRequest {
  playthroughFilter: PlaythroughFilter
  authorization?: string
}

export interface GetLastPlaythroughsRequest {
  limit?: number
  authorization?: string
}

export interface GetLevelLastScoreTableRequest {
  limit: number
  playthroughFilter: PlaythroughFilter
  authorization?: string
}

export interface GetLevelRunsChartRequest {
  levelId: string
  userId: string
  authorization?: string
}

export interface GetLevelScoreChartRequest {
  playthroughFilter: PlaythroughFilter
  authorization?: string
}

export interface GetLevelTopScoreTableRequest {
  limit: number
  playthroughFilter: PlaythroughFilter
  authorization?: string
}

export interface GetPlaythroughCountRequest {
  fromDate?: Date
  toDate?: Date
  authorization?: string
}

export interface GetPlaythroughsRequest {
  playthroughFilter: PlaythroughFilter
  authorization?: string
}

export interface GetProbabilityDensityByLevelRequest {
  playthroughFilter: PlaythroughFilter
  authorization?: string
}

export interface GetRehabPlanStatisticsRequest {
  patientId: string
  rehabilitationPlanId: string
  authorization?: string
}

export interface GetRepeatedRunsChartRequest {
  levelId: string
  userId: string
  authorization?: string
}

export interface GetTimeLevelsBoxPlotRequest {
  playthroughFilter: PlaythroughFilter
  quantilesN?: number
  authorization?: string
}

/**
 * PlaythroughApi - interface
 *
 * @export
 * @interface PlaythroughApiInterface
 */
export interface PlaythroughApiInterface {
  /**
   *
   * @summary Get Deficiency
   * @param {string} patientId
   * @param {string} rehabilitationPlanId
   * @param {number} [fromAge]
   * @param {number} [toAge]
   * @param {string} [authorization]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlaythroughApiInterface
   */
  deficiencyDetectorRaw(
    requestParameters: DeficiencyDetectorRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ZScoreAndDensityFunction>>

  /**
   * Get Deficiency
   */
  deficiencyDetector(
    requestParameters: DeficiencyDetectorRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ZScoreAndDensityFunction>

  /**
   *
   * @summary Export
   * @param {PlaythroughFilter} playthroughFilter
   * @param {string} [authorization]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlaythroughApiInterface
   */
  exportPlaythroughsRaw(
    requestParameters: ExportPlaythroughsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Export>>

  /**
   * Export
   */
  exportPlaythroughs(
    requestParameters: ExportPlaythroughsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Export>

  /**
   *
   * @summary Get Last Route
   * @param {number} [limit]
   * @param {string} [authorization]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlaythroughApiInterface
   */
  getLastPlaythroughsRaw(
    requestParameters: GetLastPlaythroughsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<Playthrough>>>

  /**
   * Get Last Route
   */
  getLastPlaythroughs(
    requestParameters: GetLastPlaythroughsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<Playthrough>>

  /**
   *
   * @summary Get Level Last Score Table
   * @param {number} limit
   * @param {PlaythroughFilter} playthroughFilter
   * @param {string} [authorization]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlaythroughApiInterface
   */
  getLevelLastScoreTableRaw(
    requestParameters: GetLevelLastScoreTableRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<ScoreTable>>>

  /**
   * Get Level Last Score Table
   */
  getLevelLastScoreTable(
    requestParameters: GetLevelLastScoreTableRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<ScoreTable>>

  /**
   *
   * @summary Get Level Runs Chart
   * @param {string} levelId
   * @param {string} userId
   * @param {string} [authorization]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlaythroughApiInterface
   */
  getLevelRunsChartRaw(
    requestParameters: GetLevelRunsChartRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<LevelRunsChartData>>

  /**
   * Get Level Runs Chart
   */
  getLevelRunsChart(
    requestParameters: GetLevelRunsChartRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<LevelRunsChartData>

  /**
   *
   * @summary Get Level Score Chart
   * @param {PlaythroughFilter} playthroughFilter
   * @param {string} [authorization]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlaythroughApiInterface
   */
  getLevelScoreChartRaw(
    requestParameters: GetLevelScoreChartRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<ScoreChart>>>

  /**
   * Get Level Score Chart
   */
  getLevelScoreChart(
    requestParameters: GetLevelScoreChartRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<ScoreChart>>

  /**
   *
   * @summary Get Level Top Score Table
   * @param {number} limit
   * @param {PlaythroughFilter} playthroughFilter
   * @param {string} [authorization]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlaythroughApiInterface
   */
  getLevelTopScoreTableRaw(
    requestParameters: GetLevelTopScoreTableRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<ScoreTable>>>

  /**
   * Get Level Top Score Table
   */
  getLevelTopScoreTable(
    requestParameters: GetLevelTopScoreTableRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<ScoreTable>>

  /**
   *
   * @summary Get Count In Date Range Route
   * @param {Date} [fromDate]
   * @param {Date} [toDate]
   * @param {string} [authorization]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlaythroughApiInterface
   */
  getPlaythroughCountRaw(
    requestParameters: GetPlaythroughCountRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PlaythroughCounter>>

  /**
   * Get Count In Date Range Route
   */
  getPlaythroughCount(
    requestParameters: GetPlaythroughCountRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PlaythroughCounter>

  /**
   *
   * @summary Get Playtrough
   * @param {PlaythroughFilter} playthroughFilter
   * @param {string} [authorization]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlaythroughApiInterface
   */
  getPlaythroughsRaw(
    requestParameters: GetPlaythroughsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<Playthrough>>>

  /**
   * Get Playtrough
   */
  getPlaythroughs(
    requestParameters: GetPlaythroughsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<Playthrough>>

  /**
   *
   * @summary Get Stage Times Grouped By Level Route
   * @param {PlaythroughFilter} playthroughFilter
   * @param {string} [authorization]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlaythroughApiInterface
   */
  getProbabilityDensityByLevelRaw(
    requestParameters: GetProbabilityDensityByLevelRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<ProbabilityDensityData>>>

  /**
   * Get Stage Times Grouped By Level Route
   */
  getProbabilityDensityByLevel(
    requestParameters: GetProbabilityDensityByLevelRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<ProbabilityDensityData>>

  /**
   *
   * @summary Get Rehab Plan Statistic
   * @param {string} patientId
   * @param {string} rehabilitationPlanId
   * @param {string} [authorization]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlaythroughApiInterface
   */
  getRehabPlanStatisticsRaw(
    requestParameters: GetRehabPlanStatisticsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<RehabPlanStatistic>>

  /**
   * Get Rehab Plan Statistic
   */
  getRehabPlanStatistics(
    requestParameters: GetRehabPlanStatisticsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<RehabPlanStatistic>

  /**
   *
   * @summary Get Repeated Runs Chart
   * @param {string} levelId
   * @param {string} userId
   * @param {string} [authorization]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlaythroughApiInterface
   */
  getRepeatedRunsChartRaw(
    requestParameters: GetRepeatedRunsChartRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<RepeatedRunsChartData>>

  /**
   * Get Repeated Runs Chart
   */
  getRepeatedRunsChart(
    requestParameters: GetRepeatedRunsChartRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<RepeatedRunsChartData>

  /**
   *
   * @summary Get Stage Times Grouped By Level Route
   * @param {PlaythroughFilter} playthroughFilter
   * @param {number} [quantilesN]
   * @param {string} [authorization]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlaythroughApiInterface
   */
  getTimeLevelsBoxPlotRaw(
    requestParameters: GetTimeLevelsBoxPlotRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<BoxPlotData>>>

  /**
   * Get Stage Times Grouped By Level Route
   */
  getTimeLevelsBoxPlot(
    requestParameters: GetTimeLevelsBoxPlotRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<BoxPlotData>>
}

/**
 *
 */
export class PlaythroughApi extends runtime.BaseAPI implements PlaythroughApiInterface {
  /**
   * Get Deficiency
   */
  async deficiencyDetectorRaw(
    requestParameters: DeficiencyDetectorRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ZScoreAndDensityFunction>> {
    if (requestParameters['patientId'] == null) {
      throw new runtime.RequiredError(
        'patientId',
        'Required parameter "patientId" was null or undefined when calling deficiencyDetector().',
      )
    }

    if (requestParameters['rehabilitationPlanId'] == null) {
      throw new runtime.RequiredError(
        'rehabilitationPlanId',
        'Required parameter "rehabilitationPlanId" was null or undefined when calling deficiencyDetector().',
      )
    }

    const queryParameters: any = {}

    if (requestParameters['patientId'] != null) {
      queryParameters['patient_id'] = requestParameters['patientId']
    }

    if (requestParameters['rehabilitationPlanId'] != null) {
      queryParameters['rehabilitation_plan_id'] = requestParameters['rehabilitationPlanId']
    }

    if (requestParameters['fromAge'] != null) {
      queryParameters['from_age'] = requestParameters['fromAge']
    }

    if (requestParameters['toAge'] != null) {
      queryParameters['to_age'] = requestParameters['toAge']
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters['authorization'] != null) {
      headerParameters['authorization'] = String(requestParameters['authorization'])
    }

    const response = await this.request(
      {
        path: `/playthrough/deficiencyDetector`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) => ZScoreAndDensityFunctionFromJSON(jsonValue))
  }

  /**
   * Get Deficiency
   */
  async deficiencyDetector(
    requestParameters: DeficiencyDetectorRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ZScoreAndDensityFunction> {
    const response = await this.deficiencyDetectorRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Export
   */
  async exportPlaythroughsRaw(
    requestParameters: ExportPlaythroughsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Export>> {
    if (requestParameters['playthroughFilter'] == null) {
      throw new runtime.RequiredError(
        'playthroughFilter',
        'Required parameter "playthroughFilter" was null or undefined when calling exportPlaythroughs().',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters['authorization'] != null) {
      headerParameters['authorization'] = String(requestParameters['authorization'])
    }

    const response = await this.request(
      {
        path: `/playthrough/export`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PlaythroughFilterToJSON(requestParameters['playthroughFilter']),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) => ExportFromJSON(jsonValue))
  }

  /**
   * Export
   */
  async exportPlaythroughs(
    requestParameters: ExportPlaythroughsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Export> {
    const response = await this.exportPlaythroughsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Get Last Route
   */
  async getLastPlaythroughsRaw(
    requestParameters: GetLastPlaythroughsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<Playthrough>>> {
    const queryParameters: any = {}

    if (requestParameters['limit'] != null) {
      queryParameters['limit'] = requestParameters['limit']
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters['authorization'] != null) {
      headerParameters['authorization'] = String(requestParameters['authorization'])
    }

    const response = await this.request(
      {
        path: `/playthrough/last`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PlaythroughFromJSON))
  }

  /**
   * Get Last Route
   */
  async getLastPlaythroughs(
    requestParameters: GetLastPlaythroughsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<Playthrough>> {
    const response = await this.getLastPlaythroughsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Get Level Last Score Table
   */
  async getLevelLastScoreTableRaw(
    requestParameters: GetLevelLastScoreTableRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<ScoreTable>>> {
    if (requestParameters['limit'] == null) {
      throw new runtime.RequiredError(
        'limit',
        'Required parameter "limit" was null or undefined when calling getLevelLastScoreTable().',
      )
    }

    if (requestParameters['playthroughFilter'] == null) {
      throw new runtime.RequiredError(
        'playthroughFilter',
        'Required parameter "playthroughFilter" was null or undefined when calling getLevelLastScoreTable().',
      )
    }

    const queryParameters: any = {}

    if (requestParameters['limit'] != null) {
      queryParameters['limit'] = requestParameters['limit']
    }

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters['authorization'] != null) {
      headerParameters['authorization'] = String(requestParameters['authorization'])
    }

    const response = await this.request(
      {
        path: `/playthrough/levelLastScoreTable`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PlaythroughFilterToJSON(requestParameters['playthroughFilter']),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ScoreTableFromJSON))
  }

  /**
   * Get Level Last Score Table
   */
  async getLevelLastScoreTable(
    requestParameters: GetLevelLastScoreTableRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<ScoreTable>> {
    const response = await this.getLevelLastScoreTableRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Get Level Runs Chart
   */
  async getLevelRunsChartRaw(
    requestParameters: GetLevelRunsChartRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<LevelRunsChartData>> {
    if (requestParameters['levelId'] == null) {
      throw new runtime.RequiredError(
        'levelId',
        'Required parameter "levelId" was null or undefined when calling getLevelRunsChart().',
      )
    }

    if (requestParameters['userId'] == null) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter "userId" was null or undefined when calling getLevelRunsChart().',
      )
    }

    const queryParameters: any = {}

    if (requestParameters['levelId'] != null) {
      queryParameters['levelId'] = requestParameters['levelId']
    }

    if (requestParameters['userId'] != null) {
      queryParameters['userId'] = requestParameters['userId']
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters['authorization'] != null) {
      headerParameters['authorization'] = String(requestParameters['authorization'])
    }

    const response = await this.request(
      {
        path: `/playthrough/levelRunsChart`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) => LevelRunsChartDataFromJSON(jsonValue))
  }

  /**
   * Get Level Runs Chart
   */
  async getLevelRunsChart(
    requestParameters: GetLevelRunsChartRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<LevelRunsChartData> {
    const response = await this.getLevelRunsChartRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Get Level Score Chart
   */
  async getLevelScoreChartRaw(
    requestParameters: GetLevelScoreChartRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<ScoreChart>>> {
    if (requestParameters['playthroughFilter'] == null) {
      throw new runtime.RequiredError(
        'playthroughFilter',
        'Required parameter "playthroughFilter" was null or undefined when calling getLevelScoreChart().',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters['authorization'] != null) {
      headerParameters['authorization'] = String(requestParameters['authorization'])
    }

    const response = await this.request(
      {
        path: `/playthrough/levelScoreChart`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PlaythroughFilterToJSON(requestParameters['playthroughFilter']),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ScoreChartFromJSON))
  }

  /**
   * Get Level Score Chart
   */
  async getLevelScoreChart(
    requestParameters: GetLevelScoreChartRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<ScoreChart>> {
    const response = await this.getLevelScoreChartRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Get Level Top Score Table
   */
  async getLevelTopScoreTableRaw(
    requestParameters: GetLevelTopScoreTableRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<ScoreTable>>> {
    if (requestParameters['limit'] == null) {
      throw new runtime.RequiredError(
        'limit',
        'Required parameter "limit" was null or undefined when calling getLevelTopScoreTable().',
      )
    }

    if (requestParameters['playthroughFilter'] == null) {
      throw new runtime.RequiredError(
        'playthroughFilter',
        'Required parameter "playthroughFilter" was null or undefined when calling getLevelTopScoreTable().',
      )
    }

    const queryParameters: any = {}

    if (requestParameters['limit'] != null) {
      queryParameters['limit'] = requestParameters['limit']
    }

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters['authorization'] != null) {
      headerParameters['authorization'] = String(requestParameters['authorization'])
    }

    const response = await this.request(
      {
        path: `/playthrough/levelTopScoreTable`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PlaythroughFilterToJSON(requestParameters['playthroughFilter']),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ScoreTableFromJSON))
  }

  /**
   * Get Level Top Score Table
   */
  async getLevelTopScoreTable(
    requestParameters: GetLevelTopScoreTableRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<ScoreTable>> {
    const response = await this.getLevelTopScoreTableRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Get Count In Date Range Route
   */
  async getPlaythroughCountRaw(
    requestParameters: GetPlaythroughCountRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PlaythroughCounter>> {
    const queryParameters: any = {}

    if (requestParameters['fromDate'] != null) {
      queryParameters['fromDate'] = (requestParameters['fromDate'] as any).toISOString()
    }

    if (requestParameters['toDate'] != null) {
      queryParameters['toDate'] = (requestParameters['toDate'] as any).toISOString()
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters['authorization'] != null) {
      headerParameters['authorization'] = String(requestParameters['authorization'])
    }

    const response = await this.request(
      {
        path: `/playthrough/count`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) => PlaythroughCounterFromJSON(jsonValue))
  }

  /**
   * Get Count In Date Range Route
   */
  async getPlaythroughCount(
    requestParameters: GetPlaythroughCountRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PlaythroughCounter> {
    const response = await this.getPlaythroughCountRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Get Playtrough
   */
  async getPlaythroughsRaw(
    requestParameters: GetPlaythroughsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<Playthrough>>> {
    if (requestParameters['playthroughFilter'] == null) {
      throw new runtime.RequiredError(
        'playthroughFilter',
        'Required parameter "playthroughFilter" was null or undefined when calling getPlaythroughs().',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters['authorization'] != null) {
      headerParameters['authorization'] = String(requestParameters['authorization'])
    }

    const response = await this.request(
      {
        path: `/playthrough/filtered`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PlaythroughFilterToJSON(requestParameters['playthroughFilter']),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PlaythroughFromJSON))
  }

  /**
   * Get Playtrough
   */
  async getPlaythroughs(
    requestParameters: GetPlaythroughsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<Playthrough>> {
    const response = await this.getPlaythroughsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Get Stage Times Grouped By Level Route
   */
  async getProbabilityDensityByLevelRaw(
    requestParameters: GetProbabilityDensityByLevelRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<ProbabilityDensityData>>> {
    if (requestParameters['playthroughFilter'] == null) {
      throw new runtime.RequiredError(
        'playthroughFilter',
        'Required parameter "playthroughFilter" was null or undefined when calling getProbabilityDensityByLevel().',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters['authorization'] != null) {
      headerParameters['authorization'] = String(requestParameters['authorization'])
    }

    const response = await this.request(
      {
        path: `/playthrough/probabilityDensityByLevel`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PlaythroughFilterToJSON(requestParameters['playthroughFilter']),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProbabilityDensityDataFromJSON))
  }

  /**
   * Get Stage Times Grouped By Level Route
   */
  async getProbabilityDensityByLevel(
    requestParameters: GetProbabilityDensityByLevelRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<ProbabilityDensityData>> {
    const response = await this.getProbabilityDensityByLevelRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Get Rehab Plan Statistic
   */
  async getRehabPlanStatisticsRaw(
    requestParameters: GetRehabPlanStatisticsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<RehabPlanStatistic>> {
    if (requestParameters['patientId'] == null) {
      throw new runtime.RequiredError(
        'patientId',
        'Required parameter "patientId" was null or undefined when calling getRehabPlanStatistics().',
      )
    }

    if (requestParameters['rehabilitationPlanId'] == null) {
      throw new runtime.RequiredError(
        'rehabilitationPlanId',
        'Required parameter "rehabilitationPlanId" was null or undefined when calling getRehabPlanStatistics().',
      )
    }

    const queryParameters: any = {}

    if (requestParameters['patientId'] != null) {
      queryParameters['patient_id'] = requestParameters['patientId']
    }

    if (requestParameters['rehabilitationPlanId'] != null) {
      queryParameters['rehabilitation_plan_id'] = requestParameters['rehabilitationPlanId']
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters['authorization'] != null) {
      headerParameters['authorization'] = String(requestParameters['authorization'])
    }

    const response = await this.request(
      {
        path: `/playthrough/rehabPlanStatistic`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) => RehabPlanStatisticFromJSON(jsonValue))
  }

  /**
   * Get Rehab Plan Statistic
   */
  async getRehabPlanStatistics(
    requestParameters: GetRehabPlanStatisticsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<RehabPlanStatistic> {
    const response = await this.getRehabPlanStatisticsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Get Repeated Runs Chart
   */
  async getRepeatedRunsChartRaw(
    requestParameters: GetRepeatedRunsChartRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<RepeatedRunsChartData>> {
    if (requestParameters['levelId'] == null) {
      throw new runtime.RequiredError(
        'levelId',
        'Required parameter "levelId" was null or undefined when calling getRepeatedRunsChart().',
      )
    }

    if (requestParameters['userId'] == null) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter "userId" was null or undefined when calling getRepeatedRunsChart().',
      )
    }

    const queryParameters: any = {}

    if (requestParameters['levelId'] != null) {
      queryParameters['levelId'] = requestParameters['levelId']
    }

    if (requestParameters['userId'] != null) {
      queryParameters['userId'] = requestParameters['userId']
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters['authorization'] != null) {
      headerParameters['authorization'] = String(requestParameters['authorization'])
    }

    const response = await this.request(
      {
        path: `/playthrough/repeatedRunsChart`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) => RepeatedRunsChartDataFromJSON(jsonValue))
  }

  /**
   * Get Repeated Runs Chart
   */
  async getRepeatedRunsChart(
    requestParameters: GetRepeatedRunsChartRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<RepeatedRunsChartData> {
    const response = await this.getRepeatedRunsChartRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Get Stage Times Grouped By Level Route
   */
  async getTimeLevelsBoxPlotRaw(
    requestParameters: GetTimeLevelsBoxPlotRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<BoxPlotData>>> {
    if (requestParameters['playthroughFilter'] == null) {
      throw new runtime.RequiredError(
        'playthroughFilter',
        'Required parameter "playthroughFilter" was null or undefined when calling getTimeLevelsBoxPlot().',
      )
    }

    const queryParameters: any = {}

    if (requestParameters['quantilesN'] != null) {
      queryParameters['quantiles_n'] = requestParameters['quantilesN']
    }

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters['authorization'] != null) {
      headerParameters['authorization'] = String(requestParameters['authorization'])
    }

    const response = await this.request(
      {
        path: `/playthrough/timeLevelsBoxPlot`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PlaythroughFilterToJSON(requestParameters['playthroughFilter']),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BoxPlotDataFromJSON))
  }

  /**
   * Get Stage Times Grouped By Level Route
   */
  async getTimeLevelsBoxPlot(
    requestParameters: GetTimeLevelsBoxPlotRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<BoxPlotData>> {
    const response = await this.getTimeLevelsBoxPlotRaw(requestParameters, initOverrides)
    return await response.value()
  }
}
