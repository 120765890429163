/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { Density } from './Density'
import { DensityFromJSON, DensityFromJSONTyped, DensityToJSON } from './Density'
import type { Domain } from './Domain'
import { DomainFromJSON, DomainFromJSONTyped, DomainToJSON } from './Domain'

/**
 *
 * @export
 * @interface DensityFunction
 */
export interface DensityFunction {
  /**
   *
   * @type {string}
   * @memberof DensityFunction
   */
  level: string
  /**
   *
   * @type {Array<Domain>}
   * @memberof DensityFunction
   */
  domains: Array<Domain>
  /**
   *
   * @type {Density}
   * @memberof DensityFunction
   */
  density: Density
}

/**
 * Check if a given object implements the DensityFunction interface.
 */
export function instanceOfDensityFunction(value: object): boolean {
  if (!('level' in value)) return false
  if (!('domains' in value)) return false
  if (!('density' in value)) return false
  return true
}

export function DensityFunctionFromJSON(json: any): DensityFunction {
  return DensityFunctionFromJSONTyped(json, false)
}

export function DensityFunctionFromJSONTyped(json: any, ignoreDiscriminator: boolean): DensityFunction {
  if (json == null) {
    return json
  }
  return {
    level: json['level'],
    domains: (json['domains'] as Array<any>).map(DomainFromJSON),
    density: DensityFromJSON(json['density']),
  }
}

export function DensityFunctionToJSON(value?: DensityFunction | null): any {
  if (value == null) {
    return value
  }
  return {
    level: value['level'],
    domains: (value['domains'] as Array<any>).map(DomainToJSON),
    density: DensityToJSON(value['density']),
  }
}
