import React, { FC, useContext, useEffect, useState } from 'react'
import { Form, Input, PasswordInput, Submit } from '@proxyqb/ui-forms'
import { Alert as MuiAlert, AlertProps, Box, Button } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { EMAIL_REGEX } from '../../ui/src/shared'
import { errorMapper, Severity } from '../../ui/src/utils/errorMapper'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
import { IsUserDeletedDocument } from './login.generated'
import { Context } from 'urql'

type LoginFields = {
  email: string
  password: string
}

interface Props {
  onSubmit(cred: LoginFields)
  authState: any // TODO: types?
  showDoctorRegistration: boolean
  isContinueUrlRequired?: boolean
}

enum FormState {
  login = 'LOGIN',
  resetPassword = 'RESET_PASSWORD',
}

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

export const LoginForm: FC<Props> = ({
  onSubmit,
  authState,
  showDoctorRegistration,
  isContinueUrlRequired = false,
}) => {
  const loginForm = useForm<LoginFields>({ defaultValues: { email: '', password: '' } })
  const client = useContext(Context)
  const [isLoading, setIsLoading] = useState(false)

  const intl = useIntl()
  const [formState, changeFormState] = useState<FormState>(FormState.login)
  const [alertSeverity, changeSeverity] = useState<Severity | null>(null)

  function evokeResetPassword() {
    changeSeverity(null)
    changeFormState(FormState.resetPassword)
  }

  const checkIfUserIsDeletedAndLogin = async (email: string, password: string): Promise<void> => {
    setIsLoading(true)
    const userDeletedCheck = await client.query(IsUserDeletedDocument, { email }).toPromise()
    if (userDeletedCheck.data.isUserDeleted) {
      changeSeverity(Severity.invalidCredentials)
      setIsLoading(false)
    } else {
      onSubmit({ email, password })
    }
  }

  const errorMessage = authState.context?.error?.code

  useEffect(() => {
    if (errorMessage !== undefined) {
      const severity = errorMapper[errorMessage] ?? Severity.default
      changeSeverity(severity)
      setIsLoading(false)
    }
  }, [errorMessage, isLoading])

  switch (formState) {
    case FormState.login:
      return (
        <Form
          form={loginForm}
          name="loginForm"
          onSubmit={({ email, password }) => {
            checkIfUserIsDeletedAndLogin(email.trim(), password)
          }}
        >
          {alertSeverity !== null &&
            alertSeverity !== Severity.invalidVerificationCode &&
            (alertSeverity === Severity.success ? (
              <Alert severity={Severity.success}>
                {intl.formatMessage({ id: `loginForm.emailWasSentMessage` })}
              </Alert>
            ) : (
              <Alert severity="error">{intl.formatMessage({ id: `loginForm.${alertSeverity}` })}</Alert>
            ))}
          <Input name="email" rules={{ required: true, pattern: EMAIL_REGEX }} />
          <PasswordInput />
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Submit buttonProps={{ loading: isLoading }} />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', fontSize: 30 }}>
            {showDoctorRegistration && (
              <Button sx={{ textTransform: 'none' }} color="secondary" component={Link} to="/registration">
                {intl.formatMessage({ id: 'loginForm.signUp' })}
              </Button>
            )}
            {showDoctorRegistration && String.fromCharCode(183)}
            <Button sx={{ textTransform: 'none' }} color="secondary" onClick={evokeResetPassword}>
              {intl.formatMessage({ id: 'loginForm.forgotPassword' })}
            </Button>
          </Box>
        </Form>
      )

    case FormState.resetPassword:
      return (
        <ResetPasswordForm
          changeFormState={changeFormState}
          changeSeverity={changeSeverity}
          alertSeverity={alertSeverity}
          isContinueUrlRequired={isContinueUrlRequired}
        />
      )

    default:
      return <Alert severity="error">{intl.formatMessage({ id: 'form.loadingErrorMessage' })}</Alert>
  }
}

type ResetPasswordFields = {
  email: string
}

const ResetPasswordForm = ({ changeFormState, changeSeverity, alertSeverity, isContinueUrlRequired }) => {
  const resetPasswordForm = useForm<ResetPasswordFields>({ defaultValues: { email: '' } })
  const intl = useIntl()

  function onResetFormSuccess() {
    changeFormState(FormState.login)
    changeSeverity(Severity.success)
  }

  function evokeLogin() {
    changeSeverity(null)
    changeFormState(FormState.login)
  }

  function onError(err) {
    const severity = errorMapper[err] ?? Severity.default
    changeSeverity(severity)
  }

  return (
    <Form
      form={resetPasswordForm}
      name="resetPasswordForm"
      onSubmit={({ email }) => {
        if (isContinueUrlRequired) {
          sendPasswordResetEmail(getAuth(), email, {
            url: window.location.origin,
          })
            .then(onResetFormSuccess)
            .catch((err) => onError(err.code))
        } else {
          sendPasswordResetEmail(getAuth(), email)
            .then(onResetFormSuccess)
            .catch((err) => onError(err.code))
        }
      }}
    >
      {alertSeverity !== null && (
        <Alert severity="error">{intl.formatMessage({ id: `resetPasswordForm.${alertSeverity}` })}</Alert>
      )}
      <Input name="email" rules={{ required: true, pattern: EMAIL_REGEX }} />
      <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <Submit />

        <Button sx={{ mt: 5 }} onClick={evokeLogin} color="secondary">
          {intl.formatMessage({ id: 'shared.back' })}
        </Button>
      </Box>
    </Form>
  )
}
