import { Box, SxProps } from '@mui/material'
import { MouseEventHandler } from 'react'

interface Props {
  url: string
  color?: string
  sx?: SxProps
  width?: string
  height?: string
  onClick?: MouseEventHandler<HTMLDivElement>
  titleAccess?: string
}

export const MaskIcon = ({
  url,
  color,
  onClick,
  width = '20px',
  height = '20px',
  sx = {},
  titleAccess,
}: Props) => {
  //TODO title
  return (
    <Box
      component={'div'}
      title={titleAccess}
      onClick={onClick && onClick}
      sx={[
        {
          width,
          height,
          maskImage: `url(${url})`,
          // background image has to be set, otherwise Chrome will fetch the image with wrong headers and the request will crash on CORS policy
          backgroundImage: `url(${url})`,
          backgroundSize: 0,
          backgroundColor: color || 'primary.main',
          maskSize: 'cover',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    />
  )
}
