import * as Types from '@proxyqb/graphql-api-types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export const RegisterPublicDoctorDocument = gql`
    mutation registerPublicDoctor($doctor: RegisterDoctor!) {
  registerPublicDoctor(input: $doctor)
}
    `;

export function useRegisterPublicDoctorMutation() {
  return Urql.useMutation<RegisterPublicDoctorMutation, RegisterPublicDoctorMutationVariables>(RegisterPublicDoctorDocument);
};
export type RegisterPublicDoctorMutationVariables = Types.Exact<{
  doctor: Types.RegisterDoctor;
}>;


export type RegisterPublicDoctorMutation = { __typename: 'Mutation', registerPublicDoctor: string };
