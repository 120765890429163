import { createContext, useState } from 'react'

const languageOptions = {
  en: 'en',
  cs: 'cs',
  de: 'de',
  pl: 'pl',
  is: 'is',
  nl: 'nl',
}

export const getUserLanguage = () =>
  window.localStorage.getItem('rcml-lang') || navigator.language.split('-')[0] || 'cs'

export const LanguageContext = createContext({
  userLanguage: getUserLanguage(),
  userLanguageChange: (selected: string) => {},
})

export function LanguageProvider({ children }) {
  const [userLanguage, setUserLanguage] = useState(getUserLanguage())

  const provider = {
    userLanguage,
    userLanguageChange: (selected) => {
      const newLanguage = languageOptions[selected] ? selected : 'cs'
      setUserLanguage(newLanguage)
      window.localStorage.setItem('rcml-lang', newLanguage)
    },
  }

  return <LanguageContext.Provider value={provider}>{children}</LanguageContext.Provider>
}
