import * as Types from '@proxyqb/graphql-api-types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export const GetNameDocument = gql`
    query getName($ids: [ID!]!) {
  names(ids: $ids) {
    id
    name
  }
}
    `;

export function useGetNameQuery(options: Omit<Urql.UseQueryArgs<GetNameQueryVariables>, 'query'>) {
  return Urql.useQuery<GetNameQuery, GetNameQueryVariables>({ query: GetNameDocument, ...options });
};
export type GetNameQueryVariables = Types.Exact<{
  ids: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
}>;


export type GetNameQuery = { __typename: 'Query', names: Array<{ __typename: 'PublicRecord', id: string, name: string }> };
