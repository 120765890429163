import { CSSProperties, FC, PropsWithChildren } from 'react'
import styled from 'styled-components'
import { Flex, FlexProps } from './Flex'

/**  basically css properties */
const baseGridProps = [
  'background',
  'padding',
  'margin',
  'width',
  'maxWidth',
  'minWidth',
  'height',
  'maxHeight',
  'minHeight',
  'justifyContent',
  'alignItems',
  'width',
  'height',
  'gridGap',
] as const

type ElementType<T extends ReadonlyArray<unknown>> = T extends ReadonlyArray<infer ElementType>
  ? ElementType
  : never

type GridValues = ElementType<typeof baseGridProps> // this is correctly inferred as literal "A" | "B"

export type BaseGridProps = Pick<CSSProperties, GridValues>

export interface GridProps extends BaseGridProps {
  templateColumns?: string
  templateRows?: string
  gridGap?: string
  columnGap?: string
  rowGap?: string
  autoFlow?: 'column' | 'row'
  templateAreas?: string[]
}

type GridComponentProps = FC<PropsWithChildren<GridProps>> & { Item: FC<GridItemProps> }

// @ts-ignore
const GridComponent: GridComponentProps = styled.div.attrs((props: PropsWithChildren<GridProps>) => {
  return { style: baseGridProps.reduce((acc, prop) => ({ ...acc, [prop]: props[prop] }), {}) }
})<GridProps>`
  display: grid;
  grid-template-columns: ${({ templateColumns }) => templateColumns};
  grid-template-rows: ${({ templateRows }) => templateRows};
  grid-auto-flow: ${({ autoFlow }) => autoFlow};
  grid-column-gap: ${({ columnGap }) => columnGap};
  grid-row-gap: ${({ rowGap }) => rowGap};
  grid-template-areas: ${({ templateAreas }) =>
    '"' + (templateAreas ? templateAreas.join('"\n"') : '') + '"'};
`

export interface GridItemProps extends FlexProps {
  area?: string
}
const Item: FC<GridItemProps> = styled(Flex)<PropsWithChildren<GridItemProps>>`
  grid-area: ${({ area }) => area};
`

export const Grid = Object.assign(GridComponent, { Item })
