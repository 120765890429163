import flagsmith from 'flagsmith'
import type { IFlagsmithFeature } from 'flagsmith/types'
import { useState } from 'react'
import { createGlobalState, useEffectOnce } from 'react-use'
import { usePageVisibility } from './use-page-visibility'

type FlagKeys =
  | 'voice_success_stage'
  | 'rendering_cubes'
  | 'analytics'
  | 'doctor_groups'
  | 'patient_groups'
  | 'smiley_face_on_level_finished'
  | 'disable_level_edit'
  | 'cube_goal_animations'
  | 'show_winning_vectors'
  | 'led_color_cubes'
  | 'remove_background'
  | 'ble_notifications_ms_interval'
  | 'sync_with_tablet'
  | 'deficiency_detector_rehab_plan_id'

export type FeatureFlags = Record<FlagKeys, IFlagsmithFeature | undefined>

const useGlobalState = createGlobalState<FeatureFlags>()

type UseInitFlagsmithResult = {
  isLoadingFlags: boolean
}

export const useInitFlagsmith = (environmentID: string): UseInitFlagsmithResult => {
  const [isLoadingFlags, setIsLoadingFlags] = useState(true)
  const [, setState] = useGlobalState()
  useEffectOnce(() => {
    flagsmith.init({
      environmentID,
      onChange: (oldFlags, params) => {
        const flags = flagsmith.getAllFlags()
        setState(flags as FeatureFlags)
        setIsLoadingFlags(false)
      },
      onError: (error) => {
        console.error('Could not load flags', error)
        setIsLoadingFlags(false)
        setState({})
      },
    })
  })
  usePageVisibility({
    onVisible: () => {
      flagsmith.getFlags()
    },
    throttle: 3_600_000, // 1 hour
  })
  return {
    isLoadingFlags,
  }
}

type UseFlagsmithResult = {
  flags: FeatureFlags
}

export const useFlagsmith = (): UseFlagsmithResult => {
  const [flags] = useGlobalState()
  return {
    flags,
  }
}
