import { useEffect, useState } from 'react'
import { InputAdornment, Theme, Typography, useTheme } from '@mui/material'

interface Props {
  maxLimit: number
  value: string
}

export const InputCharacterCounter = ({ maxLimit, value }: Props) => {
  const [counter, setCounter] = useState<string>(`0/${maxLimit}`)
  const theme: Theme = useTheme()

  useEffect(() => {
    setCounter(`${value.length}/${maxLimit}`)
  }, [value])

  return (
    <InputAdornment position={'end'}>
      <Typography color={value.length > maxLimit ? theme.palette.error.main : theme.palette.text.primary}>
        {counter}
      </Typography>
    </InputAdornment>
  )
}
