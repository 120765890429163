import { FC } from 'react'
import styled from 'styled-components'

const Container = styled.div<{ scale?: number }>`
  position: relative;
  height: 100px;
  width: 86px;
  transform: scale(${({ scale }) => 0.5 * (scale ?? 1)});
`
const Cube = styled.div<{ h: number; w: number; l: number }>`
  position: absolute;
  width: 86px;
  height: 100px;
  z-index: ${({ h }) => -h};
  animation-name: ${({ h, w, l }) => `h${h}w${w}l${l}`};
  animation-timing-function: ease;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  ${({ h, w, l }) => `
      @keyframes h${h}w${w}l${l} {
      0% {
        transform: translate(${w * -50 - 50 + (l * 50 + 50)}%,${
    h * 50 - 200 + (w * 25 - 25) + (l * 25 + 25)
  }%);
      }
      14% {
        transform: translate(${w * -50 - 50 + (l * 100 - 50)}%,${
    h * 50 - 200 + (w * 25 - 25) + (l * 50 - 25)
  }%);
      }
      28% {
        transform: translate(${w * -100 + 50 + (l * 100 - 50)}%,${
    h * 50 - 200 + (w * 50 - 75) + (l * 50 - 25)
  }%);
      }
      43% {
        transform: translate(${w * -100 - 100 + (l * 100 + 100)}%,${
    h * 100 - 400 + (w * 50 - 50) + (l * 50 + 50)
  }%);
      }
      57% {
        transform: translate(${w * -100 - 100 + (l * 50 + 200)}%,${
    h * 100 - 400 + (w * 50 - 50) + (l * 25 + 100)
  }%);
      }
      71% {
        transform: translate(${w * -50 - 200 + (l * 50 + 200)}%,${
    h * 100 - 375 + (w * 25 - 25) + (l * 25 + 100)
  }%);
      }
      85% {
        transform: translate(${w * -50 - 50 + (l * 50 + 50)}%,${
    h * 50 - 200 + (w * 25 - 25) + (l * 25 + 25)
  }%);
      }
      100% {
        transform: translate(${w * -50 - 50 + (l * 50 + 50)}%,${
    h * 50 - 200 + (w * 25 - 25) + (l * 25 + 25)
  }%);
      }
    }
  `}
`
const Face = styled.div<{ type: 'right' | 'left' | 'top' }>`
  height: 50px;
  width: 50px;
  position: absolute;
  transform-origin: 0 0;
  ${({ type }) => {
    if (type === 'right') {
      return `
      background:#E79C10;
  transform: rotate(-30deg) skewX(-30deg) translate(49px, 65px) scaleY(0.86);`
    }
    if (type === 'left') {
      return `
        background:#D53A33;
  transform: rotate(90deg) skewX(-30deg) scaleY(0.86) translate(25px, -50px);`
    }
    if (type === 'top') {
      return `
      background:#1d9099;
  transform: rotate(210deg) skew(-30deg) translate(-75px, -22px) scaleY(0.86);
  z-index:2;`
    }
  }}
`

interface Props {
  scale?: number
}

export const Loading: FC<Props> = ({ scale }) => {
  return (
    <Container scale={scale}>
      <div>
        <Cube h={1} w={1} l={1}>
          <Face type="top" />
          <Face type="left" />
          <Face type="right" />
        </Cube>

        <Cube h={1} w={1} l={2}>
          <Face type="top" />
          <Face type="left" />
          <Face type="right" />
        </Cube>

        <Cube h={1} w={1} l={3}>
          <Face type="top" />
          <Face type="left" />
          <Face type="right" />
        </Cube>

        <Cube h={1} w={2} l={1}>
          <Face type="top" />
          <Face type="left" />
          <Face type="right" />
        </Cube>

        <Cube h={1} w={2} l={2}>
          <Face type="top" />
          <Face type="left" />
          <Face type="right" />
        </Cube>

        <Cube h={1} w={2} l={3}>
          <Face type="top" />
          <Face type="left" />
          <Face type="right" />
        </Cube>

        <Cube h={1} w={3} l={1}>
          <Face type="top" />
          <Face type="left" />
          <Face type="right" />
        </Cube>

        <Cube h={1} w={3} l={2}>
          <Face type="top" />
          <Face type="left" />
          <Face type="right" />
        </Cube>

        <Cube h={1} w={3} l={3}>
          <Face type="top" />
          <Face type="left" />
          <Face type="right" />
        </Cube>
      </div>

      <div>
        <Cube h={2} w={1} l={1}>
          <Face type="top" />
          <Face type="left" />
          <Face type="right" />
        </Cube>

        <Cube h={2} w={1} l={2}>
          <Face type="top" />
          <Face type="left" />
          <Face type="right" />
        </Cube>

        <Cube h={2} w={1} l={3}>
          <Face type="top" />
          <Face type="left" />
          <Face type="right" />
        </Cube>

        <Cube h={2} w={2} l={1}>
          <Face type="top" />
          <Face type="left" />
          <Face type="right" />
        </Cube>

        <Cube h={2} w={2} l={2}>
          <Face type="top" />
          <Face type="left" />
          <Face type="right" />
        </Cube>

        <Cube h={2} w={2} l={3}>
          <Face type="top" />
          <Face type="left" />
          <Face type="right" />
        </Cube>

        <Cube h={2} w={3} l={1}>
          <Face type="top" />
          <Face type="left" />
          <Face type="right" />
        </Cube>

        <Cube h={2} w={3} l={2}>
          <Face type="top" />
          <Face type="left" />
          <Face type="right" />
        </Cube>

        <Cube h={2} w={3} l={3}>
          <Face type="top" />
          <Face type="left" />
          <Face type="right" />
        </Cube>
      </div>

      <div>
        <Cube h={3} w={1} l={1}>
          <Face type="top" />
          <Face type="left" />
          <Face type="right" />
        </Cube>

        <Cube h={3} w={1} l={2}>
          <Face type="top" />
          <Face type="left" />
          <Face type="right" />
        </Cube>

        <Cube h={3} w={1} l={3}>
          <Face type="top" />
          <Face type="left" />
          <Face type="right" />
        </Cube>

        <Cube h={3} w={2} l={1}>
          <Face type="top" />
          <Face type="left" />
          <Face type="right" />
        </Cube>

        <Cube h={3} w={2} l={2}>
          <Face type="top" />
          <Face type="left" />
          <Face type="right" />
        </Cube>

        <Cube h={3} w={2} l={3}>
          <Face type="top" />
          <Face type="left" />
          <Face type="right" />
        </Cube>

        <Cube h={3} w={3} l={1}>
          <Face type="top" />
          <Face type="left" />
          <Face type="right" />
        </Cube>

        <Cube h={3} w={3} l={2}>
          <Face type="top" />
          <Face type="left" />
          <Face type="right" />
        </Cube>

        <Cube h={3} w={3} l={3}>
          <Face type="top" />
          <Face type="left" />
          <Face type="right" />
        </Cube>
      </div>
    </Container>
  )
}
