import { useGlobalState } from '@proxyqb/portal-global-state'
import { LoadingTemplate } from '@proxyqb/ui'
import { useSelector } from '@xstate/react'
import { ReactElement } from 'react'
import { Navigate } from 'react-router'

export const MyProfile = (): ReactElement => {
  const { authService, authState } = useGlobalState()
  const checkingIfLoggedIn = useSelector(authService, (state) => {
    return state.matches('checkingIfLoggedIn')
  })
  const { userDetails } = authState.context
  console.log({ userDetails })

  if (checkingIfLoggedIn) {
    return <LoadingTemplate fullScreen />
  }
  if (userDetails?.roles.includes('doctor')) {
    return <Navigate to={`/doctors/${userDetails!.id}`} />
  }

  if (userDetails?.roles.includes('admin')) {
    return <Navigate to={`/admins/${userDetails!.id}`} />
  }
  return <>Nothing for you here yet!</>
}
