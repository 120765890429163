/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { WeakDomain } from './WeakDomain'
import { WeakDomainFromJSON, WeakDomainFromJSONTyped, WeakDomainToJSON } from './WeakDomain'
import type { ZScoreResult } from './ZScoreResult'
import { ZScoreResultFromJSON, ZScoreResultFromJSONTyped, ZScoreResultToJSON } from './ZScoreResult'

/**
 *
 * @export
 * @interface ZScore
 */
export interface ZScore {
  /**
   *
   * @type {Array<ZScoreResult>}
   * @memberof ZScore
   */
  zScore: Array<ZScoreResult>
  /**
   *
   * @type {Array<WeakDomain>}
   * @memberof ZScore
   */
  weakDomains: Array<WeakDomain>
}

/**
 * Check if a given object implements the ZScore interface.
 */
export function instanceOfZScore(value: object): boolean {
  if (!('zScore' in value)) return false
  if (!('weakDomains' in value)) return false
  return true
}

export function ZScoreFromJSON(json: any): ZScore {
  return ZScoreFromJSONTyped(json, false)
}

export function ZScoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): ZScore {
  if (json == null) {
    return json
  }
  return {
    zScore: (json['zScore'] as Array<any>).map(ZScoreResultFromJSON),
    weakDomains: (json['weakDomains'] as Array<any>).map(WeakDomainFromJSON),
  }
}

export function ZScoreToJSON(value?: ZScore | null): any {
  if (value == null) {
    return value
  }
  return {
    zScore: (value['zScore'] as Array<any>).map(ZScoreResultToJSON),
    weakDomains: (value['weakDomains'] as Array<any>).map(WeakDomainToJSON),
  }
}
