declare module '@mui/material/styles' {
  interface Theme {
    mainScreenSpacing: {
      px: number
      pxSmall: number
      py: number
    }
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    mainScreenSpacing?: {
      px?: number
      py?: number
    }
  }
}

export const theme = {
  mainScreenSpacing: {
    px: 10,
    pxSmall: 1.2,
    py: 21,
  },
  palette: {
    primary: {
      light: '#daecf3',
      main: '#009EE2',
      dark: '#007DB3',
    },
    text: {
      primary: '#3B5057',
    },
    background: {
      default: '#efefef',
      paper: 'white',
    },
  },
  shadows: [
    '0px 3px 30px #00000029',
    ...Array.from(Array(30)).fill('0px 0px 30px #3B505726'), // Some fucking error "Please make sure that `theme.shadows[24]` is defined." Who the fuck is using shadows[24]?
  ],
  typography: {
    fontFamily: '"Red Hat Display", "Roboto", "Helvetica", "Arial", sans-serif',
    body: {
      fontFamily: '"Red Hat Display", "Roboto", "Helvetica", "Arial", sans-serif',
    },
    body1: {
      fontFamily: '"Red Hat Display", "Roboto", "Helvetica", "Arial", sans-serif',
    },
    body2: {
      fontFamily: '"Red Hat Display", "Roboto", "Helvetica", "Arial", sans-serif',
    },
    button: {
      fontFamily: '"Red Hat Display", "Roboto", "Helvetica", "Arial", sans-serif',
    },
    h1: {
      fontFamily: '"Red Hat Display", "Roboto", "Helvetica", "Arial", sans-serif',
    },
    h2: {
      fontFamily: '"Red Hat Display", "Roboto", "Helvetica", "Arial", sans-serif',
    },
    h3: {
      fontFamily: '"Red Hat Display", "Roboto", "Helvetica", "Arial", sans-serif',
    },
    h4: {
      fontFamily: '"Red Hat Display", "Roboto", "Helvetica", "Arial", sans-serif',
    },
    h5: {
      fontFamily: '"Red Hat Display", "Roboto", "Helvetica", "Arial", sans-serif',
    },
    h6: {
      fontFamily: '"Red Hat Display", "Roboto", "Helvetica", "Arial", sans-serif',
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        fontFamily: '"Red Hat Display", "Roboto", "Helvetica", "Arial", sans-serif',
      },
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {
          padding: 0,
          textTransform: 'none',
          height: '100%',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: 0,
          paddingBottom: '8px',
          textTransform: 'none',
          minWidth: 'initial',
          justifyContent: 'flex-end',
          '&:not(:first-of-type)': {
            marginLeft: '16px',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          '& .MuiDataGrid-cellCheckbox.MuiDataGrid-cell:first-of-type, & .MuiDataGrid-columnHeaderCheckbox.MuiDataGrid-columnHeader:first-of-type':
            {
              minWidth: '130px !important',
              maxWidth: '130px !important',
              // width: '130px',
            },
          '& .MuiDataGrid-cell:first-of-type': {
            paddingLeft: '80px',
          },
          '& .MuiDataGrid-cell:last-of-type': {
            paddingRight: '80px',
          },
          '& .MuiDataGrid-columnHeader:first-of-type': {
            paddingLeft: '80px',
          },
          '& .MuiDataGrid-columnHeader:last-of-type': {
            paddingRight: '80px',
          },
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '32px',
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          justifyContent: 'flex-end',
          padding: '32px',
          paddingTop: 0,
        },
      },
    },
  },
}
