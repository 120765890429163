import { FormControlLabel, Grid, Paper, Switch } from '@mui/material'
import { useGlobalState } from '@proxyqb/portal-global-state'
import { Flex } from '@proxyqb/ui'
import { PublicTemplate } from '../../../shared'
import img from './login-fe-img.png'
import { ChangeEvent, useState } from 'react'
import { useIntl } from 'react-intl'
import { PhoneLogin } from '@proxyqb/phone-login'
import { LoginForm } from '@proxyqb/login'

export const LoginScreen = () => {
  const { authService, authState } = useGlobalState()
  const [phoneLogin, setPhoneLogin] = useState<boolean>(false)
  const { formatMessage } = useIntl()

  const handleSwitchChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setPhoneLogin(event.target.checked)
  }

  function onSubmit({ email, password }: { email: string; password: string }) {
    authService.send({
      type: 'LOG_IN',
      credentials: {
        email,
        password,
      },
    })
  }

  return (
    <PublicTemplate>
      <Flex
        position="absolute"
        alignItems="center"
        justifyContent="center"
        height="80%"
        width="calc(100% - 16px)"
      >
        <Flex as={Paper} elevation={1}>
          <Grid container columns={{ xs: 1, md: 2 }}>
            <Grid item xs={1} md={1}>
              <Flex
                background="#fff"
                borderRadius="8px 0 0 8px"
                alignItems="center"
                justifyContent="center"
                width={'100%'}
                height={'100%'}
              >
                <img src={img} width="300px" />
              </Flex>
            </Grid>
            <Grid item xs={1} md={1}>
              <Flex
                width="400px"
                height="100%"
                minHeight="360px"
                padding="32px"
                flexDirection="column"
                alignItems="center"
                gap={10}
                background="#e7e7e7"
                borderRadius="0 8px 8px 0"
              >
                <FormControlLabel
                  label={formatMessage({ id: 'sendPhoneCodeForm.switch' })}
                  control={<Switch checked={phoneLogin} onChange={handleSwitchChange} />}
                />
                {phoneLogin && <PhoneLogin authService={authService} authState={authState} />}
                {!phoneLogin && (
                  <LoginForm
                    onSubmit={onSubmit}
                    authState={authState}
                    showDoctorRegistration={true}
                    isContinueUrlRequired
                  />
                )}
              </Flex>
            </Grid>
          </Grid>
        </Flex>
      </Flex>
    </PublicTemplate>
  )
}
