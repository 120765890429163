import Changelog from './Changelog.mdx'

import { Box, Typography } from '@mui/material'
import { env } from '../env'

export const ChangelogScreen = () => {
  return (
    <Box>
      <Typography>Aktuální verze: {env.release}</Typography>
      <Changelog />
    </Box>
  )
}
