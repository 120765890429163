import { memo } from 'react'

interface Props {
  firstName: string
  lastName: string
  size?: number
}

const colors = [
  '#1abc9c',
  '#16a085',
  '#f1c40f',
  '#f39c12',
  '#2ecc71',
  '#27ae60',
  '#e67e22',
  '#d35400',
  '#3498db',
  '#2980b9',
  '#e74c3c',
  '#c0392b',
  '#9b59b6',
  '#8e44ad',
  '#bdc3c7',
  '#34495e',
  '#2c3e50',
  '#95a5a6',
  '#7f8c8d',
  '#ec87bf',
  '#d870ad',
  '#f69785',
  '#9ba37e',
  '#b49255',
  '#b49255',
  '#a94136',
]

export const AvatarInitials = memo(({ firstName, lastName, size = 35 }: Props) => {
  const text = `${firstName[0]}${lastName[0]}`
  const textSize = Math.ceil(size / 2.5)
  const colorIndex = Math.abs(Math.floor((text.charCodeAt(0) - 65) % colors.length))
  const finalColor = colors[colorIndex]

  return (
    <svg height={size} width={size} style={{ borderRadius: '50%', background: finalColor }}>
      <text textAnchor="middle" x="50%" y="50%" dy="0.35em" fill="white" fontSize={textSize}>
        {text.toUpperCase()}
      </text>
    </svg>
  )
})
