import { ComponentType, CSSProperties, forwardRef, MouseEventHandler, useState } from 'react'
import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  SlideProps,
  Tooltip,
} from '@mui/material'
import { useIntl } from 'react-intl'
import { LoadingButton } from '@mui/lab'

type Props = {
  confirmAction: () => Promise<unknown> | void
  dialogTitle: string
  alertComponentText?: string
  alertComponentColor?: ButtonProps['color']
  buttonStyle?: CSSProperties
  AlertComponent?: ComponentType<{ onClick: MouseEventHandler<HTMLDivElement> }>
  dialogText?: string
  stopPropagationOnClose?: boolean
  loading?: boolean
  disabled?: boolean
  disabledTooltip?: string
  buttonVariant?: string
}

const Transition = forwardRef((props: SlideProps, ref) => <Slide direction="up" ref={ref} {...props} />)

export const AlertDialogSlide = ({
  alertComponentText,
  alertComponentColor,
  dialogTitle,
  dialogText,
  confirmAction,
  AlertComponent,
  stopPropagationOnClose,
  loading,
  disabled,
  disabledTooltip,
  buttonVariant,
}: Props) => {
  const [open, setOpen] = useState(false)
  const { formatMessage } = useIntl()

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      {AlertComponent ? (
        <AlertComponent onClick={handleOpen}>{alertComponentText}</AlertComponent>
      ) : (
        <Tooltip title={(disabled && disabledTooltip) ?? ''}>
          <span style={{ cursor: 'not-allowed' }}>
            <Button
              disabled={disabled}
              variant={buttonVariant ?? 'contained'}
              onClick={handleOpen}
              color={alertComponentColor ?? 'error'}
            >
              {alertComponentText}
            </Button>
          </span>
        </Tooltip>
      )}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{dialogTitle}</DialogTitle>
        {dialogText && (
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">{dialogText}</DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button
            onClick={(e) => {
              handleClose()
              stopPropagationOnClose && e.stopPropagation()
            }}
            variant="outlined"
          >
            {formatMessage({ id: 'shared.no' })}
          </Button>
          <LoadingButton
            onClick={async (e) => {
              stopPropagationOnClose && e.stopPropagation()
              confirmAction()?.then(() => {
                handleClose()
              })
            }}
            variant="contained"
            color="error"
            loading={loading}
          >
            {formatMessage({ id: 'shared.yes' })}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}
