import { FC } from 'react'
import styled from 'styled-components'
import { IoMdCube } from 'react-icons/io'

const Circle = styled.div<any>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  opacity: ${({ opacity }) => opacity};
  background: white;
  border-radius: 100%;
  position: absolute;
  transition: opacity 0.3s ease-in;
`
const CirclesWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 238px;
  height: 238px;
`
const StyledIcon = styled(IoMdCube)`
  opacity: 0.5;
  transition: opacity 0.3s ease-in;
`
const ComponentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  &:hover {
    ${Circle} {
      opacity: 0.15;
    }
    ${StyledIcon} {
      opacity: 1;
    }
  }
`

interface Props {}

export const Circles: FC<Props> = ({ children }) => {
  return (
    <ComponentWrapper>
      <CirclesWrapper>
        <Circle size="238px" opacity={0.05} />
        <Circle size="164px" opacity={0.08} />
        <Circle size="92px" opacity={0.1} />
        <StyledIcon size="40px" color="white" />
      </CirclesWrapper>
      {children}
    </ComponentWrapper>
  )
}
