import { FC } from 'react'
import styled from 'styled-components'
import { Text } from '../atoms'

const StyledNotFound = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background: #37393f;
`

interface Props {}

export const NotFoundTemplate: FC<Props> = ({}) => {
  return (
    <StyledNotFound>
      <div style={{ fontSize: '200px' }}>🛸</div>
      <Text variant="h1">They stole the page!</Text>
    </StyledNotFound>
  )
}
