import { FormEventHandler, ReactNode, useEffect, useRef, useState } from 'react'
import styled, { StyledProps, useTheme } from 'styled-components'
import { SubmitHandler, UseFormMethods } from 'react-hook-form'
import { FormInnerProvider } from '../utils/FormInnerProvider'
import { Button, Flex, Loading, Text } from '../atoms'
import { useIntl } from 'react-intl'
// import { MdClose, MdCheck } from 'react-icons/md'

const Overlay = styled.div<StyledProps<any>>`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
`
const Container = styled.div.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => !['isOpen'].includes(prop) && defaultValidatorFn(prop),
})<StyledProps<any>>`
  border-radius: 10px;
  box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.05);
  position: fixed;
  z-index: 10100;
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  overflow-y: auto; // TODO: scroll in body not modal
`
const LoadingWrapper = styled.div<StyledProps<any>>`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(2px);
  z-index: 1000;
`

const StyledForm = styled.form.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => !['show'].includes(prop) && defaultValidatorFn(prop),
})<{ show: boolean }>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  height: 100%;
`
const StyledTitle = styled.div`
  padding: 20px;
  text-transform: uppercase;
  font-weight: 600;
`
const StyledContent = styled.div<{ padding?: string }>`
  display: flex;
  flex-direction: column;
  padding: ${({ padding }) => padding ?? '0 20px 20px 20px'};
  gap: 20px;
`
const StyledFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  width: 100%;
  gap: 20px;
  padding: 20px;
  border-radius: 0 0 10px 10px;
  background-color: rgba(0, 0, 0, 0.08);
`
const StyledError = styled.div`
  display: flex;
  padding: 0 20px 0 20px;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  font-size: 16px;
  color: ${({ theme }) => theme.palette.text.primary};
  background: ${({ theme }) => theme.palette.error.main};
  border-radius: 10px 10px 0 0;
`

interface Props<T extends Record<string, any>> {
  submitText?: string
  openActionText?: string
  onSubmit?: SubmitHandler<T>
  onReset?: FormEventHandler<HTMLFormElement>
  children?: ReactNode
  title?: string
  form: UseFormMethods<T>
  name: string
}

export function ModalForm<T>({
  submitText,
  children,
  onSubmit,
  onReset,
  title,
  form,
  openActionText,
  name,
}: Props<T>) {
  const { handleSubmit, errors, control } = form
  const [isOpen, setOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const [responseState, setResponseState] = useState<'success' | 'error' | undefined>()
  const [isLoading, setLoading] = useState(false)
  const [showChildren, setShowChildren] = useState(false)
  const [showOverlay, setShowOverlay] = useState(false)
  const buttonRef = useRef<any>()
  // const overlayStyle = useSpring({
  //   backdropFilter: `blur(${showOverlay ? 6 : 0}px)`,
  //   background: `rgba(0, 0, 0, ${showOverlay ? 0.4 : 0})`,
  // })
  const theme = useTheme()
  // const [style, set] = useSpring(() => ({
  //   height: 0,
  //   width: 0,
  //   top: 0,
  //   left: 0,
  //   backgroundColor: theme.palette.primary.main,
  // }))
  useEffect(() => {
    if (buttonRef.current) {
      setInitialSpring()
    }
  }, [buttonRef]) // eslint-disable-line
  const openModal = () => {
    const { innerHeight, innerWidth } = window
    // set({
    //   height: innerHeight * 0.8,
    //   width: innerWidth * 0.8,
    //   top: innerHeight * 0.1,
    //   left: innerWidth * 0.1,
    //   backgroundColor: theme.palette.background.paper,
    // })
    setOpen(true)
    setShowOverlay(true)
    setTimeout(() => {
      setShowChildren(true)
    }, 300)
  }
  const closeModal = () => {
    setShowChildren(false)
    setShowOverlay(false)
    setTimeout(() => {
      setOpen(false)
      setResponseState(undefined)
    }, 500)
    setInitialSpring()
  }
  const setInitialSpring = () => {
    if (!buttonRef.current) {
      return
    }
    const { height, width, top, left } = buttonRef.current.getBoundingClientRect()
    // set({ height, width, top, left, backgroundColor: theme.palette.primary.main })
  }
  const submitHandler = async (fields, event) => {
    setLoading(true)
    try {
      await onSubmit?.(fields, event)
      setLoading(false)
      setResponseState('success')
      setTimeout(() => {
        closeModal()
      }, 500)
    } catch (e) {
      setResponseState('error')
      setLoading(false)
      setErrorMessage(e.message)
      if (e.code === 'invalid-argument') {
        // TODO: process e.details.errors and display it
      }
    }
  }
  const cancelRequest = () => {
    setResponseState(undefined)
    setLoading(false)
    // TODO: cancel request for real
  }
  const intl = useIntl()
  const titleTranslation = intl.messages[`${name}.title`] ?? title
  const openActionTranslation = intl.messages[`${name}.openAction`] ?? openActionText
  const submitTranslation = intl.messages[`${name}.submit`] ?? submitText

  return (
    <FormInnerProvider errors={errors} isSubmitting={isLoading} control={control} formName={name} form={form}>
      <Button ref={buttonRef} onClick={openModal} kind="primary" hidden={isOpen}>
        {openActionTranslation}
      </Button>
      {/*{isOpen && <Overlay onClick={closeModal} style={overlayStyle} />}*/}
      <Container isOpen={isOpen}>
        {!isOpen && openActionTranslation}
        {isLoading && (
          <LoadingWrapper>
            <Loading />
          </LoadingWrapper>
        )}
        {responseState === 'error' && errorMessage && (
          <StyledError>
            {errorMessage}
            {/*<MdClose color="white" onClick={() => setErrorMessage(undefined)} />*/}
          </StyledError>
        )}
        {showChildren && titleTranslation && <StyledTitle>{titleTranslation}</StyledTitle>}
        {showChildren && (
          <StyledForm onSubmit={handleSubmit(submitHandler)} onReset={onReset} show={showChildren} id={name}>
            <Flex flexDirection="column" justifyContent="space-between" height="100%">
              <StyledContent padding={titleTranslation ? '0 20px 20px 20px' : '20px'}>
                {responseState === 'success' ? (
                  <Flex flexDirection="column" alignItems="center" justifyContent="center" height="100%">
                    {/*<MdCheck color={theme.palette.primary.main} size="100px" />*/}
                    <Text variant="h1" color={theme.palette.primary.main}>
                      Success
                    </Text>
                  </Flex>
                ) : (
                  children
                )}
              </StyledContent>
              <StyledFooter>
                {isLoading && (
                  <Button kind="error" onClick={cancelRequest}>
                    Cancel request
                  </Button>
                )}
                {!isLoading && responseState !== 'success' && (
                  <>
                    <Button variant="outlined" onClick={closeModal}>
                      Close
                    </Button>
                    <Button type="submit" kind="primary">
                      {submitTranslation}
                    </Button>
                  </>
                )}
                {responseState === 'success' && (
                  <Button variant="outlined" onClick={closeModal}>
                    Close
                  </Button>
                )}
              </StyledFooter>
            </Flex>
          </StyledForm>
        )}
      </Container>
    </FormInnerProvider>
  )
}
