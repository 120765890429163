import * as Types from '@proxyqb/graphql-api-types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export const DoctorsRightsDocument = gql`
    query doctorsRights($documentId: ID!) {
  allAccessRightsToDocument(
    to: $documentId
    filter: {node: {id: {startsWith: "users-"}}}
  ) {
    node {
      id
      ... on Doctor {
        firstName
        lastName
      }
    }
    accessRights {
      accessRights
    }
  }
}
    `;

export function useDoctorsRightsQuery(options: Omit<Urql.UseQueryArgs<DoctorsRightsQueryVariables>, 'query'>) {
  return Urql.useQuery<DoctorsRightsQuery, DoctorsRightsQueryVariables>({ query: DoctorsRightsDocument, ...options });
};
export type DoctorsRightsQueryVariables = Types.Exact<{
  documentId: Types.Scalars['ID'];
}>;


export type DoctorsRightsQuery = { __typename: 'Query', allAccessRightsToDocument: Array<{ __typename: 'AccessRightForNode', node: { __typename: 'Admin', id: string } | { __typename: 'Doctor', firstName: string, lastName: string, id: string } | { __typename: 'DocumentGroup', id: string } | { __typename: 'Patient', id: string } | { __typename: 'PublicDoctor', id: string } | { __typename: 'PublicUser', id: string }, accessRights: Array<{ __typename: 'AccessRight', accessRights: Array<Types.AccessRightType> }> }> };
