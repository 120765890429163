import { Input } from './input'
import { IconButton, InputAdornment } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { RegisterOptions } from 'react-hook-form'
import { ReactElement, useState } from 'react'

type Props = {
  name?: string
  rules?: RegisterOptions
  helperText?: string
}

export const PasswordInput = ({ name, rules, helperText }: Props): ReactElement => {
  const [visibility, setVisibility] = useState<boolean>(false)
  const passwordInputProps = (): ReactElement => {
    return (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          edge="end"
          onClick={() => setVisibility(!visibility)}
        >
          {visibility ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>
    )
  }

  return (
    <Input
      name={name ?? 'password'}
      type={visibility ? 'text' : 'password'}
      rules={rules ?? { required: true }}
      helperText={helperText}
      InputProps={{ endAdornment: passwordInputProps() }}
    />
  )
}
