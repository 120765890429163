import { FC, ReactElement } from 'react'
import styled, { css } from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import { Box, Button, ButtonBase } from '@mui/material'
import { ROOT_SCREEN_COMPONENT_PATH } from '../../../routes'
import { useIntl } from 'react-intl'
import { MaskIcon } from '@proxyqb/ui'

interface SideMenuProps {}

const StyledMenu = styled.div`
  display: flex;
  flex-direction: column;
  width: 260px;
  height: 100%;
  overflow: visible;
  box-shadow: ${({ theme }) => theme.shadows[0]};
  z-index: 1000;
  position: relative;
  top: 0;
  left: 0;
  padding: 20px 0 0 0;
  gap: 8px;
  background-color: ${({ theme }) => theme.palette.background.paper};
`

const SideMenuComponent: FC<SideMenuProps> = ({ children }) => {
  return (
    <>
      <StyledMenu>{children}</StyledMenu>
    </>
  )
}

interface SideMenuItemProps {
  t: string
  path: string
  exact?: boolean
}

// @ts-ignore
const StyledItem = styled('div').withConfig({
  shouldForwardProp: (prop) => !['selected'].includes(prop),
})<{ selected: boolean }>`
  display: flex;
  align-items: center;
  height: 50px;
  border-radius: 4px;
  color: ${({ theme }) => theme.palette.text.primary};
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  padding: 16px 32px;
  ${({ selected }) =>
    selected &&
    css`
      background: ${({ theme }) => theme.palette.primary.light};
      color: ${({ theme }) => theme.palette.primary.main};
      ::after {
        content: ' ';
        width: 5px;
        height: 50px;
        position: absolute;
        right: 0;
        border-radius: 5px 0 0 5px;
        background-color: ${({ theme }) => theme.palette.primary.main};
      }
    `} :hover {
    background: ${({ theme }) => theme.palette.primary.light};
  }
`
const StyledLink = styled(Link)`
  text-decoration: none;
  width: 100%;
`
const SideMenuItem = ({ t, path, exact }: SideMenuItemProps): ReactElement => {
  const { pathname } = useLocation()
  const intl = useIntl()
  let selected = pathname === path
  if (!exact) {
    selected = pathname === path || pathname.startsWith(`${path}/`)
  }
  if (path === `/${ROOT_SCREEN_COMPONENT_PATH}` && pathname === '/') {
    selected = true
  }
  return (
    <ButtonBase sx={{ p: 0 }} testing-id={t.split('.')[1]}>
      <StyledLink to={path}>
        <StyledItem selected={selected}>{intl.formatMessage({ id: t })}</StyledItem>
      </StyledLink>
    </ButtonBase>
  )
}

interface SideMenuHeaderProps {
  name: string
}
const SideMenuHeader: FC<SideMenuHeaderProps> = ({ name }) => {
  return (
    <Button
      component={Link}
      to="/users/me/profile"
      testing-id="header"
      sx={{
        textDecoration: 'none',
        '&:hover': {
          backgroundColor: 'primary.light',
        },
      }}
    >
      <Box
        sx={{
          fontSize: '24px',
          lineHeight: '24px',
          fontWeight: 'bold',
          fontDecoration: 'none',
          color: 'text.primary',
          display: 'flex',
          mt: 5,
          mb: 10,
          mx: 4,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'primary.main',
            height: '48px',
            width: '48px',
            borderRadius: '50%',
            mr: 2,
            flexShrink: 0,
          }}
        >
          <MaskIcon url="/static/media/icons/doctor.svg" color="white" width="30px" height="30px" />
        </Box>
        {name}
      </Box>
    </Button>
  )
}

const Spacer = styled.div`
  flex-grow: 1;
`

export const SideMenu = Object.assign(SideMenuComponent, {
  Item: SideMenuItem,
  Spacer,
  Header: SideMenuHeader,
})
