/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ProbabilityDensityData
 */
export interface ProbabilityDensityData {
  /**
   *
   * @type {string}
   * @memberof ProbabilityDensityData
   */
  levelId: string
  /**
   *
   * @type {any}
   * @memberof ProbabilityDensityData
   */
  levelName?: any
  /**
   *
   * @type {Array<number>}
   * @memberof ProbabilityDensityData
   */
  score: Array<number>
  /**
   *
   * @type {number}
   * @memberof ProbabilityDensityData
   */
  min: number
  /**
   *
   * @type {number}
   * @memberof ProbabilityDensityData
   */
  max: number
  /**
   *
   * @type {number}
   * @memberof ProbabilityDensityData
   */
  count: number
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof ProbabilityDensityData
   */
  scoreCounts: { [key: string]: number }
  /**
   *
   * @type {number}
   * @memberof ProbabilityDensityData
   */
  median: number
  /**
   *
   * @type {number}
   * @memberof ProbabilityDensityData
   */
  avg: number
  /**
   *
   * @type {Array<number>}
   * @memberof ProbabilityDensityData
   */
  quantiles: Array<number>
  /**
   *
   * @type {string}
   * @memberof ProbabilityDensityData
   */
  userId: string
  /**
   *
   * @type {string}
   * @memberof ProbabilityDensityData
   */
  firstName: string
  /**
   *
   * @type {string}
   * @memberof ProbabilityDensityData
   */
  lastName: string
}

/**
 * Check if a given object implements the ProbabilityDensityData interface.
 */
export function instanceOfProbabilityDensityData(value: object): boolean {
  if (!('levelId' in value)) return false
  if (!('score' in value)) return false
  if (!('min' in value)) return false
  if (!('max' in value)) return false
  if (!('count' in value)) return false
  if (!('scoreCounts' in value)) return false
  if (!('median' in value)) return false
  if (!('avg' in value)) return false
  if (!('quantiles' in value)) return false
  if (!('userId' in value)) return false
  if (!('firstName' in value)) return false
  if (!('lastName' in value)) return false
  return true
}

export function ProbabilityDensityDataFromJSON(json: any): ProbabilityDensityData {
  return ProbabilityDensityDataFromJSONTyped(json, false)
}

export function ProbabilityDensityDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ProbabilityDensityData {
  if (json == null) {
    return json
  }
  return {
    levelId: json['levelId'],
    levelName: json['levelName'] == null ? undefined : json['levelName'],
    score: json['score'],
    min: json['min'],
    max: json['max'],
    count: json['count'],
    scoreCounts: json['scoreCounts'],
    median: json['median'],
    avg: json['avg'],
    quantiles: json['quantiles'],
    userId: json['userId'],
    firstName: json['firstName'],
    lastName: json['lastName'],
  }
}

export function ProbabilityDensityDataToJSON(value?: ProbabilityDensityData | null): any {
  if (value == null) {
    return value
  }
  return {
    levelId: value['levelId'],
    levelName: value['levelName'],
    score: value['score'],
    min: value['min'],
    max: value['max'],
    count: value['count'],
    scoreCounts: value['scoreCounts'],
    median: value['median'],
    avg: value['avg'],
    quantiles: value['quantiles'],
    userId: value['userId'],
    firstName: value['firstName'],
    lastName: value['lastName'],
  }
}
