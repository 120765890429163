/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum GameType {
  StageCountLevelGoal = 'StageCountLevelGoal',
  TimeLevelGoal = 'TimeLevelGoal',
}

export function GameTypeFromJSON(json: any): GameType {
  return GameTypeFromJSONTyped(json, false)
}

export function GameTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): GameType {
  return json as GameType
}

export function GameTypeToJSON(value?: GameType | null): any {
  return value as any
}
