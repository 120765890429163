import { PHONE_NUMBER_REGEX } from '@proxyqb/ui'
import { InferType, boolean, object, string } from 'yup'

export const PASSWORD_LENGTH = 8

export const doctorFormSchema = object({
  prenominalLetters: string().trim().optional(),
  postnominalLetters: string().trim().optional(),
  firstName: string().trim().required(),
  lastName: string().trim().required(),
  email: string().trim().email().required(),
  phoneNumber: string()
    .trim()
    .matches(PHONE_NUMBER_REGEX, {
      excludeEmptyString: true,
    })
    .optional(),
  password: string().min(PASSWORD_LENGTH).matches(/[0-9]/).required(),
  hideGlobalLevels: boolean().required(),
})
  .required()
  .default({
    phoneNumber: '+420',
    email: '',
    lastName: '',
    firstName: '',
    password: '',
    postnominalLetters: '',
    prenominalLetters: '',
    hideGlobalLevels: false,
  })

export const editDoctorFormSchema = object({
  prenominalLetters: string().trim().optional(),
  postnominalLetters: string().trim().optional(),
  firstName: string().trim().required(),
  lastName: string().trim().required(),
  email: string().trim().email().required(),
  phoneNumber: string()
    .trim()
    .matches(PHONE_NUMBER_REGEX, {
      excludeEmptyString: true,
    })
    .optional(),
  hideGlobalLevels: boolean().required(),
}).required()

export type DoctorFormFields = InferType<typeof doctorFormSchema>
