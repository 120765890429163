import { forwardRef, MouseEventHandler, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { ifProp, switchProp } from 'styled-tools'

interface Props {
  onClick?: MouseEventHandler<any>
  variant?: 'outlined'
  kind?: 'primary' | 'secondary' | 'error'
  type?: 'button' | 'submit' | 'reset'
  disabled?: boolean
  children?: ReactNode
  hidden?: boolean
  width?: string | number
}

const StyledButton = styled.button<Props>`
  position: relative;
  display: inline-block;
  opacity: ${ifProp('hidden', 0, 1)};
  box-sizing: border-box;
  border: none;
  border-radius: 4px;
  padding: 0 16px;
  min-width: 64px;
  width: ${(props) => props.width};
  height: 36px;
  vertical-align: middle;
  text-align: center;
  text-overflow: ellipsis;
  text-transform: uppercase;
  color: ${({ theme, kind }) =>
    kind === 'primary' ? theme.palette.primary.contrastText : theme.palette.secondary.contrastText};
  ${ifProp(
    'disabled',
    css`
      background-color: ${({ theme }) => theme.palette.grey[400]};
    `,
    switchProp(
      'kind',
      {
        primary: css`
          background-color: ${({ theme }) => theme.palette.primary.main};
        `,
        secondary: css`
          background-color: ${({ theme }) => theme.palette.secondary.main};
        `,
        error: css`
          background-color: ${({ theme }) => theme.palette.error.main};
        `,
      },
      css`
        background-color: ${({ theme }) => theme.palette.primary.main};
      `,
    ),
  )}
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  font-family: 'Roboto', 'Segoe UI', BlinkMacSystemFont, system-ui, -apple-system;
  font-size: 14px;
  font-weight: 500;
  line-height: 36px;
  overflow: hidden;
  outline: none;
  cursor: pointer;
  transition: box-shadow 0.2s;

  &::-moz-focus-inner {
    border: none;
  }

  /* Overlay */
  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgb(var(--pure-material-onprimary-rgb, 255, 255, 255));
    opacity: 0;
    transition: opacity 0.2s;
  }

  /* Ripple */
  &::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 50%;
    padding: 50%;
    width: 32px; /* Safari */
    height: 32px; /* Safari */
    background-color: rgb(var(--pure-material-onprimary-rgb, 255, 255, 255));
    opacity: 0;
    transform: translate(-50%, -50%) scale(1);
    transition: opacity 1s, transform 0.5s;
  }

  /* Hover, Focus */
  &:hover,
  &:focus {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
      0 1px 10px 0 rgba(0, 0, 0, 0.12);
  }

  &:hover::before {
    opacity: 0.08;
  }

  &:focus::before {
    opacity: 0.24;
  }

  &:hover:focus::before {
    opacity: 0.3;
  }

  /* Active */
  &:active {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
      0 3px 14px 2px rgba(0, 0, 0, 0.12);
  }

  &:active::after {
    opacity: 0.32;
    transform: translate(-50%, -50%) scale(0);
    transition: transform 0s;
  }

  /* Disabled */
  &:disabled {
    color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
    background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.12);
    box-shadow: none;
    cursor: initial;
  }

  &:disabled::before {
    opacity: 0;
  }

  &:disabled::after {
    opacity: 0;
  }
`

/**
 * @deprecated Use MUI
 */
// eslint-disable-next-line react/display-name
export const Button = forwardRef(({ children, type = 'button', ...rest }: Props, ref) => {
  return (
    <StyledButton ref={ref as any} type={type} {...rest}>
      {children}
    </StyledButton>
  )
})
