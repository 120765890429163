/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ScoreTable
 */
export interface ScoreTable {
  /**
   *
   * @type {number}
   * @memberof ScoreTable
   */
  place: number
  /**
   *
   * @type {string}
   * @memberof ScoreTable
   */
  date: string
  /**
   *
   * @type {string}
   * @memberof ScoreTable
   */
  score: string
}

/**
 * Check if a given object implements the ScoreTable interface.
 */
export function instanceOfScoreTable(value: object): boolean {
  if (!('place' in value)) return false
  if (!('date' in value)) return false
  if (!('score' in value)) return false
  return true
}

export function ScoreTableFromJSON(json: any): ScoreTable {
  return ScoreTableFromJSONTyped(json, false)
}

export function ScoreTableFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScoreTable {
  if (json == null) {
    return json
  }
  return {
    place: json['place'],
    date: json['date'],
    score: json['score'],
  }
}

export function ScoreTableToJSON(value?: ScoreTable | null): any {
  if (value == null) {
    return value
  }
  return {
    place: value['place'],
    date: value['date'],
    score: value['score'],
  }
}
