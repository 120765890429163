import { useMachine } from '@xstate/react'
import {
  authenticationMachine,
  AuthenticationMachineContext,
  AuthenticationMachineEvent,
  AuthenticationMachineTypestate,
} from './authentication-machine'
import { Interpreter, State } from 'xstate'
import { createCtx } from './create-ctx'

interface GlobalStateContextType {
  authState: State<AuthenticationMachineContext, any, AuthenticationMachineTypestate>
  authService: Interpreter<
    AuthenticationMachineContext,
    any,
    AuthenticationMachineEvent,
    AuthenticationMachineTypestate
  >
}

const [useGlobalState, GlobalStateContextProvider] = createCtx<GlobalStateContextType>()

export const GlobalStateProvider = (props) => {
  // const authService = useInterpret(authenticationMachine)
  const [authState, , authService] = useMachine(authenticationMachine)
  return (
    <GlobalStateContextProvider value={{ authState, authService }}>
      {props.children}
    </GlobalStateContextProvider>
  )
}

export { useGlobalState }
