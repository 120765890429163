import { FC } from 'react'
import styled from 'styled-components'

interface Props {}

const StyledMain = styled.div`
  width: 100vw;
  min-height: 100vh;
  background: ${({ theme }) => theme.palette.background.default};
`

const StyledContent = styled.div`
  padding: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const PublicTemplate: FC<Props> = ({ children }) => {
  return (
    <StyledMain>
      <StyledContent>{children}</StyledContent>
    </StyledMain>
  )
}
