import { Ability, defineAbility } from '@casl/ability'
import { AccessRight, AccessRightType, Doctor, Patient, Role, User } from '@proxyqb/graphql-api-types'
import { uniq } from 'lodash-es'

type RequireField<T, K extends keyof T> = T & Required<Pick<T, K>>
type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>
    }
  : T

export const abilitySubjectTypes = ['Doctor', 'Patient'] as const

export const AbilityActions = {
  create: 'create',
  read: 'read',
  update: 'update',
  delete: 'delete',
  updateRights: 'updateRights',
} as const

export type AbilityActions = typeof AbilityActions[keyof typeof AbilityActions]

const allAbilityActions: AbilityActions[] = ['create', 'read', 'update', 'delete', 'updateRights']

export const mapBEAccessRights = (accessRights: (AccessRight | AccessRightType)[]): AbilityActions[] =>
  uniq(accessRights.flatMap((it) => (typeof it === 'string' ? [it] : it.accessRights))).map((right) => {
    switch (right) {
      case AccessRightType.ReadDocument:
        return AbilityActions.read
      case AccessRightType.UpdateDocument:
        return AbilityActions.update
      case AccessRightType.RemoveDocument:
        return AbilityActions.delete
      case AccessRightType.UpdateRights:
        return AbilityActions.updateRights
      default:
        return right
    }
  })

export type AbilitySubjectTypes = typeof abilitySubjectTypes[number]
export type AbilitySubjectKeys = 'id'
export type AbilitySubjects =
  | AbilitySubjectTypes
  | ({ __typename: 'Doctor' } & RequireField<DeepPartial<Doctor>, 'id'>)
  | ({ __typename: 'Patient' } & RequireField<DeepPartial<Patient>, 'id'>)
  | 'Level'
  | 'Playthrough'
  | 'RehabilitationPlan'
  | 'Catalog'
  | 'CatalogItem'
  | 'Role'
  | 'DoctorGroup'
  | 'PatientGroup'
  | 'Firmware'
  | 'all'
export type AppAbility = Ability<[AbilityActions, AbilitySubjects]>

const userHasPatients = (user: User): user is Doctor => (user as Doctor).patients !== undefined

export const createAbility = (user: User) =>
  defineAbility<AppAbility>(
    (allow) => {
      user.roles.forEach((role) => {
        switch (role) {
          case Role.Admin:
            allow(allAbilityActions, 'all')
            break
          case Role.Doctor:
            if (userHasPatients(user)) {
              allow(['create', 'read'], 'Patient')
              user.patients.nodes.forEach(({ node: { id }, accessRights }) =>
                allow(mapBEAccessRights(accessRights), 'Patient', { id }),
              )
            }
            allow(['read', 'update'], 'Doctor', { id: user.id })
            allow(['create', 'read', 'update'], ['Level', 'RehabilitationPlan'])
            allow(['create', 'read'], 'Playthrough')
            allow('read', ['Catalog', 'CatalogItem'])
            break
          case Role.Patient:
            allow('read', 'Patient', { id: user.id })
            allow('create', 'Playthrough')
            allow('read', ['Level', 'RehabilitationPlan', 'Catalog', 'CatalogItem'])
            break
        }
      })
    },
    {
      detectSubjectType: (object) => object.__typename,
    },
  )
