import { FC } from 'react'
import styled from 'styled-components'

const StyledImage = styled.img<any>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`

interface Props {
  empty?: boolean
  size?: string
}

// TODO: get some nice images, put them in our assets folder, precache them in sw-config.js
export const Star: FC<Props> = ({ empty, size }) => {
  return empty ? (
    <StyledImage
      size={size}
      src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Empty_Star.svg/500px-Empty_Star.svg.png"
    />
  ) : (
    <StyledImage
      size={size}
      src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a0/Antu_rating.svg/200px-Antu_rating.svg.png"
    />
  )
}
