import { Box, Button } from '@mui/material'
import { useGlobalState } from '@proxyqb/portal-global-state'
import { useFlagsmith } from '@proxyqb/react-feature-flags'
import { LanguageSelect, MaskIcon } from '@proxyqb/ui'
import { useSelector } from '@xstate/react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { Role } from '@proxyqb/graphql-api-types'
import { Can } from '../../ability-context'
import { SideMenu } from './SideMenu'

export const Menu = () => {
  const intl = useIntl()
  const globalServices = useGlobalState()
  const { flags } = useFlagsmith()
  const isLoggedIn = useSelector(globalServices.authService, (state) => {
    return state.matches('loggedIn')
  })
  const user = useSelector(globalServices.authService, (state) => {
    return state.context?.userDetails
  })

  if (!isLoggedIn) {
    return <SideMenu />
  }

  const { Item, Header, Spacer } = SideMenu

  return (
    <SideMenu>
      <Header name={`${user!.firstName} ${user!.lastName}`} />
      <Item t="menu.dashboard" path="/dashboard" />
      <Can I="read" a="Doctor">
        {user!.roles.includes(Role.Admin) && <Item t="menu.doctors" path="/doctors" />}
      </Can>
      <Can I="read" a="Patient">
        <Item t="menu.patients" path="/patients" />
      </Can>
      <Can I="read" a="Catalog">
        {user!.roles.includes(Role.Admin) && <Item t="menu.catalogs" path="/catalogs" />}
      </Can>
      <Can I="update" a="Role">
        <Item t="menu.roles" path="/roles" />
      </Can>
      <Can I="read" a="Level">
        <Item t="menu.games" path="/games" />
      </Can>
      <Can I="read" a="RehabilitationPlan">
        <Item t="menu.rehabilitationPlans" path="/rehabilitation-plans" />
      </Can>
      <Can I="read" a="Firmware">
        <Item t="menu.firmware" path="/firmware" />
      </Can>
      {flags.analytics?.enabled && <Item t="menu.analytics" path="/analytics" />}
      {/*{hasOneOfThese([Role.admin, Role.doctor]) && (*/}
      {/*  <Item t="providers" path="/providers">*/}
      {/*    Providers*/}
      {/*  </Item>*/}
      {/*)}*/}
      <Item t="menu.changelog" path="/changelog" />
      {user!.roles.includes(Role.Admin) && <Item t="menu.devArea" path="/dev-area" />}
      {user!.roles.includes(Role.Admin) && <Item t="menu.assetDelivery" path="/assets" />}
      <Box sx={{ flexGrow: 1 }}></Box>
      <Box
        sx={{
          px: 4,
          py: 0,
          position: 'sticky',
          bottom: 0,
          left: 0,
          right: 0,
          width: '260px',
        }}
        testing-id="logout"
      >
        <Box
          sx={{
            background:
              'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 25%, rgba(255,255,255,0) 100%)',
            height: '40px',
            width: '260px',
            ml: -4,
          }}
        />
        <Box sx={{ backgroundColor: 'white', width: '260px', ml: -4, pl: 4 }}>
          <LanguageSelect marginTop={2} />
          <Button
            component={Link}
            to="/logout"
            sx={{
              mt: '1vw',
              color: 'text.primary',
              textTransform: 'initial',
              justifyContent: 'flex-start',
              width: '260px',
              ml: -4,
              pl: 4,
              pb: 2,
            }}
          >
            <MaskIcon url="/static/media/icons/exit_to_app-filled.svg" sx={{ mr: 1 }} />
            {intl.formatMessage({ id: 'menu.logout' })}
          </Button>
          <Spacer />
        </Box>
      </Box>
    </SideMenu>
  )
}
