/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ZScoreResult
 */
export interface ZScoreResult {
  /**
   *
   * @type {number}
   * @memberof ZScoreResult
   */
  mean: number
  /**
   *
   * @type {number}
   * @memberof ZScoreResult
   */
  zScore: number
  /**
   *
   * @type {number}
   * @memberof ZScoreResult
   */
  domainMean: number
  /**
   *
   * @type {number}
   * @memberof ZScoreResult
   */
  domainStd: number
  /**
   *
   * @type {Array<string>}
   * @memberof ZScoreResult
   */
  domains: Array<string>
  /**
   *
   * @type {string}
   * @memberof ZScoreResult
   */
  levelGoal: string
}

/**
 * Check if a given object implements the ZScoreResult interface.
 */
export function instanceOfZScoreResult(value: object): boolean {
  if (!('mean' in value)) return false
  if (!('zScore' in value)) return false
  if (!('domainMean' in value)) return false
  if (!('domainStd' in value)) return false
  if (!('domains' in value)) return false
  if (!('levelGoal' in value)) return false
  return true
}

export function ZScoreResultFromJSON(json: any): ZScoreResult {
  return ZScoreResultFromJSONTyped(json, false)
}

export function ZScoreResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ZScoreResult {
  if (json == null) {
    return json
  }
  return {
    mean: json['mean'],
    zScore: json['zScore'],
    domainMean: json['domainMean'],
    domainStd: json['domainStd'],
    domains: json['domains'],
    levelGoal: json['levelGoal'],
  }
}

export function ZScoreResultToJSON(value?: ZScoreResult | null): any {
  if (value == null) {
    return value
  }
  return {
    mean: value['mean'],
    zScore: value['zScore'],
    domainMean: value['domainMean'],
    domainStd: value['domainStd'],
    domains: value['domains'],
    levelGoal: value['levelGoal'],
  }
}
