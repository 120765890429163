import { FC, useEffect } from 'react'
import { useGlobalState } from '@proxyqb/portal-global-state'

export const LogoutScreen: FC = () => {
  const { authService } = useGlobalState()
  useEffect(() => {
    authService.send('LOG_OUT')
  }, []) // eslint-disable-line
  return <></>
}
