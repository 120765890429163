import { CSSProperties, forwardRef, PropsWithChildren } from 'react'
// import { ColorFunction } from '../theme'
import { convertSize } from '../utils'
// import { flexWrap, FlexWrapProps } from 'styled-system'
import { Box } from '@mui/material'
import { BoxProps } from '@mui/system'
import { pick, omit } from 'lodash-es'

const flexProps = [
  'padding',
  'margin',
  'position',
  'order',
  'overflow',
  'pointerEvents',
  'cursor',
  'zIndex',
  'flexDirection',
  'alignItems',
  'alignSelf',
  'justifyContent',
  'flex',
  'flexWrap',
  'flexGrow',
  'color',
  'border',
  'borderTop',
  'borderRadius',
  'borderBottom',
  'borderLeft',
  'borderRight',
] as const

const convertSizeProps = [
  'gap',
  'width',
  'maxWidth',
  'minWidth',
  'height',
  'maxHeight',
  'minHeight',
  'right',
  'top',
  'left',
] as const

type ElementType<T extends ReadonlyArray<unknown>> = T extends ReadonlyArray<infer ElementType>
  ? ElementType
  : never

type FlexValues = ElementType<typeof flexProps> // this is correctly inferred as literal "A" | "B"
type ConvertValues = ElementType<typeof convertSizeProps> // this is correctly inferred as literal "A" | "B"

export type FlexProps = {
  // background?: ColorFunction | string
  background?: string
} & Pick<CSSProperties, FlexValues> & {
    [Key in ConvertValues]?: CSSProperties[Key] | number
  }
// & FlexWrapProps

// export const Flex: StyledComponent<'div', DefaultTheme, FlexProps> = styled.div
//   .withConfig({
//     shouldForwardProp: (prop) =>
//       ![...flexProps, ...convertSizeProps, 'background', 'flexWrap'].includes(prop),
//   })
//   .attrs((props: FlexProps) => {
//     return {
//       style: {
//         ...flexProps.reduce((acc, prop) => ({ ...acc, [prop]: props[prop] }), {}),
//         ...convertSizeProps.reduce((acc, prop) => ({ ...acc, [prop]: convertSize(props[prop]) }), {}),
//       },
//     }
//   })<FlexProps>`
//   display: flex;
//   background: ${({ background }) => background};
//   ${flexWrap}
// `

export const Flex = forwardRef(
  (
    { children, sx, background, ...rest }: PropsWithChildren<FlexProps & BoxProps>,
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const styles = pick(rest, flexProps)
    const otherProps = omit(rest, [...flexProps, ...convertSizeProps])
    return (
      <Box
        sx={{
          display: 'flex',
          ...styles,
          ...convertSizeProps.reduce((acc, prop) => ({ ...acc, [prop]: convertSize(rest[prop]) }), {}),
          ...sx,
          background,
        }}
        {...otherProps}
      >
        {children}
      </Box>
    )
  },
)

Flex.displayName = 'Flex'
