import { Breadcrumbs as MUIBreadcrumbs, Button, Skeleton, Typography } from '@mui/material'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useGetNameQuery } from './breadcrumbs.generated'
import { AlertDialogSlide } from '@proxyqb/ui'

const BackButton = ({ onClick }) => {
  const { formatMessage } = useIntl()

  return (
    <Button
      variant="text"
      onClick={(e) => {
        e.stopPropagation()
        onClick()
      }}
      sx={{
        minWidth: 0,
        padding: 0,
        ':hover': {
          background: 'none',
        },
      }}
    >
      {formatMessage({ id: 'shared.back' })}
    </Button>
  )
}

interface Props {
  ignorePaths?: string[]
}

export const Breadcrumbs = ({ ignorePaths }: Props) => {
  const location = useLocation()
  const pathNames = location.pathname.split('/').filter(Boolean)
  const shouldIgnoreThisPath = ignorePaths?.some((path) => location.pathname.match(path))
  const { formatMessage, messages } = useIntl()
  const navigate = useNavigate()

  const pathIds = shouldIgnoreThisPath
    ? []
    : pathNames
        .filter(Boolean)
        .filter((it) => it!.match(/\d{1,}$/) || it!.includes('users-'))
        .filter((it) => it!.includes('-'))
        .filter((it) => !it!.includes('/'))
  const [{ fetching, data }] = useGetNameQuery({
    variables: { ids: pathIds },
    pause: !pathIds.length,
    requestPolicy: 'network-only',
  })
  if (shouldIgnoreThisPath) {
    return null
  }

  const backBtnRender = (pathNames: string[]) => {
    return Object.values(pathNames).some(
      (x) =>
        x.includes('users') ||
        x.includes('documentGroups') ||
        x.includes('rehabilitationPlans-') ||
        x.includes('levels-') ||
        x.includes('catalogs-'),
    )
  }

  return (
    <MUIBreadcrumbs
      separator="-"
      sx={{
        position: 'absolute',
        left: (theme) => theme.spacing(theme.mainScreenSpacing.px),
        top: 30,
        display: 'flex',
        justifyContent: 'flex-start',
        '& .MuiTypography-root': {
          fontWeight: '500',
        },
      }}
    >
      {Object.values(pathNames).some((x) => x.includes('form')) && (
        <AlertDialogSlide
          AlertComponent={BackButton}
          dialogText={formatMessage({ id: 'shared.confirmBackText' })}
          dialogTitle={formatMessage({ id: 'shared.confirmBackTitle' })}
          stopPropagationOnClose={true}
          confirmAction={() => navigate(-1)}
        />
      )}
      {backBtnRender(pathNames) && !pathNames.includes('form') && <BackButton onClick={() => navigate(-1)} />}
      {pathNames.map((value) => {
        const nameRecord = data?.names.find((n) => n.id === value)
        const breadcrumbLabel = (() => {
          if (nameRecord) {
            return nameRecord.name
          }
          const id = `shared.breadcrumbs.${value}`
          if (messages[id]) {
            return formatMessage({ id })
          }
          return <Skeleton variant="rectangular" width={100} height={18} />
        })()

        return (
          <Typography key={value} color="text.primary">
            {breadcrumbLabel}
          </Typography>
        )
      })}
    </MUIBreadcrumbs>
  )
}
