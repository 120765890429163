/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { MovingTime } from './MovingTime'
import { MovingTimeFromJSON, MovingTimeFromJSONTyped, MovingTimeToJSON } from './MovingTime'
import type { PercentageCloseness } from './PercentageCloseness'
import {
  PercentageClosenessFromJSON,
  PercentageClosenessFromJSONTyped,
  PercentageClosenessToJSON,
} from './PercentageCloseness'

/**
 *
 * @export
 * @interface PlaythroughStats
 */
export interface PlaythroughStats {
  /**
   *
   * @type {Array<PercentageCloseness>}
   * @memberof PlaythroughStats
   */
  percentageCloseness: Array<PercentageCloseness>
  /**
   *
   * @type {Array<MovingTime>}
   * @memberof PlaythroughStats
   */
  movingTimes: Array<MovingTime>
}

/**
 * Check if a given object implements the PlaythroughStats interface.
 */
export function instanceOfPlaythroughStats(value: object): boolean {
  if (!('percentageCloseness' in value)) return false
  if (!('movingTimes' in value)) return false
  return true
}

export function PlaythroughStatsFromJSON(json: any): PlaythroughStats {
  return PlaythroughStatsFromJSONTyped(json, false)
}

export function PlaythroughStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlaythroughStats {
  if (json == null) {
    return json
  }
  return {
    percentageCloseness: (json['percentageCloseness'] as Array<any>).map(PercentageClosenessFromJSON),
    movingTimes: (json['movingTimes'] as Array<any>).map(MovingTimeFromJSON),
  }
}

export function PlaythroughStatsToJSON(value?: PlaythroughStats | null): any {
  if (value == null) {
    return value
  }
  return {
    percentageCloseness: (value['percentageCloseness'] as Array<any>).map(PercentageClosenessToJSON),
    movingTimes: (value['movingTimes'] as Array<any>).map(MovingTimeToJSON),
  }
}
