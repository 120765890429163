/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ScoreChart
 */
export interface ScoreChart {
  /**
   *
   * @type {string}
   * @memberof ScoreChart
   */
  gameType: string
  /**
   *
   * @type {string}
   * @memberof ScoreChart
   */
  date: string
  /**
   *
   * @type {number}
   * @memberof ScoreChart
   */
  score: number
}

/**
 * Check if a given object implements the ScoreChart interface.
 */
export function instanceOfScoreChart(value: object): boolean {
  if (!('gameType' in value)) return false
  if (!('date' in value)) return false
  if (!('score' in value)) return false
  return true
}

export function ScoreChartFromJSON(json: any): ScoreChart {
  return ScoreChartFromJSONTyped(json, false)
}

export function ScoreChartFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScoreChart {
  if (json == null) {
    return json
  }
  return {
    gameType: json['gameType'],
    date: json['date'],
    score: json['score'],
  }
}

export function ScoreChartToJSON(value?: ScoreChart | null): any {
  if (value == null) {
    return value
  }
  return {
    gameType: value['gameType'],
    date: value['date'],
    score: value['score'],
  }
}
