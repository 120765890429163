import { FC } from 'react'
import { Grid, Box } from '@mui/material'
import { LastPlaythroughsWidget } from './widgets/LastPlaythroughsWidget'
import { PlaythroughsTodayWidget } from './widgets/PlaythroughsTodayWidget'

interface Props {}

export const DashboardScreen: FC<Props> = () => {
  return (
    <Box mt={-20}>
      <Grid container spacing={4}>
        <LastPlaythroughsWidget />
        <PlaythroughsTodayWidget />
      </Grid>
    </Box>
  )
}
