import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { useContext } from 'react'
import { LanguageContext } from '../utils'
import { useIntl } from 'react-intl'

interface LanguageSelectProps {
  width?: any
  marginTop?: any
}

export const LanguageSelect = ({ width, marginTop }: LanguageSelectProps) => {
  const { userLanguage, userLanguageChange } = useContext(LanguageContext)
  const { formatMessage } = useIntl()

  const handleChange = (event: SelectChangeEvent) => {
    const selectedLanguage = event.target.value as string
    userLanguageChange(selectedLanguage)
  }

  return (
    <FormControl sx={{ width: width, marginTop: marginTop }}>
      <InputLabel id="language-select-label">{formatMessage({ id: 'shared.language' })}</InputLabel>
      <Select
        labelId="language-select-label"
        value={userLanguage}
        label={formatMessage({ id: 'shared.language' })}
        onChange={handleChange}
        testing-id="languageSelect"
      >
        <MenuItem value={'cs'}>
          <img height={12} style={{ marginRight: 5 }} src={'/static/media/flags/czflag.svg'} alt={'czflag'} />
          Čeština
        </MenuItem>
        <MenuItem value={'en'}>
          <img height={12} style={{ marginRight: 5 }} src={'/static/media/flags/ukflag.svg'} alt={'czflag'} />
          English
        </MenuItem>
        <MenuItem value={'pl'}>
          <img height={12} style={{ marginRight: 5 }} src={'/static/media/flags/plflag.svg'} alt={'plflag'} />
          Polski
        </MenuItem>
        <MenuItem value={'is'}>
          <img
            height={12}
            style={{ marginRight: 5 }}
            src={'/static/media/flags/islflag.svg'}
            alt={'islflag'}
          />
          Íslenskur
        </MenuItem>
        <MenuItem value={'nl'}>
          <img height={12} style={{ marginRight: 5 }} src={'/static/media/flags/nlflag.svg'} alt={'nlflag'} />
          Nederlands
        </MenuItem>
      </Select>
    </FormControl>
  )
}
