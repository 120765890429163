/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { SortOrder } from './SortOrder'
import { SortOrderFromJSON, SortOrderFromJSONTyped, SortOrderToJSON } from './SortOrder'

/**
 *
 * @export
 * @interface Sort
 */
export interface Sort {
  /**
   *
   * @type {string}
   * @memberof Sort
   */
  attribute: string
  /**
   *
   * @type {SortOrder}
   * @memberof Sort
   */
  order: SortOrder
}

/**
 * Check if a given object implements the Sort interface.
 */
export function instanceOfSort(value: object): boolean {
  if (!('attribute' in value)) return false
  if (!('order' in value)) return false
  return true
}

export function SortFromJSON(json: any): Sort {
  return SortFromJSONTyped(json, false)
}

export function SortFromJSONTyped(json: any, ignoreDiscriminator: boolean): Sort {
  if (json == null) {
    return json
  }
  return {
    attribute: json['attribute'],
    order: SortOrderFromJSON(json['order']),
  }
}

export function SortToJSON(value?: Sort | null): any {
  if (value == null) {
    return value
  }
  return {
    attribute: value['attribute'],
    order: SortOrderToJSON(value['order']),
  }
}
