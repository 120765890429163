import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage'
import { FileInput, Form, Input, Submit } from '@proxyqb/ui-forms'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { InferType, mixed, object, string } from 'yup'
import { useAddFirmwareMutation } from './firmware.generated'

export const formSchema = object({
  name: string().required(),
  version: string().required(),
  file: mixed()
    .test('invalid file size', (value) => value && value[0].size <= 2000000)
    .test('invalid file type', (value) => value && value[0].name.split('.').pop() === 'cpp')
    .required('Required'),
})
  .required()
  .default({
    name: '',
    version: '',
  })

export type FormFields = InferType<typeof formSchema>
const resolver = yupResolver(formSchema)

export const AddFirmwareForm = ({ refreshData }: { refreshData: () => void }) => {
  const { formatMessage } = useIntl()
  const { enqueueSnackbar } = useSnackbar()
  const [open, setOpen] = useState(false)
  const form = useForm<FormFields>({
    defaultValues: formSchema.getDefault(),
    resolver,
  })

  const [, addFirmware] = useAddFirmwareMutation()

  const handleSubmit = async (firmware) => {
    const storage = getStorage()
    const fileFirmwareRef = ref(storage, `firmware/${firmware.file[0].name}`)
    try {
      const metadata = {
        contentType: 'image/jpeg',
      }
      await uploadBytes(fileFirmwareRef, firmware.file[0], metadata)
    } catch (e) {
      enqueueSnackbar(e.message, {
        variant: 'error',
      })
    }
    const url = await getDownloadURL(fileFirmwareRef)
    const result = await addFirmware({
      input: {
        name: firmware.name,
        version: firmware.version,
        url: url,
      },
    })
    if (result.error) {
      enqueueSnackbar(result.error.message, {
        variant: 'error',
      })
    } else {
      enqueueSnackbar(formatMessage({ id: 'firmware.firmwareAdded' }), {
        variant: 'success',
        autoHideDuration: 1000,
      })
    }
    refreshData()
    setOpen(false)
  }

  useEffect(() => {
    form.reset()
  }, [open])

  return (
    <>
      <Button variant="contained" onClick={() => setOpen(true)}>
        {formatMessage({ id: 'firmware.addFirmware' })}
      </Button>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <Form name="firmware" onSubmit={handleSubmit} form={form} schema={formSchema}>
          <DialogTitle sx={{ padding: '16px 40px' }}>
            {formatMessage({ id: 'firmware.addFirmware' })}
          </DialogTitle>
          <DialogContent sx={{ padding: '0 40px 20px' }}>
            <Input name="name" sx={{ display: 'block', margin: '15px 0', width: '100%' }} />
            <Input name="version" sx={{ display: 'block', margin: '15px 0', width: '100%' }} />
            <FileInput name="source" accept=".cpp" />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)}>{formatMessage({ id: 'shared.cancel' })}</Button>
            <Submit />
          </DialogActions>
        </Form>
      </Dialog>
    </>
  )
}
