import { LoadingButton, LoadingButtonProps } from '@mui/lab'
import { ReactNode } from 'react'
import { useIntl } from 'react-intl'
import { useFormInnerContext } from '../utils/FormInnerProvider'

type SubmitProps = {
  children?: ReactNode
  buttonProps?: LoadingButtonProps
  onClick?: () => void
}

export function Submit({ children, buttonProps, onClick }: SubmitProps) {
  const { formName, isSubmitting } = useFormInnerContext()
  const intl = useIntl()
  const label = intl.messages[`${formName}.submit`] ?? intl.messages[`shared.submit`] ?? `${formName}.submit`
  return (
    <LoadingButton
      variant="contained"
      loading={isSubmitting}
      type="submit"
      style={{
        width: 'max-content',
      }}
      {...buttonProps}
      onClick={onClick}
    >
      {children || label}
    </LoadingButton>
  )
}
