import { animated, useSpring } from 'react-spring'
import { alpha, Collapse } from '@mui/material'
import styled from 'styled-components'
import { omit } from 'lodash-es'
import { TreeItem as MUITreeItem, treeItemClasses, TreeView as MUITreeView, TreeViewProps } from '@mui/lab'
import { FC } from 'react'
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io'

const TransitionComponent = (props) => {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: 'translate3d(20px,0,0)',
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  })

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  )
}

export const TreeItem = styled(({ nodeId, ...props }) => (
  <MUITreeItem
    {...omit(props, ['showBorder', 'borderStyle'])}
    nodeId={nodeId}
    TransitionComponent={TransitionComponent}
  />
))(({ theme, showBorder, borderStyle }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    '& .close': {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
  [`& .${treeItemClasses.content}`]: {
    minHeight: '50px',
    margin: 0,
    borderBottom: showBorder ? borderStyle : '',
  },
  [`& .${treeItemClasses.expanded}`]: {
    borderBottom: borderStyle,
  },
}))

export const TreeView: FC<Pick<TreeViewProps, 'expanded' | 'onNodeToggle'>> = ({
  expanded,
  onNodeToggle,
  children,
}) => (
  <MUITreeView
    aria-label="catalog items tree"
    defaultCollapseIcon={<IoIosArrowDown />}
    defaultExpandIcon={<IoIosArrowForward />}
    onNodeToggle={onNodeToggle}
    expanded={expanded}
    sx={{ flexGrow: 1 }}
  >
    {children}
  </MUITreeView>
)
