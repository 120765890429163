/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { PlaythroughStats } from './PlaythroughStats'
import {
  PlaythroughStatsFromJSON,
  PlaythroughStatsFromJSONTyped,
  PlaythroughStatsToJSON,
} from './PlaythroughStats'

/**
 *
 * @export
 * @interface UserRunsData
 */
export interface UserRunsData {
  /**
   *
   * @type {string}
   * @memberof UserRunsData
   */
  endedAt: string
  /**
   *
   * @type {number}
   * @memberof UserRunsData
   */
  score: number
  /**
   *
   * @type {PlaythroughStats}
   * @memberof UserRunsData
   */
  stats: PlaythroughStats
}

/**
 * Check if a given object implements the UserRunsData interface.
 */
export function instanceOfUserRunsData(value: object): boolean {
  if (!('endedAt' in value)) return false
  if (!('score' in value)) return false
  if (!('stats' in value)) return false
  return true
}

export function UserRunsDataFromJSON(json: any): UserRunsData {
  return UserRunsDataFromJSONTyped(json, false)
}

export function UserRunsDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserRunsData {
  if (json == null) {
    return json
  }
  return {
    endedAt: json['endedAt'],
    score: json['score'],
    stats: PlaythroughStatsFromJSON(json['stats']),
  }
}

export function UserRunsDataToJSON(value?: UserRunsData | null): any {
  if (value == null) {
    return value
  }
  return {
    endedAt: value['endedAt'],
    score: value['score'],
    stats: PlaythroughStatsToJSON(value['stats']),
  }
}
