import * as React_2 from 'react'
import { CSSProperties, FC } from 'react'
import styled, { DefaultTheme } from 'styled-components'
import { useIntl } from 'react-intl'
import { FormatXMLElementFn, PrimitiveType } from 'intl-messageformat'
import { prop } from 'styled-tools'
import { fontWeight, FontWeightProps, textAlign, TextAlignProps } from 'styled-system'

type Variant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p' | 'span'
// TODO intelligent headers, just spec header and it will choose depending on the DOM sturcture, was H1 used above? I'm gonna be H2.

type Props = {
  variant?: Variant
  size?: string | number
  padding?: CSSProperties['padding']
  color?: string | ((args: { theme: DefaultTheme }) => string)
  t?: string
  // values?: Record<string, any>
  values?: Record<string, PrimitiveType | React_2.ReactElement | FormatXMLElementFn<any, any>>
  onClick?: Function
} & TextAlignProps &
  FontWeightProps

const StyledText = styled('span').withConfig({
  shouldForwardProp: (prop) => !['size', 'color', 'textAlign', 'fontWeight'].includes(prop),
})<any>`
  font-size: ${({ size }) => size};
  color: ${({ color, theme }) => color ?? theme.palette.text.primary} !important;
  margin: 0;
  padding: ${prop('padding')};
  ${textAlign}
  ${fontWeight}
`

const normalizeSize = (size) => {
  if (typeof size === 'number') {
    // size in px
    return size / 16 + 'rem'
  } else {
    return size
  }
}

const defaultValues: Props['values'] = {
  // TODO: formats for <b>, <strong>, <i>, etc.
  b: (...chunks) => <b>{chunks}</b>,
}

const IntlText = ({ t, children, values }) => {
  const intl = useIntl()
  return (
    <>{intl.formatMessage({ id: t, defaultMessage: children as string }, { ...defaultValues, ...values })}</>
  )
}

export const Text: FC<Props> = ({ variant = 'p', t, children, values, size, padding, ...rest }) => {
  return (
    <StyledText as={variant} {...rest} size={normalizeSize(size)} padding={padding}>
      {t ? (
        <IntlText t={t} values={values}>
          {children}
        </IntlText>
      ) : (
        children
      )}
    </StyledText>
  )
}
