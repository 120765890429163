/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
/**
 *
 * @export
 * @interface BoxPlotData
 */
export interface BoxPlotData {
  /**
   *
   * @type {string}
   * @memberof BoxPlotData
   */
  levelId: string
  /**
   *
   * @type {any}
   * @memberof BoxPlotData
   */
  levelName?: any
  /**
   *
   * @type {Array<number>}
   * @memberof BoxPlotData
   */
  score: Array<number>
  /**
   *
   * @type {number}
   * @memberof BoxPlotData
   */
  min: number
  /**
   *
   * @type {number}
   * @memberof BoxPlotData
   */
  max: number
  /**
   *
   * @type {number}
   * @memberof BoxPlotData
   */
  count: number
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof BoxPlotData
   */
  scoreCounts: { [key: string]: number }
  /**
   *
   * @type {number}
   * @memberof BoxPlotData
   */
  median: number
  /**
   *
   * @type {number}
   * @memberof BoxPlotData
   */
  avg: number
  /**
   *
   * @type {Array<number>}
   * @memberof BoxPlotData
   */
  pdfX: Array<number>
  /**
   *
   * @type {Array<number>}
   * @memberof BoxPlotData
   */
  pdfY: Array<number>
  /**
   *
   * @type {Array<number>}
   * @memberof BoxPlotData
   */
  quantiles: Array<number>
}

/**
 * Check if a given object implements the BoxPlotData interface.
 */
export function instanceOfBoxPlotData(value: object): boolean {
  if (!('levelId' in value)) return false
  if (!('score' in value)) return false
  if (!('min' in value)) return false
  if (!('max' in value)) return false
  if (!('count' in value)) return false
  if (!('scoreCounts' in value)) return false
  if (!('median' in value)) return false
  if (!('avg' in value)) return false
  if (!('pdfX' in value)) return false
  if (!('pdfY' in value)) return false
  if (!('quantiles' in value)) return false
  return true
}

export function BoxPlotDataFromJSON(json: any): BoxPlotData {
  return BoxPlotDataFromJSONTyped(json, false)
}

export function BoxPlotDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): BoxPlotData {
  if (json == null) {
    return json
  }
  return {
    levelId: json['levelId'],
    levelName: json['levelName'] == null ? undefined : json['levelName'],
    score: json['score'],
    min: json['min'],
    max: json['max'],
    count: json['count'],
    scoreCounts: json['scoreCounts'],
    median: json['median'],
    avg: json['avg'],
    pdfX: json['pdfX'],
    pdfY: json['pdfY'],
    quantiles: json['quantiles'],
  }
}

export function BoxPlotDataToJSON(value?: BoxPlotData | null): any {
  if (value == null) {
    return value
  }
  return {
    levelId: value['levelId'],
    levelName: value['levelName'],
    score: value['score'],
    min: value['min'],
    max: value['max'],
    count: value['count'],
    scoreCounts: value['scoreCounts'],
    median: value['median'],
    avg: value['avg'],
    pdfX: value['pdfX'],
    pdfY: value['pdfY'],
    quantiles: value['quantiles'],
  }
}
