export enum Severity {
  success = 'success',
  invalidEmail = 'invalidEmail',
  userDisabled = 'userDisabled',
  userNotFound = 'userNotFound',
  invalidCredentials = 'invalidCredentials',
  invalidVerificationCode = 'invalidVerificationCode',
  default = 'default',
}

export const errorMapper = {
  'auth/user-not-found': Severity.userNotFound,
  'auth/user-disabled': Severity.userDisabled,
  'auth/invalid-email': Severity.invalidEmail,
  'auth/wrong-password': Severity.invalidCredentials,
  'auth/invalid-verification-code': Severity.invalidVerificationCode,
}
