import { ReactNode } from 'react'
import styled from 'styled-components'
import { Flex, Text } from '../atoms'
import { palette } from 'styled-tools'

const Styles = styled.div<{ selectable: boolean }>`
  background: ${palette('background', 1)};
  border-radius: 5px;
  overflow: hidden;
  border-bottom: 1px solid ${palette('background', 2)};
  box-shadow: ${palette('shadow')};
  height: 100%;

  table {
    border-spacing: 0;
    width: 100%;
    background: ${palette('background', 2)};
    tbody tr {
      cursor: ${({ selectable }) => (selectable ? 'pointer' : 'default')};
      :hover {
        background: ${palette('background', 3)};
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      text-align: left;
    }
  }
`

type TableColumn<Model> = {
  header: string
  render: (model: Model) => ReactNode
  stopImmediatePropagation?: boolean
}

type Props<Model extends object> = {
  columns: Array<TableColumn<Model>>
  data: Model[]
  onRowClick?: (model: Model) => void
  name?: string
  header?: ReactNode
}

/**
 * @deprecated use material-table
 */
export function Table<D extends object>({ columns, data, onRowClick, name, header }: Props<D>) {
  function handleRowClick(row) {
    onRowClick?.(row)
  }

  return (
    <Styles selectable={!!onRowClick}>
      <Flex padding="20px" justifyContent="flex-end" gap="20px">
        {header}
      </Flex>
      <Flex background={palette('background', 2)} height="100%">
        <table>
          <thead>
            <tr>
              {columns.map((column) => (
                <th key={column.header}>
                  <Text t={name ? `${name}.${column.header}` : `common.${column.header}`} />
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => {
              return (
                <tr key={row['id'] ?? index}>
                  {columns.map((cell, index) => (
                    <td
                      key={index}
                      onClick={cell.stopImmediatePropagation ? undefined : () => handleRowClick(row)}
                    >
                      {cell.render(row)}
                    </td>
                  ))}
                </tr>
              )
            })}
          </tbody>
        </table>
      </Flex>
    </Styles>
  )
}
