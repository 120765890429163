export async function loadTranslations(locale: string) {
  switch (locale) {
    case 'cs':
      return import('./cs.json')
    case 'en':
      return import('./en.json')
    case 'pl':
      return import('./pl.json')
    case 'is':
      return import('./is.json')
    case 'nl':
      return import('./nl.json')
    default:
      return import('./cs.json')
  }
}
