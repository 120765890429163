import { createTheme, Paper, ThemeProvider as MuiThemeProvider, Typography } from '@mui/material'
import { Flex, lightMuiThemeOptions } from '@proxyqb/ui'
import { FC } from 'react'
import { ThemeProvider } from 'styled-components'
import { PublicTemplate } from '../../../shared'
import { DoctorRegistrationForm } from './doctor-registration.form'
import { useIntl } from 'react-intl'

interface Props {}

const theme = createTheme(lightMuiThemeOptions)

export const DoctorRegistrationScreen: FC<Props> = () => {
  const { formatMessage } = useIntl()

  return (
    <PublicTemplate>
      <Flex
        justifyContent="center"
        position="absolute"
        alignItems="center"
        height="80%"
        width="calc(100% - 16px)"
      >
        <MuiThemeProvider theme={theme}>
          <ThemeProvider theme={theme}>
            <Flex
              as={Paper}
              elevation={1}
              padding="20px"
              background={theme.palette.background.paper}
              flexDirection="column"
              gap="16px"
              maxWidth="1200px"
            >
              <Typography sx={{ fontSize: { xs: '30px', md: '36px' } }}>
                {formatMessage({ id: 'doctorForm.title' })}
              </Typography>
              <DoctorRegistrationForm />
            </Flex>
          </ThemeProvider>
        </MuiThemeProvider>
      </Flex>
    </PublicTemplate>
  )
}
