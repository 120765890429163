import { Typography } from '@mui/material'
import { useGlobalState } from '@proxyqb/portal-global-state'
import {
  AbilityActions,
  AbilitySubjectKeys,
  AbilitySubjects,
  abilitySubjectTypes,
  AbilitySubjectTypes,
  AppAbility,
} from '@proxyqb/rights'
import { LoadingTemplate } from '@proxyqb/ui'
import { ComponentType, FC } from 'react'
import { useIntl } from 'react-intl'
import { Navigate, useParams } from 'react-router-dom'
import { useAbilityContext } from './ability-context'

export interface PrivateRouteProps {
  Component: ComponentType
  abilityParams?: {
    action: AbilityActions
    subject: AbilitySubjects | AbilitySubjects[]
    objectKey?: AbilitySubjectKeys
    paramKey?: string
  }
}

const isSubjectType = (subject: AbilitySubjects): subject is AbilitySubjectTypes =>
  abilitySubjectTypes.includes(subject.toString() as AbilitySubjectTypes)

const checkAbilityAccess = (
  ability: AppAbility,
  abilityParams: PrivateRouteProps['abilityParams'],
  params: Record<string, any>,
) => {
  if (abilityParams) {
    const checkAbility = (subject: AbilitySubjects) => {
      if (abilityParams.objectKey && isSubjectType(subject)) {
        return ability.can(abilityParams.action, {
          __typename: subject,
          [abilityParams.objectKey]: params[abilityParams.paramKey || abilityParams.objectKey],
        })
      }

      return ability.can(abilityParams.action, subject)
    }

    if (Array.isArray(abilityParams.subject)) {
      return abilityParams.subject.some(checkAbility)
    }

    return checkAbility(abilityParams.subject)
  }

  return false
}

export const PrivateRoute: FC<PrivateRouteProps> = ({ abilityParams, Component }) => {
  const params = useParams()
  const ability = useAbilityContext()
  const { formatMessage } = useIntl()
  const { authState } = useGlobalState()
  const loggedIn = authState.matches('loggedIn')
  const checkingIfLoggedIn = authState.matches('checkingIfLoggedIn')

  if (checkingIfLoggedIn) {
    return <LoadingTemplate />
  }

  const { userDetails } = authState.context
  const isUserFetched = !!userDetails
  const canAccess = checkAbilityAccess(ability, abilityParams, params)

  if (!abilityParams || (isUserFetched && canAccess)) {
    if (loggedIn) {
      return <Component />
    } else {
      return (
        <Navigate
          to={{
            pathname: '/login',
          }}
          state={{ returnTo: location }}
        />
      )
    }
  } else {
    return <Typography>{formatMessage({ id: 'shared.insufficientRole' })}</Typography>
  }
}
