export async function loadSharedTranslations(locale: string) {
  switch (locale) {
    case 'cs':
      return import('./cs.json')
    case 'en':
      return import('./en.json')
    case 'pl':
      return import('./pl.json')
    case 'is':
      return import('./is.json')
    case 'nl':
      return import('./nl.json')
    default:
      return import('./cs.json')
  }
}

function convertTranslationsToPairs(obj) {
  if (typeof obj === 'string') {
    return [{ key: '', value: obj }]
  }
  const entries = Object.entries(obj).flatMap(([key, obj]) => {
    const stuff = convertTranslationsToPairs(obj)
    return stuff.flatMap(({ key: deeperKey, value }) => {
      const add = deeperKey ? '.' + deeperKey : deeperKey
      return { key: key + add, value }
    })
  })
  return entries
}

export function flattenTranslations(obj) {
  const pairs = convertTranslationsToPairs(obj)
  return pairs.reduce(
    (acc, { key, value }) => ({
      ...acc,
      [key]: value,
    }),
    {},
  )
}
