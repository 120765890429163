import { Checkbox, FormControlLabel } from '@mui/material'
import { ReactElement, useRef } from 'react'
import { useIntl } from 'react-intl'
import { AccessRightType } from '@proxyqb/graphql-api-types'

interface Props {
  accessRights: AccessRightType[]
  filter?: AccessRightType[]
  disable?: AccessRightType[]
  onChange?(types: AccessRightType[], checked: boolean)
}

export const AccessRightCheckboxes = ({ accessRights, filter, onChange, disable }: Props): ReactElement => {
  const { formatMessage } = useIntl()
  const accessRightCheckboxes = [
    { type: AccessRightType.ReadDocument, label: formatMessage({ id: 'shared.readDocument' }) },
    { type: AccessRightType.UpdateDocument, label: formatMessage({ id: 'shared.updateDocument' }) },
    { type: AccessRightType.RemoveDocument, label: formatMessage({ id: 'shared.removeDocument' }) },
    { type: AccessRightType.UpdateRights, label: formatMessage({ id: 'shared.updateRights' }) },
  ].filter((it) => (filter ? filter?.includes(it.type) : true))
  const handleChange = (type: AccessRightType) => (_, checked) => {
    const changedIndex = accessRightCheckboxes.findIndex((it) => it.type === type)
    if (checked) {
      const changes: AccessRightType[] = []
      for (let index = 0; index <= changedIndex; index++) {
        const type = accessRightCheckboxes[index].type
        if (!accessRights.includes(type)) {
          changes.push(type)
        }
      }
      onChange?.(changes, true)
    } else {
      onChange?.([type], false)
    }
  }
  const isAllDisabled = !onChange

  return (
    <>
      {accessRightCheckboxes.map(({ type, label }, index, all) => {
        const nextRight = all[index + 1]
        const isDisabled = nextRight ? accessRights.includes(nextRight.type) : false
        const inputRef = useRef<HTMLInputElement>()
        return (
          <FormControlLabel
            inputRef={inputRef}
            key={type}
            control={<Checkbox checked={accessRights.includes(type)} />}
            label={label}
            labelPlacement="start"
            disabled={isAllDisabled || isDisabled || (disable?.includes(type) && inputRef.current?.checked)}
            onChange={handleChange(type)}
          />
        )
      })}
    </>
  )
}
