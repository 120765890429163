import { FetchParams, Middleware, ResponseContext } from './generated'
import { getAuth, User } from 'firebase/auth'

function getCurrentUser(auth): Promise<User> {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      unsubscribe()
      resolve(user)
    }, reject)
  })
}

const getToken = async () => {
  const user = await getCurrentUser(getAuth())
  return await user.getIdToken()
}

export class ApiMiddleware implements Middleware {
  public async pre(context: ResponseContext): Promise<FetchParams | void> {
    const accessToken = await getToken()
    return {
      url: context.url,
      init: {
        ...context.init,
        headers: new Headers({
          ...context.init.headers,
          Authorization: accessToken,
        }),
      },
    }
  }

  public post(context: ResponseContext): Promise<Response | void> {
    return Promise.resolve(context.response)
  }
}
