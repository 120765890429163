/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
/**
 *
 * @export
 * @interface Density
 */
export interface Density {
  /**
   *
   * @type {Array<number>}
   * @memberof Density
   */
  x: Array<number>
  /**
   *
   * @type {Array<number>}
   * @memberof Density
   */
  y: Array<number>
}

/**
 * Check if a given object implements the Density interface.
 */
export function instanceOfDensity(value: object): boolean {
  if (!('x' in value)) return false
  if (!('y' in value)) return false
  return true
}

export function DensityFromJSON(json: any): Density {
  return DensityFromJSONTyped(json, false)
}

export function DensityFromJSONTyped(json: any, ignoreDiscriminator: boolean): Density {
  if (json == null) {
    return json
  }
  return {
    x: json['x'],
    y: json['y'],
  }
}

export function DensityToJSON(value?: Density | null): any {
  if (value == null) {
    return value
  }
  return {
    x: value['x'],
    y: value['y'],
  }
}
