import { yupResolver } from '@hookform/resolvers/yup'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { Autocomplete, Form, Input, Submit } from '@proxyqb/ui-forms'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useDebounce, useEffectOnce, useMap } from 'react-use'
import { InferType, object, string } from 'yup'
import { AccessRightType } from '@proxyqb/graphql-api-types'
import { AccessRightCheckboxes } from '../components'
import { useAddAccessRightsMutation, useAutocompleteGroupsQuery } from '../shared-queries.generated'

export const formSchema = object({
  search: string().optional(),
  group: object().required(),
})
  .required()
  .default({
    group: undefined,
    search: '',
  })

export type FormFields = InferType<typeof formSchema>
const resolver = yupResolver(formSchema)

export const AddDoctorGroupForm = ({
  id,
  refreshData,
  title,
}: {
  id: string
  refreshData()
  title?: string
}) => {
  const { formatMessage } = useIntl()
  const { enqueueSnackbar } = useSnackbar()
  const [open, setOpen] = useState(false)
  const [rights, rightsActions] = useMap({
    [AccessRightType.ReadDocument]: true,
    [AccessRightType.UpdateDocument]: false,
    [AccessRightType.RemoveDocument]: false,
    [AccessRightType.UpdateRights]: false,
  })
  const checkedRights = Object.entries(rights)
    .filter(([, checked]) => checked)
    .map(([key]) => key as AccessRightType)
  const form = useForm<FormFields>({
    defaultValues: formSchema.getDefault(),
    resolver,
  })
  const search = useWatch({ name: 'search', control: form.control })
  const [{ data, fetching }, autocompleteGroups] = useAutocompleteGroupsQuery({
    variables: {
      search: search!,
      filter: {
        collectionName: {
          equals: 'doctors',
        },
      },
    },
    pause: true,
  })
  useDebounce(
    () => {
      autocompleteGroups()
    },
    500,
    [search],
  )
  useEffectOnce(autocompleteGroups)
  const [, addAccessRights] = useAddAccessRightsMutation()
  const createAccessRules = async ({ group }) => {
    const result = await addAccessRights({
      from: group.id,
      to: id,
      accessRights: Object.entries(rights)
        .filter(([, checked]) => checked)
        .map(([key]) => key as AccessRightType),
    })
    if (result.error) {
      enqueueSnackbar(result.error.message, {
        variant: 'error',
      })
    } else {
      enqueueSnackbar(formatMessage({ id: 'patient.detail.doctors.accessAdded' }), {
        variant: 'success',
        autoHideDuration: 1000,
      })
    }
    refreshData()
    setOpen(false)
  }

  const handleRightsChange = (types: AccessRightType[], checked: boolean) => {
    types.forEach((type) => {
      rightsActions.set(type, checked)
    })
  }

  return (
    <>
      <Button variant="contained" onClick={() => setOpen(true)}>
        {formatMessage({ id: 'addPatientRightsToDoctorGroupForm.submit' })}
      </Button>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <Form
          name="addPatientRightsToDoctorGroupForm"
          onSubmit={createAccessRules}
          form={form}
          schema={formSchema}
        >
          <DialogTitle>
            {title || formatMessage({ id: 'addPatientRightsToDoctorGroupForm.title' })}
          </DialogTitle>
          <DialogContent>
            <Autocomplete
              filterOptions={(x) => x}
              name="group"
              sx={{ width: 300, mt: 1 }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.name.local}
              options={fetching ? [] : data?.autocompleteGroups ?? []}
              loading={fetching}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  {option.name.local}
                </li>
              )}
              renderInput={(params) => (
                <Input
                  name="search"
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {fetching ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                  helperText={formatMessage({ id: 'addPatientRightsToDoctorGroupForm.searchHelperText' })}
                />
              )}
            />

            <AccessRightCheckboxes
              accessRights={checkedRights}
              onChange={handleRightsChange}
              disable={['readDocument']}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)}>{formatMessage({ id: 'shared.cancel' })}</Button>
            <Submit />
          </DialogActions>
        </Form>
      </Dialog>
    </>
  )
}
