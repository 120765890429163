import { ReactElement } from 'react'
import { DashboardWidget, DataGrid } from '@proxyqb/ui'

import { useIntl } from 'react-intl'
import { Box, Typography } from '@mui/material'
import { format } from 'date-fns'
import { apiClient } from '@proxyqb/gaia-api-client'
import { useQuery } from 'react-query'

export const LastPlaythroughsWidget = (): ReactElement => {
  const { data, isFetching } = useQuery('getLastPlaythroughs', () => apiClient.getLastPlaythroughs())
  const intl = useIntl()

  return (
    <DashboardWidget loading={isFetching} sx={{ minWidth: '800px', minHeight: '700px' }}>
      <Typography fontSize={32}>{intl.formatMessage({ id: 'dashboard.lastPlayed' })}</Typography>

      <Box sx={{ height: '600px', mt: 2 }}>
        <DataGrid
          rows={[]}
          columns={[
            {
              field: 'patient',
              valueGetter: ({ row }) => {
                return `${row?.user?.firstName} ${row?.user?.lastName}`
              },
              headerName: intl.messages['patient.patient'] as string,
              width: 200,
              sortable: false,
              disableColumnMenu: true,
            },
            {
              field: 'level',
              valueGetter: ({ row }) => {
                return row?.level?.name.local
              },
              headerName: intl.messages['level.name'] as string,
              width: 350,
              sortable: false,
              disableColumnMenu: true,
            },
            {
              field: 'endedAt',
              valueGetter: ({ row }) => {
                return format(new Date(row?.endedAt), 'HH:mm yyyy-MM-dd')
              },
              headerName: intl.messages['level.endedAt'] as string,
              width: 250,
              sortable: false,
              disableColumnMenu: true,
            },
          ]}
          hideFooter
        />
      </Box>
    </DashboardWidget>
  )
}
