import { Button, Paper } from '@mui/material'
import { ButtonBar, DataGrid } from '@proxyqb/ui'
import { GridColDef } from '@mui/x-data-grid'
import { useIntl } from 'react-intl'
import { useGetFirmwareQuery } from './firmware.generated'
import { AddFirmwareForm } from './add-firmware-form'

export const FirmwareScreen = () => {
  const { formatMessage } = useIntl()
  const [{ data, fetching }, refreshData] = useGetFirmwareQuery()

  const columns: GridColDef[] = [
    {
      field: 'name',
      valueGetter: ({ row }) => row.name,
      headerName: formatMessage({ id: 'firmware.name' }),
      sortable: false,
      flex: 1,
      minWidth: 80,
    },
    {
      field: 'version',
      valueGetter: ({ row }) => row.version,
      headerName: formatMessage({ id: 'firmware.version' }),
      sortable: false,
      flex: 1,
      minWidth: 80,
    },
    {
      field: 'update',
      headerName: `${formatMessage({ id: 'firmware.source' })}`,
      sortable: false,
      filterable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Button component={'a'} variant="contained" href={params.row.url} target="blank">
            {formatMessage({ id: 'firmware.download' })}
          </Button>
        )
      },
      minWidth: 200,
    },
  ]

  return (
    <>
      <ButtonBar>
        <AddFirmwareForm refreshData={refreshData} />
      </ButtonBar>
      <Paper sx={{ height: '100%' }}>
        <DataGrid
          rows={data?.getFirmware || []}
          columns={columns}
          disableSelectionOnClick
          getRowId={(row) => row.version}
          loading={fetching}
        />
      </Paper>
    </>
  )
}
