import { Box, CircularProgress, Grid, Paper, Skeleton, SxProps, Theme } from '@mui/material'
import { FC, PropsWithChildren } from 'react'
import { GridItemProps } from './Grid'

interface Props {
  loading?: boolean
  xs?: number
  itemProps?: GridItemProps
  sx?: SxProps<Theme>
}

const LoadingSkeleton = () => {
  return (
    <Box width={'100%'} height={'100%'}>
      <Skeleton height={28} sx={{ marginBottom: '10px' }} />
      <Skeleton sx={{ my: '8px' }} />
      <Skeleton sx={{ my: '8px' }} />
      <Skeleton sx={{ my: '8px' }} />
      <Skeleton sx={{ my: '8px' }} />
      <Skeleton sx={{ my: '8px' }} />
      <Skeleton sx={{ my: '8px' }} />
    </Box>
  )
}

export const DashboardWidget: FC<PropsWithChildren<Props>> = ({ loading, children, sx, itemProps }) => {
  return (
    <Grid item {...itemProps}>
      <Paper
        sx={[{ minWidth: '100px', minHeight: '100px', p: 2 }, ...(Array.isArray(sx) ? sx! : [sx || {}])]}
      >
        {loading ? <LoadingSkeleton /> : children}
      </Paper>
    </Grid>
  )
}
