/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { PlansLevelsStatistic } from './PlansLevelsStatistic'
import {
  PlansLevelsStatisticFromJSON,
  PlansLevelsStatisticFromJSONTyped,
  PlansLevelsStatisticToJSON,
} from './PlansLevelsStatistic'
import type { RehabPlanStats } from './RehabPlanStats'
import { RehabPlanStatsFromJSON, RehabPlanStatsFromJSONTyped, RehabPlanStatsToJSON } from './RehabPlanStats'

/**
 *
 * @export
 * @interface RehabPlanStatistic
 */
export interface RehabPlanStatistic {
  /**
   *
   * @type {Array<PlansLevelsStatistic>}
   * @memberof RehabPlanStatistic
   */
  plansLevelsStatistic: Array<PlansLevelsStatistic>
  /**
   *
   * @type {Array<RehabPlanStats>}
   * @memberof RehabPlanStatistic
   */
  rehabPlanStats: Array<RehabPlanStats>
}

/**
 * Check if a given object implements the RehabPlanStatistic interface.
 */
export function instanceOfRehabPlanStatistic(value: object): boolean {
  if (!('plansLevelsStatistic' in value)) return false
  if (!('rehabPlanStats' in value)) return false
  return true
}

export function RehabPlanStatisticFromJSON(json: any): RehabPlanStatistic {
  return RehabPlanStatisticFromJSONTyped(json, false)
}

export function RehabPlanStatisticFromJSONTyped(json: any, ignoreDiscriminator: boolean): RehabPlanStatistic {
  if (json == null) {
    return json
  }
  return {
    plansLevelsStatistic: (json['plansLevelsStatistic'] as Array<any>).map(PlansLevelsStatisticFromJSON),
    rehabPlanStats: (json['rehabPlanStats'] as Array<any>).map(RehabPlanStatsFromJSON),
  }
}

export function RehabPlanStatisticToJSON(value?: RehabPlanStatistic | null): any {
  if (value == null) {
    return value
  }
  return {
    plansLevelsStatistic: (value['plansLevelsStatistic'] as Array<any>).map(PlansLevelsStatisticToJSON),
    rehabPlanStats: (value['rehabPlanStats'] as Array<any>).map(RehabPlanStatsToJSON),
  }
}
