import * as Types from '@proxyqb/graphql-api-types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export const CreateDocumentGroupDocument = gql`
    mutation createDocumentGroup($input: DocumentGroupInput!) {
  createDocumentGroup(input: $input) {
    id
  }
}
    `;

export function useCreateDocumentGroupMutation() {
  return Urql.useMutation<CreateDocumentGroupMutation, CreateDocumentGroupMutationVariables>(CreateDocumentGroupDocument);
};
export const AddDocumentToGroupDocument = gql`
    mutation addDocumentToGroup($documentId: ID!, $groupId: ID!) {
  addDocumentToGroup(documentId: $documentId, groupId: $groupId)
}
    `;

export function useAddDocumentToGroupMutation() {
  return Urql.useMutation<AddDocumentToGroupMutation, AddDocumentToGroupMutationVariables>(AddDocumentToGroupDocument);
};
export const RemoveDocumentFromGroupDocument = gql`
    mutation removeDocumentFromGroup($documentId: ID!, $groupId: ID!) {
  removeDocumentFromGroup(documentId: $documentId, groupId: $groupId)
}
    `;

export function useRemoveDocumentFromGroupMutation() {
  return Urql.useMutation<RemoveDocumentFromGroupMutation, RemoveDocumentFromGroupMutationVariables>(RemoveDocumentFromGroupDocument);
};
export const RenameDocumentGroupDocument = gql`
    mutation renameDocumentGroup($input: RenameDocumentGroupInput!) {
  renameDocumentGroup(input: $input) {
    id
  }
}
    `;

export function useRenameDocumentGroupMutation() {
  return Urql.useMutation<RenameDocumentGroupMutation, RenameDocumentGroupMutationVariables>(RenameDocumentGroupDocument);
};
export const RemoveDocumentGroupDocument = gql`
    mutation removeDocumentGroup($groupId: ID!) {
  removeDocumentGroup(groupId: $groupId)
}
    `;

export function useRemoveDocumentGroupMutation() {
  return Urql.useMutation<RemoveDocumentGroupMutation, RemoveDocumentGroupMutationVariables>(RemoveDocumentGroupDocument);
};
export const AddAccessRightsDocument = gql`
    mutation addAccessRights($from: ID!, $to: ID!, $accessRights: [AccessRightType!]!) {
  addAccessRights(from: $from, to: $to, accessRights: $accessRights)
}
    `;

export function useAddAccessRightsMutation() {
  return Urql.useMutation<AddAccessRightsMutation, AddAccessRightsMutationVariables>(AddAccessRightsDocument);
};
export const RemoveAccessRightDocument = gql`
    mutation removeAccessRight($from: ID!, $to: ID!, $accessRight: [AccessRightType!]!) {
  removeAccessRight(from: $from, to: $to, accessRight: $accessRight)
}
    `;

export function useRemoveAccessRightMutation() {
  return Urql.useMutation<RemoveAccessRightMutation, RemoveAccessRightMutationVariables>(RemoveAccessRightDocument);
};
export const AutocompleteDoctorsDocument = gql`
    query autocompleteDoctors($search: String!) {
  autocompleteDoctors(search: $search) {
    id
    name
  }
}
    `;

export function useAutocompleteDoctorsQuery(options: Omit<Urql.UseQueryArgs<AutocompleteDoctorsQueryVariables>, 'query'>) {
  return Urql.useQuery<AutocompleteDoctorsQuery, AutocompleteDoctorsQueryVariables>({ query: AutocompleteDoctorsDocument, ...options });
};
export const PublicDoctorsDocument = gql`
    query publicDoctors {
  publicDoctors {
    id
    name
  }
}
    `;

export function usePublicDoctorsQuery(options?: Omit<Urql.UseQueryArgs<PublicDoctorsQueryVariables>, 'query'>) {
  return Urql.useQuery<PublicDoctorsQuery, PublicDoctorsQueryVariables>({ query: PublicDoctorsDocument, ...options });
};
export const AutocompleteGroupsDocument = gql`
    query autocompleteGroups($search: String!, $filter: PublicGroupsFilter) {
  autocompleteGroups(search: $search, filter: $filter) {
    id
    name {
      local
    }
  }
}
    `;

export function useAutocompleteGroupsQuery(options: Omit<Urql.UseQueryArgs<AutocompleteGroupsQueryVariables>, 'query'>) {
  return Urql.useQuery<AutocompleteGroupsQuery, AutocompleteGroupsQueryVariables>({ query: AutocompleteGroupsDocument, ...options });
};
export const PublicGroupsDocument = gql`
    query publicGroups($filter: PublicGroupsFilter) {
  publicGroups(filter: $filter) {
    id
    name {
      local
    }
  }
}
    `;

export function usePublicGroupsQuery(options?: Omit<Urql.UseQueryArgs<PublicGroupsQueryVariables>, 'query'>) {
  return Urql.useQuery<PublicGroupsQuery, PublicGroupsQueryVariables>({ query: PublicGroupsDocument, ...options });
};
export type CreateDocumentGroupMutationVariables = Types.Exact<{
  input: Types.DocumentGroupInput;
}>;


export type CreateDocumentGroupMutation = { __typename: 'Mutation', createDocumentGroup?: { __typename: 'DocumentGroup', id: string } | null };

export type AddDocumentToGroupMutationVariables = Types.Exact<{
  documentId: Types.Scalars['ID'];
  groupId: Types.Scalars['ID'];
}>;


export type AddDocumentToGroupMutation = { __typename: 'Mutation', addDocumentToGroup?: any | null };

export type RemoveDocumentFromGroupMutationVariables = Types.Exact<{
  documentId: Types.Scalars['ID'];
  groupId: Types.Scalars['ID'];
}>;


export type RemoveDocumentFromGroupMutation = { __typename: 'Mutation', removeDocumentFromGroup?: any | null };

export type RenameDocumentGroupMutationVariables = Types.Exact<{
  input: Types.RenameDocumentGroupInput;
}>;


export type RenameDocumentGroupMutation = { __typename: 'Mutation', renameDocumentGroup?: { __typename: 'DocumentGroup', id: string } | null };

export type RemoveDocumentGroupMutationVariables = Types.Exact<{
  groupId: Types.Scalars['ID'];
}>;


export type RemoveDocumentGroupMutation = { __typename: 'Mutation', removeDocumentGroup?: any | null };

export type AddAccessRightsMutationVariables = Types.Exact<{
  from: Types.Scalars['ID'];
  to: Types.Scalars['ID'];
  accessRights: Array<Types.AccessRightType> | Types.AccessRightType;
}>;


export type AddAccessRightsMutation = { __typename: 'Mutation', addAccessRights?: any | null };

export type RemoveAccessRightMutationVariables = Types.Exact<{
  from: Types.Scalars['ID'];
  to: Types.Scalars['ID'];
  accessRight: Array<Types.AccessRightType> | Types.AccessRightType;
}>;


export type RemoveAccessRightMutation = { __typename: 'Mutation', removeAccessRight?: any | null };

export type AutocompleteDoctorsQueryVariables = Types.Exact<{
  search: Types.Scalars['String'];
}>;


export type AutocompleteDoctorsQuery = { __typename: 'Query', autocompleteDoctors: Array<{ __typename: 'PublicDoctor', id: string, name: string }> };

export type PublicDoctorsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PublicDoctorsQuery = { __typename: 'Query', publicDoctors: Array<{ __typename: 'PublicDoctor', id: string, name: string }> };

export type AutocompleteGroupsQueryVariables = Types.Exact<{
  search: Types.Scalars['String'];
  filter?: Types.InputMaybe<Types.PublicGroupsFilter>;
}>;


export type AutocompleteGroupsQuery = { __typename: 'Query', autocompleteGroups: Array<{ __typename: 'PublicGroup', id: string, name: { __typename: 'LocalizedText', local: string } }> };

export type PublicGroupsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.PublicGroupsFilter>;
}>;


export type PublicGroupsQuery = { __typename: 'Query', publicGroups: Array<{ __typename: 'PublicGroup', id: string, name: { __typename: 'LocalizedText', local: string } }> };
