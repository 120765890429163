import { deepmerge } from '@mui/utils'
import { ThemeOptions } from '@mui/material'

export const baseMuiThemeOptions: ThemeOptions = {
  typography: {
    fontSize: 14,
    body1: {
      fontSize: '14px',
    },
  },

  // status: {
  //   danger: red[500],
  // },
  // shadows: shadows as any,
  // overrides: {
  //   MuiPaper: {
  //     rounded: {
  //       borderRadius: '8px',
  //     },
  //   },
  //   MuiTable: {
  //     root: {
  //       tableLayout: 'fixed',
  //     },
  //   },
  //
  //   MuiTableCell: {
  //     head: {
  //       fontSize: '13px',
  //       padding: '12px 0px',
  //     },
  //     root: {
  //       fontSize: '14px',
  //       whiteSpace: 'pre-wrap',
  //       padding: '8px 0px',
  //     },
  //   },
  //
  //   MuiExpansionPanelSummary: {
  //     content: {
  //       margin: '0px',
  //     },
  //   },
  //
  //   MuiButton: {
  //     root: {
  //       fontSize: '14px',
  //       textTransform: 'none',
  //       fontWeight: 400,
  //     },
  //     contained: {
  //       boxShadow: shadows[8],
  //     },
  //   },
  //   MuiFab: {
  //     root: {
  //       boxShadow: shadows[12],
  //     },
  //   },
  //   MuiCard: {
  //     root: {
  //       borderRadius: '8px',
  //     },
  //   },
  //   MuiTooltip: {
  //     tooltip: {
  //       fontSize: '11px',
  //     },
  //   },
  //   MuiMenuItem: {
  //     root: {
  //       fontSize: '0.875rem',
  //     },
  //   },
  // },
}

const textLight = {
  primary: 'rgba(52, 49, 76, 1)',
  secondary: 'rgba(52, 49, 76, 0.54)',
  disabled: 'rgba(52, 49, 76, 0.38)',
  hint: 'rgba(52, 49, 76, 0.38)',
}

const light: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#7467ef',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#7467ef',
      contrastText: textLight.primary,
    },
    text: textLight,
    background: {
      paper: '#e7e7e7',
    },
  },
}
const dark: ThemeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#7467ef',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#f9a352',
      main: '#ff9e43',
      dark: '#ff932e',
      contrastText: '#ffffff',
    },
    text: {
      primary: '#ffffff',
      secondary: '#ffffff',
    },
    divider: '#132F4C',
    background: {
      default: '#001E3C',
      paper: '#0A1929',
    },
    common: {
      black: '#1D1D1D',
      white: '#fff',
    },
  },
}

export const lightMuiThemeOptions: ThemeOptions = deepmerge(baseMuiThemeOptions, light)
export const darkMuiThemeOptions: ThemeOptions = deepmerge(baseMuiThemeOptions, dark)
