import { FC } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Form, PasswordInput, Submit } from '@proxyqb/ui-forms'
import { Box } from '@mui/material'
import { useForm } from 'react-hook-form'
import { PASSWORD_LENGTH } from '../../../../apps/portal-fe/src/app/doctors/form/doctor-form-schema'
import { object, string } from 'yup'

const setPasswordFormSchema = object({
  newPassword: string().min(PASSWORD_LENGTH).matches(/[0-9]/).required(),
}).required()

type NewPasswordFields = {
  newPassword: string
}

type Props = {
  onSubmit: (newPassword: string) => Promise<void>
}

export const SetPasswordForm: FC<Props> = ({ onSubmit }) => {
  const setpasswordform = useForm<NewPasswordFields>({
    defaultValues: { newPassword: '' },
    resolver: yupResolver(setPasswordFormSchema),
  })
  return (
    <Form
      form={setpasswordform}
      name="setPasswordForm"
      schema={setPasswordFormSchema}
      onSubmit={({ newPassword }) => {
        onSubmit(newPassword)
      }}
    >
      <PasswordInput name="newPassword" />
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Submit />
      </Box>
    </Form>
  )
}
