import { ReactNode } from 'react'
import { Control, FieldErrors, UseFormReturn } from 'react-hook-form'
import { createCtx } from './create-ctx'
import { ObjectSchema } from 'yup'

type Props<T extends Record<string, any>> = {
  errors: FieldErrors<T>
  isSubmitting?: boolean
  children: ReactNode
  formName: string
  /** @deprecated */
  control: Control<T>
  form: UseFormReturn<T>
  schema?: ObjectSchema<T>
}

const [useContext, FormInnerContextProvider] = createCtx<Omit<Props<any>, 'children'>>()
export const useFormInnerContext = useContext

export function FormInnerProvider<T>({
  children,
  errors,
  isSubmitting,
  control,
  formName,
  form,
  schema,
}: Props<T>) {
  return (
    <FormInnerContextProvider value={{ errors, isSubmitting, control, formName, form, schema }}>
      {children}
    </FormInnerContextProvider>
  )
}
