import {
  getAuth,
  RecaptchaVerifier,
  signInWithEmailAndPassword,
  signInWithPhoneNumber,
  updateCurrentUser,
  User as FirebaseUser,
} from 'firebase/auth'
import { gql } from 'urql'
import flagsmith from 'flagsmith'
import { assign, createMachine, Sender } from 'xstate'
import { createGraphqlClient } from './graphql-client'
import * as Sentry from '@sentry/browser'
import { User } from '@proxyqb/graphql-api-types'
import { getCurrentUser } from './get-current-user'

export type AuthenticationMachineContext = {
  userDetails?: UserDetails
  error?: FirebaseError
}

export interface UserDetails extends User {
  __typename: 'Doctor' | 'Patient'
}

interface FirebaseError {
  code: string
  customData: any
  name: string
  message: string
  stack: string
}

export type AuthenticationMachineEvent =
  | {
      type: 'REPORT_IS_LOGGED_IN'
      userDetails: UserDetails
    }
  | {
      type: 'REPORT_IS_LOGGED_OUT'
    }
  | {
      type: 'LOG_OUT'
    }
  | {
      type: 'UPDATE_USER'
    }
  | {
      type: 'LOG_IN'
      credentials: {
        email: string
        password: string
      }
    }
  | {
      type: 'SEND_LOGIN_CODE_TO_PHONE'
      phoneNumber: string
    }
  | {
      type: 'LOGIN_WITH_PHONE_CODE'
      code: string
    }

export type AuthenticationMachineTypestate =
  | {
      value: 'checkingIfLoggedIn'
      context: AuthenticationMachineContext & { userDetails: undefined }
    }
  | {
      value: 'loggedIn'
      context: AuthenticationMachineContext & { userDetails: UserDetails }
    }
  | {
      value: 'loggedOff'
      context: AuthenticationMachineContext & { userDetails: undefined }
    }

function createVerifier(): void {
  // @ts-ignore
  if (!window.recaptchaVerifier) {
    // @ts-ignore
    window.recaptchaVerifier = new RecaptchaVerifier(
      'sign-in-button',
      {
        size: 'invisible',
      },
      getAuth(),
    )
  }
  // @ts-ignore
  window.recaptchaVerifier.render()
}

/**
 * https://xstate-catalogue.com/machines/authentication
 */
export const authenticationMachine = createMachine<
  AuthenticationMachineContext,
  AuthenticationMachineEvent,
  AuthenticationMachineTypestate
>(
  {
    id: 'authentication',
    initial: 'checkingIfLoggedIn',
    states: {
      checkingIfLoggedIn: {
        invoke: {
          src: 'checkIfLoggedIn',
          onError: {
            target: 'loggedOut',
          },
        },
        on: {
          REPORT_IS_LOGGED_IN: {
            target: 'loggedIn',
            actions: 'assignUserDetailsToContext',
          },
          REPORT_IS_LOGGED_OUT: 'loggedOut',
        },
      },
      loggedIn: {
        on: {
          LOG_OUT: {
            target: 'loggedOut',
            actions: ['clearUserDetailsFromContext', 'clearErrorsFromContext', 'cleanFirebase'],
          },
          UPDATE_USER: {
            target: 'checkingIfLoggedIn',
          },
        },
      },
      loginIn: {
        invoke: {
          id: 'logIn',
          src: 'logIn',
          onError: {
            target: 'loggedOut',
            actions: assign({ error: (context, event) => event.data }),
          },
          onDone: {
            target: 'checkingIfLoggedIn',
          },
        },
      },
      sendLoginCodeToPhone: {
        invoke: {
          id: 'sendLoginCodeToPhone',
          src: 'sendLoginCodeToPhone',
          onError: {
            target: 'sendLoginCodeToPhone',
            actions: assign({ error: (context, event) => event.data }),
          },
        },
        on: {
          LOGIN_WITH_PHONE_CODE: {
            target: 'loginWithPhoneCode',
          },
          LOG_IN: {
            target: 'loginIn',
          },
          LOG_OUT: {
            target: 'loggedOut',
            actions: ['cleanFirebase'],
          },
        },
      },
      loginWithPhoneCode: {
        invoke: {
          id: 'loginWithPhoneCode',
          src: 'loginWithPhoneCode',
          onError: {
            target: 'sendLoginCodeToPhone',
            actions: assign({ error: (context, event) => event.data }),
          },
          onDone: {
            target: 'checkingIfLoggedIn',
          },
        },
      },
      loggedOut: {
        on: {
          LOG_IN: {
            target: 'loginIn',
          },
          SEND_LOGIN_CODE_TO_PHONE: {
            target: 'sendLoginCodeToPhone',
          },
        },
      },
    },
  },
  {
    services: {
      checkIfLoggedIn: () => async (send: Sender<AuthenticationMachineEvent>) => {
        const currentUser = await getCurrentUser()
        if (currentUser) {
          const result = await createGraphqlClient()
            .query(
              gql`
                query me {
                  me {
                    id
                    email
                    firstName
                    lastName
                    roles

                    ... on Doctor {
                      patients {
                        nodes {
                          node {
                            id
                            email
                            firstName
                            lastName
                            roles
                          }
                          accessRights
                        }
                      }
                    }
                  }
                }
              `,
              {},
            )
            .toPromise()
          const { id, email, firstName, lastName, roles } = result.data.me
          flagsmith.identify(id, { email, firstName, lastName, roles })
          Sentry.setUser({ id, email, username: `${firstName} ${lastName}` })

          send({
            type: 'REPORT_IS_LOGGED_IN',
            userDetails: result.data.me,
          })
        } else {
          send({
            type: 'REPORT_IS_LOGGED_OUT',
          })
        }
      },
      logIn: (_, event: AuthenticationMachineEvent) => async (send: Sender<AuthenticationMachineEvent>) => {
        const {
          // @ts-ignore
          credentials: { email, password },
        } = event
        const currentUser = await signInWithEmailAndPassword(getAuth(), email, password)
        await updateCurrentUser(getAuth(), currentUser.user)
      },
      sendLoginCodeToPhone:
        (_, event: AuthenticationMachineEvent) => async (send: Sender<AuthenticationMachineEvent>) => {
          if (event.type !== 'SEND_LOGIN_CODE_TO_PHONE') {
            return
          }
          createVerifier()
          if (event.phoneNumber) {
            // @ts-ignore
            const appVerifier = window.recaptchaVerifier
            // @ts-ignore
            window.confirmationResult = await signInWithPhoneNumber(getAuth(), event.phoneNumber, appVerifier)
          }
        },
      loginWithPhoneCode:
        (_, event: AuthenticationMachineEvent) => async (send: Sender<AuthenticationMachineEvent>) => {
          if (event.type !== 'LOGIN_WITH_PHONE_CODE') {
            return
          }
          // @ts-ignore
          const confirmationResult = window.confirmationResult
          const result = await confirmationResult.confirm(event.code)
          await updateCurrentUser(getAuth(), result.user)
        },
    },
    actions: {
      cleanFirebase: () => {
        getAuth()
          .signOut()
          .then(() => {
            flagsmith.logout().catch(console.error)
            Sentry.setUser(null)
          })
      },
      assignUserDetailsToContext: assign((context, event) => {
        if (event.type !== 'REPORT_IS_LOGGED_IN') {
          return {}
        }
        return {
          userDetails: event.userDetails,
        }
      }),
      clearUserDetailsFromContext: assign({
        userDetails: undefined,
      }),
      clearErrorsFromContext: assign({
        error: undefined,
      }),
    },
  },
)
