/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { UserRunsData } from './UserRunsData'
import { UserRunsDataFromJSON, UserRunsDataFromJSONTyped, UserRunsDataToJSON } from './UserRunsData'

/**
 *
 * @export
 * @interface RepeatedRunsChartData
 */
export interface RepeatedRunsChartData {
  /**
   *
   * @type {Array<number>}
   * @memberof RepeatedRunsChartData
   */
  x: Array<number>
  /**
   *
   * @type {Array<number>}
   * @memberof RepeatedRunsChartData
   */
  y: Array<number>
  /**
   *
   * @type {Array<number>}
   * @memberof RepeatedRunsChartData
   */
  yTrend: Array<number>
  /**
   *
   * @type {Array<number>}
   * @memberof RepeatedRunsChartData
   */
  yTrendGlobal: Array<number>
  /**
   *
   * @type {Array<UserRunsData>}
   * @memberof RepeatedRunsChartData
   */
  userRunsData: Array<UserRunsData>
}

/**
 * Check if a given object implements the RepeatedRunsChartData interface.
 */
export function instanceOfRepeatedRunsChartData(value: object): boolean {
  if (!('x' in value)) return false
  if (!('y' in value)) return false
  if (!('yTrend' in value)) return false
  if (!('yTrendGlobal' in value)) return false
  if (!('userRunsData' in value)) return false
  return true
}

export function RepeatedRunsChartDataFromJSON(json: any): RepeatedRunsChartData {
  return RepeatedRunsChartDataFromJSONTyped(json, false)
}

export function RepeatedRunsChartDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): RepeatedRunsChartData {
  if (json == null) {
    return json
  }
  return {
    x: json['x'],
    y: json['y'],
    yTrend: json['yTrend'],
    yTrendGlobal: json['yTrendGlobal'],
    userRunsData: (json['userRunsData'] as Array<any>).map(UserRunsDataFromJSON),
  }
}

export function RepeatedRunsChartDataToJSON(value?: RepeatedRunsChartData | null): any {
  if (value == null) {
    return value
  }
  return {
    x: value['x'],
    y: value['y'],
    yTrend: value['yTrend'],
    yTrendGlobal: value['yTrendGlobal'],
    userRunsData: (value['userRunsData'] as Array<any>).map(UserRunsDataToJSON),
  }
}
