import { createAbility, AppAbility } from '@proxyqb/rights'
import { createContext, useContext } from 'react'
import { createContextualCan } from '@casl/react'
import { useGlobalState } from '@proxyqb/portal-global-state'
import { useSelector } from '@xstate/react'
import { User } from '@proxyqb/graphql-api-types'

const AbilityContext = createContext<AppAbility>(null as unknown as AppAbility)
export const Can = createContextualCan(AbilityContext.Consumer)
export const useAbilityContext = () => useContext(AbilityContext)

export const AbilityContextProvider = ({ children }) => {
  const globalServices = useGlobalState()
  const user = useSelector(globalServices.authService, (state) => {
    return state.context.userDetails
  })
  const ability = createAbility(user as User)

  return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>
}
