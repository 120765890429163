import { useState } from 'react'
import { useEffectOnce } from 'react-use'

type UsePageVisibilityOptions = {
  onHidden?()
  onVisible?()
  throttle?: number
}

export const usePageVisibility = ({
  onHidden,
  onVisible,
  throttle,
}: UsePageVisibilityOptions | undefined = {}) => {
  const [lastTimeVisible, setLastTimeVisible] = useState<number>(0)
  const [lastTimeHidden, setLastTimeHidden] = useState<number>(0)
  useEffectOnce(() => {
    let hidden, visibilityChange
    if (typeof document.hidden !== 'undefined') {
      // Opera 12.10 and Firefox 18 and later support
      hidden = 'hidden'
      visibilityChange = 'visibilitychange'
      // @ts-ignore
    } else if (typeof document.msHidden !== 'undefined') {
      hidden = 'msHidden'
      visibilityChange = 'msvisibilitychange'
      // @ts-ignore
    } else if (typeof document.webkitHidden !== 'undefined') {
      hidden = 'webkitHidden'
      visibilityChange = 'webkitvisibilitychange'
    }

    function handleVisibilityChange() {
      if (document[hidden]) {
        if (!throttle) {
          onHidden?.()
        }
        const now = Date.now()
        if (throttle && lastTimeHidden + throttle <= now) {
          onHidden?.()
        }
        setLastTimeHidden(now)
      } else {
        if (!throttle) {
          onVisible?.()
        }
        const now = Date.now()
        if (throttle && lastTimeVisible + throttle <= now) {
          onVisible?.()
        }
        setLastTimeVisible(now)
      }
    }

    // Warn if the browser doesn't support addEventListener or the Page Visibility API
    if (typeof document.addEventListener === 'undefined' || hidden === undefined) {
      console.log(
        'This demo requires a browser, such as Google Chrome or Firefox, that supports the Page Visibility API.',
      )
    } else {
      // Handle page visibility change
      document.addEventListener(visibilityChange, handleVisibilityChange, false)
      return () => {
        document.removeEventListener(visibilityChange, handleVisibilityChange)
      }
    }
  })
}
