import loadable from '@loadable/component'
import { useGlobalState } from '@proxyqb/portal-global-state'
import { useFlagsmith } from '@proxyqb/react-feature-flags'
import { LoadingTemplate, NotFoundTemplate } from '@proxyqb/ui'
import { useSelector } from '@xstate/react'
import { Navigate, RouteObject, useRoutes } from 'react-router-dom'
import { AbilityContextProvider, MainTemplate, Menu, PrivateRoute } from './shared'
import { LogoutScreen } from './users/logout.screen'
import { DashboardScreen } from './dashboard/dashboard.screen'
import { UserRouter } from './users/user.router'
import { LoginScreen } from './public/screens/Account/login.screen'
import { DoctorRegistrationScreen } from './public/screens/registration/doctor-registration.screen'
import { ChangelogScreen } from './changelog/ChangelogScreen'
import AccessNotAllowedScreen from './public/screens/Account/access-not-allowed.screen'
import { SetPasswordScreen } from './public/screens/Account/set-password.screen'
import { FirmwareScreen } from './firmware/firmware.screen'

const CatalogsRouter = loadable(() => import('./catalogs/catalogs.router'))
const RolesRouter = loadable(() => import('./roles/roles.router'))
const DoctorsRouter = loadable(() => import('./doctors/doctors.router'))
const AdminsRouter = loadable(() => import('./admins/admin.router'))
const PatientsRouter = loadable(() => import('./patients/patients.router'))
const LevelModule = loadable(() => import('@proxyqb/cube-bo-fe/levels'))
const RehabilitationPlanModule = loadable(() => import('@proxyqb/cube-bo-fe/rehabilitation-plans'))
const AnalysisModule = loadable(() => import('@proxyqb/cube-bo-fe/analysis'))
const AssetModule = loadable(() => import('@proxyqb/asset-delivery-fe/asset'))
const DevAreaScreen = loadable(() => import('./dev-area/dev-area.screen'))

export const ROOT_SCREEN_COMPONENT_PATH = 'dashboard'

export function Routes() {
  const { flags } = useFlagsmith()
  const { authService, authState } = useGlobalState()
  const checkingIfLoggedIn = useSelector(authService, (state) => {
    return state.matches('checkingIfLoggedIn')
  })
  const { userDetails } = authState.context
  const roles = userDetails?.roles
  const isPatient =
    authState.context.userDetails &&
    roles?.includes('patient') &&
    !roles?.some((role) => ['doctor', 'admin'].includes(role))

  const getRoutes = (): RouteObject[] => {
    if (userDetails) {
      if (isPatient) {
        return [
          {
            path: '*',
            element: (
              <Navigate
                to={{
                  pathname: '/',
                }}
                replace
              />
            ),
          },
          { path: '/', element: <AccessNotAllowedScreen /> },
        ]
      } else {
        return [
          {
            path: '/',
            element: <PrivateRoute Component={DashboardScreen} />,
          },
          {
            path: 'logout',
            element: <PrivateRoute Component={LogoutScreen} />,
          },
          {
            path: 'dashboard',
            element: <PrivateRoute Component={DashboardScreen} />,
          },
          {
            path: 'catalogs/*',
            element: <PrivateRoute Component={CatalogsRouter} />,
          },
          {
            path: 'doctors/*',
            element: <PrivateRoute Component={DoctorsRouter} />,
          },
          {
            path: 'admins/*',
            element: <PrivateRoute Component={AdminsRouter} />,
          },
          {
            path: 'users/*',
            element: <PrivateRoute Component={UserRouter} />,
          },
          {
            path: 'roles/*',
            element: <PrivateRoute Component={RolesRouter} />,
          },
          {
            path: 'patients/*',
            element: <PrivateRoute Component={PatientsRouter} />,
          },
          {
            path: 'games/*',
            element: <PrivateRoute Component={LevelModule} />,
          },
          {
            path: 'rehabilitation-plans/*',
            element: <PrivateRoute Component={RehabilitationPlanModule} />,
          },
          {
            path: 'analytics/*',
            element: flags.analytics?.enabled ? <PrivateRoute Component={AnalysisModule} /> : null,
          },
          {
            path: 'changelog/*',
            element: <PrivateRoute Component={ChangelogScreen} />,
          },
          {
            path: '/dev-area/*',
            element: <DevAreaScreen />,
          },
          {
            path: '/assets/*',
            element: <AssetModule />,
          },
          {
            path: '/firmware/*',
            element: <FirmwareScreen />,
          },
          {
            path: '/login',
            element: (
              <Navigate
                to={{
                  pathname: '/',
                }}
                replace
              />
            ),
          },
          {
            path: '*',
            element: <PrivateRoute Component={NotFoundTemplate} />,
          },
        ]
      }
    } else {
      return [
        {
          path: 'registration',
          element: <DoctorRegistrationScreen />,
        },
        {
          path: '/login',
          element: <LoginScreen />,
        },
        {
          path: '/reset-password',
          element: <SetPasswordScreen />,
        },
        {
          path: '/*',
          element: (
            <Navigate
              to={{
                pathname: '/login',
              }}
              replace
            />
          ),
        },
      ]
    }
  }

  const element = useRoutes(getRoutes())
  if (checkingIfLoggedIn) {
    return <LoadingTemplate fullScreen />
  }
  return userDetails && !isPatient ? (
    <AbilityContextProvider>
      <MainTemplate menu={<Menu />}>{element}</MainTemplate>
    </AbilityContextProvider>
  ) : (
    element
  )
}
