import { ReactElement, useContext, useEffect, useState } from 'react'
import { PHONE_NUMBER_REGEX } from '@proxyqb/ui'
import { Form, Input, Submit } from '@proxyqb/ui-forms'
import { useForm } from 'react-hook-form'
import { Alert, Box, Button } from '@mui/material'
import { useIntl } from 'react-intl'
import { Interpreter, State } from 'xstate'
import { Context } from 'urql'
import { DoesUserExistByPhoneNumberDocument } from './phone-login.generated'
import { IsUserDeletedDocument } from '../../login/src/login.generated'
import { useNavigate } from 'react-router-dom'
import LoadingButton from '@mui/lab/LoadingButton'

type SendPhoneCodeFields = {
  phoneNumber: string
}

type LoginWithCodeFields = {
  code: string
}

interface Props {
  authState: State<any, any>
  authService: Interpreter<any, any, any, any>
}

export const PhoneLogin = ({ authState, authService }: Props): ReactElement => {
  const [usersPhoneNumberExists, setUsersPhoneNumberExists] = useState<boolean>(true)
  const [phoneCodeLoading, setPhoneCodeLoading] = useState<boolean>(false)
  const { formatMessage } = useIntl()
  const sendPhoneCodeForm = useForm<SendPhoneCodeFields>({
    defaultValues: { phoneNumber: '+420' },
  })
  const loginWithCodeForm = useForm<LoginWithCodeFields>({ defaultValues: { code: '' } })
  const client = useContext(Context)
  const navigate = useNavigate()

  const errorMessage = authState.context?.error?.code

  useEffect(() => {
    if (authState.value === 'sendLoginCodeToPhone') {
      setPhoneCodeLoading(false)
    }
  }, [authState.value])

  const sendPhoneCode = async (): Promise<void> => {
    setPhoneCodeLoading(true)
    const phoneNumber = sendPhoneCodeForm.getValues('phoneNumber')
    const phoneNumberExistCheck = await client
      .query(DoesUserExistByPhoneNumberDocument, { phoneNumber })
      .toPromise()
    const userNotDeletedCheck = await client.query(IsUserDeletedDocument, { phoneNumber }).toPromise()

    if (phoneNumberExistCheck.data.doesUserExistByPhoneNumber && !userNotDeletedCheck.data.isUserDeleted) {
      setUsersPhoneNumberExists(true)
      authService.send({
        type: 'SEND_LOGIN_CODE_TO_PHONE',
        phoneNumber: phoneNumber,
      })
    } else {
      setUsersPhoneNumberExists(false)
      setPhoneCodeLoading(false)
    }
  }

  const loginWithPhoneCode = (code: string): void => {
    authService.send({
      type: 'LOGIN_WITH_PHONE_CODE',
      code: code,
    })
    authService.onTransition(({ value }) => {
      if (value === 'loggedIn') {
        navigate('/', { replace: true })
      }
    })
  }

  const resetPhoneForm = (): void => {
    authService.send({
      type: 'LOG_OUT',
    })
  }

  const getErrorMessage = (): string => {
    switch (errorMessage) {
      case 'auth/invalid-verification-code':
        return formatMessage({ id: 'loginWithCodeForm.wrongCode' })
      case 'auth/user-disabled':
        return formatMessage({ id: 'loginForm.userDisabled' })
      case 'auth/too-many-requests':
        return formatMessage({ id: 'sendPhoneCodeForm.tooManyRequests' })
      default:
        return formatMessage({ id: 'sendPhoneCodeForm.defaultErrorMessage' })
    }
  }

  return (
    <>
      <Form form={sendPhoneCodeForm} name={'sendPhoneCodeForm'}>
        {!usersPhoneNumberExists && (
          <Alert severity="error" elevation={6} variant="filled">
            {formatMessage({ id: 'sendPhoneCodeForm.phoneNumberNotFound' })}
          </Alert>
        )}
        {authState.context.error && (
          <Alert severity="error" elevation={6} variant="filled">
            {getErrorMessage()}
          </Alert>
        )}
        <Input
          name="phoneNumber"
          disabled={authState.value === 'sendLoginCodeToPhone'}
          rules={{ required: true, pattern: PHONE_NUMBER_REGEX }}
        />
        <Box
          sx={{
            display: `${authState.value !== 'sendLoginCodeToPhone' ? 'flex' : 'none'}`,
            justifyContent: 'center',
          }}
        >
          <LoadingButton
            loading={phoneCodeLoading}
            variant={'contained'}
            id={'sign-in-button'}
            onClick={sendPhoneCode}
          >
            {formatMessage({ id: 'sendPhoneCodeForm.submit' })}
          </LoadingButton>
        </Box>
      </Form>
      {authState.value === 'sendLoginCodeToPhone' && (
        <Form
          form={loginWithCodeForm}
          name={'loginWithCodeForm'}
          onSubmit={({ code }) => {
            loginWithPhoneCode(code)
          }}
        >
          <Input name="code" rules={{ required: true }} />
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Submit />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button sx={{ textTransform: 'none' }} color="secondary" onClick={resetPhoneForm}>
              {formatMessage({ id: 'loginWithCodeForm.sendCodeAgain' })}
            </Button>
          </Box>
        </Form>
      )}
    </>
  )
}
