# 2023.4.0


## Tablet

### Fixes

- Opraven problém s hrami na sociální kognici, kdy se nespustila časomíra a hra skončila po první etapě
- Opraveno rozložení stránky se zadáním hry, aby nebylo nutné scrollovat
- Opraveno tlačítko "zpět" v nastavení aplikace, kde bylo nutné kliknout na toto tlačítko tolikrát, kolikrát se předtím kliklo na tlačítko "Nastavení"
- Opraveno zobrazení rehabilitačních plánů, kdy pacient neviděl plán přiřazený skupině pacientů, jejímž byl daný pacient členem
- Opraven překlad chybové hlášky zobrazené při zadání špatného hesla
- Opraven špátný přepočet měsíce narození u pacienta, kdy byl po založení pacienta zobrazován měsíc narození o jedna menší, než byl původně zadán
- Opraven problém s pokračováním v rozehraném rehabilitačním plánu


### Features

- Přidána možnost odpárování kostek v nastavení aplikace
- Nové rozvržení aplikace pro doktory - po spuštění aplikace se doktor dostane na stránku, kde má na výběr zda hraje sám za sebe (pokračuje na výběr her/rehab. plánů), nebo za svého pacienta (pokračuje na seznam svých pacientů).
- Přidána verze aplikace do nastavení
- Přidána komprimace dat o hraní pacienta, pro snížení zátěže na systém a internetové připojení

## Portál

### Fixes

- Opraveno nekonečné načítání způsobené pokusem přihlásit se na deaktivovaného doktora

### Features

- vytváření her sse započítáváním chybných výsledků: čas prodlevy před započtením špatného výsledku se nyní zadává v milisekundách, místo dosavadního neurčitého čísla bez jednotky


---

# 2023.2.0

## Obecné
- Nový design formuláře pro reset hesla

## Tablet

### Fixes

- Opraveny hry se 4 kostkami

### Features

- Nový design kostek
- Nové zobrazení rehabilitačních plánů včetně zobrazení postupu
- Optimalizované načítání obrázků pro větší odolnost proti slabému připojení
- Optimalizované rozložení aplikace a přidány animace pro hladší průchod aplikací bez rušivého problikávání
- Přidána možnost přihlášení přes telefonní číslo
- Přidána obrazovka nastavení s možností připojení a synchronizace kostek, změny jazyka a nastavení Wi-Fi
- Nový design tabulky pro výběr pacienta
- Přidán systém pro opětovné uložení dat o odehrané hře, když první pokus o uložení selže např. vlivem nestabilního připojení
- Hry pro sociální kognici nyní podporují videa
- Pokud je přihlášen doktor, zobrazuje se patička s informací jestli aktuálně hraje sám za sebe, nebo za svého pacienta
- Nový design zobrazení možností ve hrách na sociální kognici
- Zobrazení ikony ve hrách, kde je pouze audio zadání


## Portál

### Fixes

- Opraveno sdílení skupin pacientů mezi skupinami doktorů
- Opraveno vyhledávání doktorů
- Opraveno chybné předvyplnění údajů do formuláře registrace zdravotníka

### Features

- Optimalizované rozložení aplikace a přidány animace pro hladší průchod aplikací bez rušivého problikávání
- Rehabilitační plány je nyní možné přiřadit skupině pacientů
- Přídán graf zobrazující detailní průběh odehrání levelu - časy jednotlivých etap a porovnání s ostatními uživateli

### Admin

- Opraveno zakládání účtu doktora
- Přidána možnost resetu hesla doktorům a pacientům
- Přidána možnost nastavení počtu odpovědí pro hry na sociální kognici


---

# 2022.11.0

## Tablet

### Fixes

- Když zapnu a vypnu baterky v level description okně, tak se kostky nevrátí na modrou barvu, ale vypnou se.

### Features

- Při zakládání pacenta v aplikaci lze přidat více rehabilitačních plánů najednou.
- Změněna černá barva v rehabilitačních plánech za fialovou.

## Portál

### Fixes

- Pacient se už nedostane do portálu.
- Opravena aktivace doktorů.
- Opraveno umístění loading ukazatele.

### Features

- Rehabilitační plány nově nejsou globalní ale řeší se práva doktorů a pacientů na plány.
- Na detailu uživatele přidána informace, zdali k jeho účtu existuje účet ve Firebase.

### Admin

- Přidána možnost vyčistit BE cache.

---

# 2022.10.0

## Tablet

### Fixes

- Kostka po odpojení neovlivní další připojené kostky
- Tlačítko hrát hru znovu nezpůsobí vypnutí LEDek na kostkách
- Odstraněno tlačítko registrovat z přihlašovacího formuláře
- Po odpojení a znovupřipojení se nezůstane odpočet času zaseknutý
- Pacient se nyní po přihlášení do aplikace nemůže zakládat další pacienty
- Opravno náhodné zobrazování hlášky o odpojení kostek i když byly připojené
- Kostky zůstanou svítít modře po zapnutí hry
- Opraveno zobrazování baterií kostek

### Features

- Vyrovnání hlasitosti zvuků a hlasu v aplikaci
- U paměťových her kostky zhasnou ve chvíli kdy se na ně nemá sahat a rozsvítí ve chvíli kdy se s nimi má pracovat
- Je možné pokračovat ve hře po odpojení jedné z kostek a připojení jiné kostky stejného typu
- Fotografie v sociální kognici byly zvětšeny
- Pokud má pacient pouze jeden rehabilitační plán, tak se rovnou vybere
- Synchronizace nových kostek V3 přímo v kufříku
- Upraveno UI ve hrách, kde se střídá hledání barvy a hodnoty. Přidána možnost hrát tyto hry i v paměťovém provedení

---

# 2022.9.0

## Tablet

### Fixes

- Po odpojení kostek během synchronizace se synchronizace přeruší
- Odstraněno problikávání kostek animace generování nového zadání
- Při otevření modalu se stavy baterek v kostkách během hry se hra přeruší a po zavření modalu opět pokračuje

### Features

- Zakázáno gesto na znovunačtení aplikace

## Portál

### Fixes

- Opraven selectbox na vyhledání doktora použitý například při sdílení pacientů
- Opraveno filtrování rehabilitačních plánů podle jména
- Opravena drobečková navigace

### Features

- V detailu pacienta se nyní zobrazují všechny údaje
- Přidána možnost smazat rehabilitační plán
- Přidána možnost filtrovat hry podle kognitivních domén

### Admin

- Přidána možnost deaktivovat doktora

---

# 2022.8.0

## Tablet

### Fixes

- Po odpojení kostek během hry se hra nově přeruší a uživatel má možnost kostky znovu připojit a poté pokračovat ve hře
- Opraveno zdvojené generování zadání u paměťových her když uživatel pohnul s kostkami během přepínání etap
- Tlačítko "Ukončit hru" nyní při hraní v rehabilitačním plánu správně vrací uživatele na obrazovku přípravy ke hře, místo na předchozí odehranou hru
- Opraveno chybné zobrazení kostek ve hře, kdy kostky zadání zasahovaly do spodní části obrazovky v případě že se na obrazovce zobrazovaly i herní kostky
- Opraveno nekvalitní zobrazení progress baru ve hře
- Opraveno zobrazení baterky, která nešla vidět na černém pozadí (hraní v rámci rehabilitačního plánu)

### Features

- Přidána podpora barevného rozsvícení stran kostek pro použití s novým pláštěm (výřezy místo nálepek)
- Přidány nové fotky do her na sociální kognici
- Při hře na čas má nově uživatel možnost dohrát rozehranou etapu i po uplynutí limitu. Bod za takto dokončenou etapu už se však do skóre hry nezapočítá.
- Přidáno tlačítko "Dokončit hru" pro případ že uživatel nechce poslední etapu po uplynutí časového limitu dohrát
- Pacient může nově mít přiřazeno více rehabilitačních plánů

### Quality of Life

- Přidána zvuková zpětná vazba po dokončení hry (otevření modalu s výsledným skóre)
- Přidána animace a zvuk na posledních 5 sekund časovače ve hře
- Opraveno čtení hlasového asistenta při hře s rozdílnými kostkami a zadáním - nyní se čte hodnota/barva pro každou kostku zvlášť, místo najednou na konci

## Portál

### Fixes

- Při editaci doktora se nyní správně předvyplní jeho staré údaje
- Opraven import obrázků pro hry na jazyk a sociální kognici

### Features

- Nový systém vytváření a sdílení her - Doktor vidí pouze globalní hry založené adminem a hry které sám založí, nebo mu budou sdíleny jiným doktorem. Pacient uvidí pouze hry, které mu byly přiřazeny v rámci rehabilitačního plánu.
- Nová sekce analýzy - evaluace hráče - přehled a statistiky

---

# 2022.7.0

## Tablet

### Fixes

- Opravena chyba, kdy se pro hru vybrala odpojená kostka místo kostky připojené.

### Features

- Přidány hry na sociální kognici.
- Pokud se v přípavě hry odpojí kostky, zobrazí se nově připojovací obrazovka.
- Nově se vítězné strany kostek ve hrách berou tak, aby byly odlišné od stran, které jsou v tu chváli na kostce směrem nahoru.
- Aktualizace aplikace se provádí automaticky. Uživatel už nemá možnost aktualizaci přeskočit.
- Přidána možnost tlačítkem odejít z rozehrané hry.
- Vylepšena obrazovka s přípravou hry.

## Portál

### Fixes

- Opravy skupin pacientů.
- Opraveny anglické překlady.

### Features

- Možnost změnit název skupin doktorů a pacientů.
- Importy a exporty assetů pro sociální a jazykové hry.

---

# 2022.6.0

## Tablet

### Fixes

- Opraveno chybné zobrazování obrazovky pro přípravu kostek v rehabilitačním plánu. Nyní se obrazovka zobrazuje správně, pouze pokud nejsou všechny potřebné kostky pro plán připraveny.
- Opraveno chybné chování tlačítka Připojit/Synchronizovat/Pokračovat na obrazovce pro přípravu kostek

### Features

- Hry na procvičení jazykových dovedností
- Zvukový a vizuální feedback na úspěšné splnění úkolu
- Nové zobrazení baterií všech připojených kostek + tabletu
- Možnost prohazování pořadí kostek v zadání v jednotlivých úkolech
- Přidána animace přechodu zadání mezi úkoly
- Přidány pole pro zadání data narození, pohlaví a typu pacienta

## Portál

### Fixes

- Opraveno přesměrování, když uživatel jde na url /login
- Opravena drobečková navigace při duplikaci hry

### Features

- Doplněna výchozí hodnota barvy textu při výběru typu zadání "Zobrazování pouze textu"
- Přepracovaný formulář na vytvoření/editaci rehabilitačního plánu
- Formuláře pro editaci nyní není možné odeslat, pokud nebyla provedena žádná změna
- Rehabilitační domény nyní není třeba vyplňovat při vytvoření hry. Pokud nebudou vyplněny, hra se nebude zobrazovat pod žádnou kategorií a bude možné ji hrát pouze jako součást rehabilitačního plánu.
- Přidány pole pro zadání data narození, pohlaví a typu pacienta

### Quality of Life

- Přidáno tlačítko "zpět" ve formuláři pro reset hesla uživatele
- Přidáno tlačítko "zpět" ve formuláři pro editaci pacienta
- Upravena aktivní oblast tlačítka "+" v sekci kostek ve formuláři pro vytvoření hry
- Upraven design detailu skupiny pacientů
- Optimalizace webu pro mobilní zařízení
- Zpřehlednění formuláře pro vytváření her - rozdělení do tématických sekcí, přidání nápověd pro komplexnější nastavení

---

# 2022.5.2

## Tablet

### Fixes

- Opraveny barva obrázků na kostce. Zase je 5 černá a 6 žlutá, ne naopak.

# 2022.5.1

## Portál

### Fixes

- Opraveno vytváření nových levelů, kde nebylo povoleno upravovat nic krom textů.

# 2022.5.0

## Tablet

### Fixes

- Hlasový asistent nyní mluví jazykem podle aktuálního nastavení (dříve pouze česky)
- Obrazovka pro přípravu kostek se nyní při hraní v rámci rehabilitačního plánu zobrazuje pouze před první hrou

## Portál

### Fixes

- Opravena validace při vytváření/úpravě pacienta
- Zadánvání tel. čísla je nyní umožněno v mezinárodním formátu

### Features

- V portálu je nyní zakázáno upravovat existující hry (s vyjímkou textace) kvůli konzistenci dat
- Přidána možnost duplikovat a následně upravit hru
- Přidán graf pro porovnání jednotlivých výsledků hráčů s globálními daty
- Smysluplnější zobrazení kognitivních domén v seznamu her
- Zjednodušení formuláře pro vytvoření hry
- Možnost převést vlastnictví pacienta na jiného doktora
- Přidáno pole pro popis hry v angličtině

### Admin

- Klik na avatara nyní zobrazuje položky Detaily, Logy, Aktivita uživatele
- Opraveno upravování rolí uživatele
- Opraveno padání aplikace na záložce "Role"

### Quality of Life

- Zrychlení načítání grafů a statistik

---

# 2022.4.0

## Tablet

### Fixes

- V paměťové hře opraveno občasné nesprávné vyhodnocení pohybu kostky, přestože byla kostka v klidu

### Features

- Přidáno tlačítko "Hrát znovu" po odehrání hry
- Přidána možnost přepnutí jazyka aplikace
- Redesign formuláře pro vyvoření/editaci rehabilitačního plánu

### Quality of Life

- Zvýšení kontrastu mezi jednolitými barvami kostky
- Pro lepší orientaci na stránce přidána drobečková navigace

## Portál

### Fixes

- Opaveno zobrazování skupin doktorů
- Proklik na hru ze seznamu her nyní vede přímo na editační formulář, kde je možné i smazání hry
- Kliknutí v tabulce na zaklíkávací tlačítko pro výběr řádku nyní neaktivuje přesměrování na detail
- Opraveno zobrazování skupin doktorů ve kterých se daný uživatel nacházi

### Features

- Kompletní redesign webové aplikace (portálu)
- Do rehabilitačního plánu lze nyní zařadit jednu hru opakovaně
- Přidána možnost smazat uživatele
- Přidána možnost přepnutí jazyka portálu
- Přidány podrobnější informace o počtu odehraných her včetně počtu unikátních hráčů za dnešní a včerejší den (Dashboard)
- Doktor může nově nasdílet svého pacienta/skupinu pacientů jinému doktorovi/skupině doktorů
- Vylepšeny logy aktivit uživatelů
- Ve formuláři vytvoření hry přidán podnět "Černá čísla"
- Přidána analýza her s možností filtrovat uživatelé a hry
- V analýze dle vlastního filtru je nyní možné exportovat data podle zvoleného filtru do excelu
- Přidán krabicový graf zobrazující rozdělení výsledků jednotlivých her

### Admin

- Přidán pohled na počet odehraných her včetně počtu unikátních hráčů za dnešní a včerejší den
- Možnost zobrazení detailu doktora a jeho pacientů, skupin atd.
- Opraveno chyba na detailu doktora, kdy se zobrazovala hláška "Uživatel není doktor"
- Opravena editace doktora

---

# 2022.3.0

## Tablet

### Fixes

- Opraveno vytvoření pacienta bez rehabilitačního plánu
- Po vytvoření pacienta se uživatel vrátí na seznam pacientů, kde se rovnou zobrazí právě vytvořený pacient

### Features

- V případě nedokončení rehabilitačního plánu se postup uloží a po návratu do aplikace může uživatel pokračovat od poslední nedokončené hry
- Ve hře nyní lze nastavit započítávání špatných řešení - pokud uživatel otočí kostky špatně, systém vyhodnotí chybu, uloží ji pokračuje další etapou
- V záhlaví aplikace se nyní zobrazuje aktuální stav baterie připojených kostek
- Ve hře nyní lze nastavit zadání hledaného podnětu textem namísto obrázku

### Quality of Life

- Tlačítka tabletové aplikace byla zvětšena pro pohodlnější ovládání dotykem
- Tabulka pacientů je nýní seřazena podle Příjmení
- Opraveno občasné sekání aplikace - především viditelné při plnění progress baru
- Zjemněny vibrace na kostkách

## Portál

### Fixes

- Při editaci levelu se nyní správně zobrazují rehabilitační domény podle předchozího nastavení
- Opraven problém s příhlášením do aplikace, kdy se aplikace po zadání správných údajů chovala, jako by uživatel neexistoval
- Opravena chyba, kdy se nenačetl detail číselníku
- Konfigurace hry pro barevnou kostku

### Features

- Přehled počtu odehraných her za den na dashboardu
- Přidána možnost smazání hry
- Přidána možnost editace pacienta
- Přidána možnost smazání pacienta
- V tabulce her se nyní zobrazují rehabilitační domény
- Ve formuláři lze nyní nastavit konkrétní rehabilitační doménu (doposud pouze obecná úroveň)
- V detailu doktora přidán list jeho pacientů
- Přidán import a export her v tabulce
- Přidána možnost sdílet pacienta/skupinu pacientů s jiným doktorem

### Admin

- Přidána možnost editovat doktora
- Přidána správa uživatelů a jejich rolí (admin, doktor, pacient)

---
