import { useRoutes } from 'react-router-dom'
import { PrivateRoute } from '../shared'
import { MyProfile } from './my-profile/my-profile.screen'

export function UserRouter() {
  const element = useRoutes([
    {
      path: 'me/profile',
      element: <PrivateRoute Component={MyProfile} />,
    },
  ])

  return element
}
