import { FC } from 'react'
import styled from 'styled-components'

const StyledCube = styled.div<any>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  overflow: hidden;
  position: relative;
`
const Img = styled.img<any>`
  transform-origin: 0 0;
  position: absolute;
  left: ${({ size }) => size / 7}px;
  width: ${({ size }) => size / 3}px;
  height: ${({ size }) => size / 3}px;
`
const TopImg = styled(Img)<any>`
  transform: rotate(210deg) skew(-30deg)
    translate(-${({ size }) => size * 0.67}px, -${({ size }) => size * 0.2}px) scaleY(0.864);
`
const LeftImg = styled(Img)<any>`
  transform: rotate(90deg) skewX(-30deg) scaleY(0.864)
    translate(${({ size }) => size * 0.22}px, -${({ size }) => size * 0.44}px);
`
const RightImg = styled(Img)<any>`
  transform: rotate(-30deg) skewX(-30deg)
    translate(${({ size }) => size * 0.43}px, ${({ size }) => size * 0.57}px) scaleY(0.864);
`
interface Props {
  textures: string[]
  size: number
}

const getImgPath = (texture: string) => `/static/media/cube/surfaces/${texture}`
export const CubeImage: FC<Props> = ({ textures, size }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [t1, t6, t2, t5, t3, t4] = textures
  return (
    <StyledCube size={size}>
      <TopImg src={getImgPath(t1)} size={size} />
      <LeftImg src={getImgPath(t2)} size={size} />
      <RightImg src={getImgPath(t3)} size={size} />
    </StyledCube>
  )
}
