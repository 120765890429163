import * as Types from '@proxyqb/graphql-api-types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export const DoesUserExistByPhoneNumberDocument = gql`
    query doesUserExistByPhoneNumber($phoneNumber: String!) {
  doesUserExistByPhoneNumber(phoneNumber: $phoneNumber)
}
    `;

export function useDoesUserExistByPhoneNumberQuery(options: Omit<Urql.UseQueryArgs<DoesUserExistByPhoneNumberQueryVariables>, 'query'>) {
  return Urql.useQuery<DoesUserExistByPhoneNumberQuery, DoesUserExistByPhoneNumberQueryVariables>({ query: DoesUserExistByPhoneNumberDocument, ...options });
};
export type DoesUserExistByPhoneNumberQueryVariables = Types.Exact<{
  phoneNumber: Types.Scalars['String'];
}>;


export type DoesUserExistByPhoneNumberQuery = { __typename: 'Query', doesUserExistByPhoneNumber?: boolean | null };
