import { Box, Button, Dialog, Grid, Paper, Typography } from '@mui/material'
import { Flex } from '@proxyqb/ui'
import { SetPasswordForm } from '@proxyqb/ui/src/organisms/SetPasswordForm'
import { confirmPasswordReset, getAuth } from 'firebase/auth'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { Link, useLocation } from 'react-router-dom'
import { PublicTemplate } from '../../../shared'
import img from './login-fe-img.png'

export const SetPasswordScreen = () => {
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const auth = getAuth()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [isConfirmCubeReset, setIsConfirmCubeReset] = useState(false)
  const { formatMessage } = useIntl()
  const continueUrl = params.get('continueUrl') || ''
  const { enqueueSnackbar } = useSnackbar()

  const resetPassword = async (newPassword: string) => {
    const oobCode = params.get('oobCode') || ''
    try {
      await confirmPasswordReset(auth, oobCode, newPassword)
      continueUrl ? setDialogOpen(true) : setIsConfirmCubeReset(true)
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' })
    }
  }
  return (
    <PublicTemplate>
      <Flex
        position="absolute"
        alignItems="center"
        justifyContent="center"
        height="80%"
        width="calc(100% - 16px)"
      >
        <Flex as={Paper} elevation={1}>
          <Grid container columns={{ xs: 1, md: 2 }}>
            <Grid item xs={1} md={1}>
              <Flex background="#fff" borderRadius="8px 0 0 8px" alignItems="center" justifyContent="center">
                <img src={img} width="300px" />
              </Flex>
            </Grid>
            <Grid item xs={1} md={1}>
              <Flex
                width="400px"
                height="100%"
                minHeight="310px"
                padding="32px"
                flexDirection="column"
                background="#e7e7e7"
                borderRadius="0 8px 8px 0"
              >
                {isConfirmCubeReset ? (
                  <Typography sx={{ margin: 'auto' }}>{`${formatMessage({
                    id: `setPasswordForm.passChanged`,
                  })} ${formatMessage({ id: `setPasswordForm.noRedirect` })}`}</Typography>
                ) : (
                  <SetPasswordForm onSubmit={resetPassword} />
                )}
              </Flex>
            </Grid>
          </Grid>
        </Flex>
      </Flex>
      <Dialog open={dialogOpen}>
        <Box
          sx={{
            padding: '40px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography>{formatMessage({ id: `setPasswordForm.passChanged` })}</Typography>
          <Button sx={{ marginTop: '10px' }} variant="contained" component={Link} to={continueUrl}>
            {formatMessage({ id: `setPasswordForm.login` })}
          </Button>
        </Box>
      </Dialog>
    </PublicTemplate>
  )
}
