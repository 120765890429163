import { Box, useTheme } from '@mui/material'
import { PropsWithChildren } from 'react'

export const ButtonBar = ({ children }: PropsWithChildren<{}>) => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        position: 'absolute',
        right: {
          xs: theme.spacing(theme.mainScreenSpacing.pxSmall),
          sm: theme.spacing(theme.mainScreenSpacing.px),
        },
        top: 112,
        display: 'flex',
        justifyContent: 'flex-end',
        gap: theme.spacing(theme.mainScreenSpacing.pxSmall),
      }}
    >
      {children}
    </Box>
  )
}
