import { InputLabelProps, TextField, TextFieldProps } from '@mui/material'
import { get } from 'lodash-es'
import { MouseEventHandler } from 'react'
import { Controller, RegisterOptions } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useFormInnerContext } from '../utils/FormInnerProvider'
import { useHelperText, useResolveRequired } from '../utils/form-helpers'
import { InputCharacterCounter } from './InputCharacterCounter'

type Props = {
  name: string
  type?: string
  rules?: RegisterOptions
  disabled?: boolean
  label?: string
  helperText?: string
  onClick?: MouseEventHandler<HTMLDivElement>
  InputLabelProps?: InputLabelProps
  characterMaxLimit?: number
} & Pick<TextFieldProps, 'multiline' | 'variant' | 'autoFocus' | 'sx' | 'InputProps'>

export function Input({ name, type, rules, label, disabled, helperText, characterMaxLimit, ...rest }: Props) {
  const { isSubmitting, errors, control, formName } = useFormInnerContext()
  const intl = useIntl()
  const resolvedLabel =
    label !== undefined
      ? label
      : intl.messages[`${formName}.${name}`] ?? intl.messages[`shared.${name}`] ?? `${formName}.${name}`
  const error = get(errors, name)
  const resolvedHelperText = useHelperText(error, resolvedLabel) ?? helperText
  const required = useResolveRequired(name, rules, disabled)

  if (type === 'file') {
    return <>{'Use <FileInput /> instead of <Input type="file" />'}</>
  }
  return (
    <Controller
      render={({ field }) => (
        <TextField
          type={type}
          disabled={disabled || isSubmitting}
          id={name}
          autoCorrect="off"
          autoComplete="off"
          spellCheck="false"
          label={`${resolvedLabel}${required ? ' *' : ''}`}
          error={!!error}
          helperText={resolvedHelperText}
          variant="outlined"
          {...field}
          value={field.value}
          InputProps={{
            endAdornment: characterMaxLimit ? (
              <InputCharacterCounter maxLimit={100} value={field.value} />
            ) : null,
          }}
          {...rest}
        />
      )}
      control={control}
      rules={{
        ...rules,
        required,
      }}
      name={name}
    />
  )
}
