/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum Domain {
  Attention = 'attention',
  ExecutiveFunctions = 'executiveFunctions',
  LearningMemory = 'learningMemory',
  Language = 'language',
  Psychomotorics = 'psychomotorics',
  SocialCognition = 'socialCognition',
}

export function DomainFromJSON(json: any): Domain {
  return DomainFromJSONTyped(json, false)
}

export function DomainFromJSONTyped(json: any, ignoreDiscriminator: boolean): Domain {
  return json as Domain
}

export function DomainToJSON(value?: Domain | null): any {
  return value as any
}
