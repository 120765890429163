/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
/**
 *
 * @export
 * @interface LevelStatistics
 */
export interface LevelStatistics {
  /**
   *
   * @type {number}
   * @memberof LevelStatistics
   */
  averageScore: number
  /**
   *
   * @type {number}
   * @memberof LevelStatistics
   */
  std: number
  /**
   *
   * @type {number}
   * @memberof LevelStatistics
   */
  sampleLength: number
}

/**
 * Check if a given object implements the LevelStatistics interface.
 */
export function instanceOfLevelStatistics(value: object): boolean {
  if (!('averageScore' in value)) return false
  if (!('std' in value)) return false
  if (!('sampleLength' in value)) return false
  return true
}

export function LevelStatisticsFromJSON(json: any): LevelStatistics {
  return LevelStatisticsFromJSONTyped(json, false)
}

export function LevelStatisticsFromJSONTyped(json: any, ignoreDiscriminator: boolean): LevelStatistics {
  if (json == null) {
    return json
  }
  return {
    averageScore: json['averageScore'],
    std: json['std'],
    sampleLength: json['sampleLength'],
  }
}

export function LevelStatisticsToJSON(value?: LevelStatistics | null): any {
  if (value == null) {
    return value
  }
  return {
    averageScore: value['averageScore'],
    std: value['std'],
    sampleLength: value['sampleLength'],
  }
}
