import * as Types from '@proxyqb/graphql-api-types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export const GetFirmwareDocument = gql`
    query getFirmware {
  getFirmware {
    name
    version
    url
  }
}
    `;

export function useGetFirmwareQuery(options?: Omit<Urql.UseQueryArgs<GetFirmwareQueryVariables>, 'query'>) {
  return Urql.useQuery<GetFirmwareQuery, GetFirmwareQueryVariables>({ query: GetFirmwareDocument, ...options });
};
export const AddFirmwareDocument = gql`
    mutation addFirmware($input: FirmwareInput!) {
  addFirmware(input: $input)
}
    `;

export function useAddFirmwareMutation() {
  return Urql.useMutation<AddFirmwareMutation, AddFirmwareMutationVariables>(AddFirmwareDocument);
};
export type GetFirmwareQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetFirmwareQuery = { __typename: 'Query', getFirmware: Array<{ __typename: 'Firmware', name: string, version: string, url: string }> };

export type AddFirmwareMutationVariables = Types.Exact<{
  input: Types.FirmwareInput;
}>;


export type AddFirmwareMutation = { __typename: 'Mutation', addFirmware: string };
