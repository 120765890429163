import * as Types from '@proxyqb/graphql-api-types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export const AbilityDocument = gql`
    query ability($id: ID!) {
  ability(id: $id) {
    rules {
      action
      subject
      conditions
      inverted
      reason
    }
  }
}
    `;

export function useAbilityQuery(options: Omit<Urql.UseQueryArgs<AbilityQueryVariables>, 'query'>) {
  return Urql.useQuery<AbilityQuery, AbilityQueryVariables>({ query: AbilityDocument, ...options });
};
export type AbilityQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type AbilityQuery = { __typename: 'Query', ability: { __typename: 'Ability', rules: Array<{ __typename: 'AbilityRule', action: Array<string>, subject: Array<string>, conditions?: any | null, inverted?: boolean | null, reason?: string | null }> } };
